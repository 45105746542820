import store from '../../store'
const target = store.state.target
const url = target + "/manager"
export default {
  queryEnterpriseInfo: url + '/enterprise-info/queryEnterpriseInfo', //查询企业信息
  reportInitQuery: url + '/report-pastdetails/reportInitQuery', //查询是否建账
  deductPersonQuery: url + '/deductConfirm/deductPersonQuery', //年收入不足6万人员查询
  confirm: url + '/deductConfirm/confirm', //年收入不足6万人员确认
  //接口链接
  assetliabilityReport: url + '/enterprise-report-query/assetliabilityReport', //资产负债--一般企业
  assetliabilityReportSmall: url + '/enterprise-small-report/assetliabilityReport', //资产负债--小企业

  profitReport: url + '/enterprise-report-query/profitReport', //利润表--一般企业
  profitReportSmall: url + '/enterprise-small-report/profitReport', //利润表--小企业

  cashFlowReport: url + '/enterprise-report-query/cashFlowReport', //现金流量--一般企业
  cashFlowReportSmall: url + '/enterprise-small-report/cashFlowReport', //现金流量--小企业

  querySubjectBalance: url + '/enterprise-report-query/querySubjectBalance', // 科目余额--一般企业
  querySubjectBalanceSmall: url + '/enterprise-small-report/querySubjectBalance', // 科目余额--小企业

  modifyAssetliabilityReport: url + '/enterprise-small-report/modifyAssetliabilityReport', // 资产负债--单条修改
  modifyCashFlowReport: url + '/enterprise-small-report/modifyCashFlowReport', // 现金流量-单条修改
  modifyProfitReport: url + '/enterprise-small-report/modifyProfitReport', // 利润表-单条修改
  modifySubjectBalance: url + '/enterprise-small-report/modifySubjectBalance', // 科目余额表-单条修改
  ledgerSubject: url + '/subsidiar-ledger/subsidiar-ledger-subject', // 明细账-科目列表
  ledgerDetail: url + '/subsidiar-ledger/subsidiar-ledger-detail', // 科目明细账


  accountsPayable: url + '/report-pastdetails/accountsPayable', //应付账款
  accountsReceivable: url + '/report-pastdetails/accountsReceivable', //应收账款
  advanceReceipts: url + '/report-pastdetails/advanceReceipts', //预收账款
  prepayment: url + '/report-pastdetails/prepayment', //预付账款
  accountsPayableOther: url + '/report-pastdetails/accountsPayable-other', //其他应付账款
  accountsReceivableOther: url + '/report-pastdetails/accountsReceivable-other', //其他应收账款


  accountsPayableDetail: url + '/report-pastdetails/accountsPayable-detail', //应付账款-明细
  accountsPayableOtherDetail: url + '/report-pastdetails/accountsPayableOther-detail', //其他应付账款-明细
  accountsReceivableDetail: url + '/report-pastdetails/accountsReceivable-detail', //应收账款-明细
  accountsReceivableOtherDetail: url + '/report-pastdetails/accountsReceivable-other-detail', //其他应收账款-明细
  advanceReceiptsDetail: url + '/report-pastdetails/advanceReceipts-detail', //预收账款-明细
  prepaymentDetail: url + '/report-pastdetails/prepayment-detail', //预付账款-明细
  queryTitel: url + '/woORAaController/v1/queryTitel', //查询处置的其他公司或者个人
  deal: url + '/woORAaController/v1/deal', //处置

  gudingAssetList: url + '/assetList/gudingAssetList', //固定资产清单
  gudingAssetZheJiuList: url + '/assetList/gudingAssetZheJiuList', //固定资产折旧清单
  wuxingAssetList: url + '/assetList/wuxingAssetList', //无形资产清单
  wuxingAssetTanXiaoList: url + '/assetList/wuxingAssetTanXiaoList', //无形资产摊销清单

  gudingAssetDeal: url + '/assetListDeal/gudingAssetDeal', //处置固定资产

  queryEnterpriseVoucher: url + '/enterprise-voucher/queryEnterpriseVoucher', //查询企业凭证
  addEnterpriseVoucher: url + '/enterprise-voucher/addEnterpriseVoucher', //增加凭证
  deleteEnterpriseVoucher: url + '/enterprise-voucher/deleteEnterpriseVoucher', //删除凭证
  voucherCodeReset: url + '/enterprise-voucher/voucherCodeReset', //凭证从新编码
  downloadVoucher: url + '/voucher-download/downloadVoucher', //生成下载凭证任务
  queryDownloadTask: url + '/download-info/queryDownloadTask', //查询下载任务
  downloadGet: url + '/voucher-download/download', //下载凭证


  inventoryList: url + '/inventory/inventoryList', //配置的库存列表
  deleteInventory: url + '/inventory/deleteInventory', //删除配置的库存
  addInventory: url + '/inventory/addInventory', //配置库存

  queryBaoShuiStatusG: url + '/enterprise-pastdetails/queryBaoShuiStatus', //国税申报-查询报税记录
  queryTaxDetail: url + '/taxDtail/v1/queryTaxDetail', //查询税费明细
  taxDetailDeal: url + '/taxDtail/v1/taxDetailDeal', //税费-申报   国税
  updateBaoShuiStatus: url + '/enterprise-pastdetails/updateBaoShuiStatus', //修改为已报税
  findEmployeeSalary: url + '/salaryservice-employee/initial/list', //员工工资-工资表人员列表
  findEmployeeSalaryV2: url + '/salaryservice-employee/initial/listv2', //员工工资-工资表
  queryStaff: url + '/salaryservice-employee/queryStaff', //  员工列表-添加员工用的花名册
  addSalaryStaff: url + '/salaryservice-employee/addSalaryStaff', //  添加员工
  queryEmployeeSalaryUser: url + '/salaryservice/queryEmployeeSalary-user', //
  querySalaryStaff: url + '/salaryservice-employee/querySalaryStaff', //员工工资-查询员工工资信息
  queryEmployeeNoun: url + '/salaryservice-social/queryEmployeeNoun', //员工工资-查询个人社保信息
  updateEmployeeNoun: url + '/salaryservice-social/updateEmployeeNoun', //员工工资-完善员工社保公积金信息
  updateFine: url + '/salaryservice-employee/updateFine', //修改罚款扣款
  updateEmployeeStaffInfo: url + '/salaryservice-employee/updateEmployeeStaffInfo', //员工工资-完善员工工资信息
  updateIsPaymentSS: url + '/salaryservice-social/updateIsPaymentSS', //员工工资-修改是否缴纳社保
  makeSalary: url + '/salaryservice/makeSalary', //制作工资

  findEmployeeSalaryNosubmmit: url + '/salaryservice/findEmployeeSalary-nosubmmit', //员工工资-工资表(查询是否有未提交审批的工资表)
  queryBaoshuiamount: url + '/salaryservice/query-baoshuiamount', //工资报税-查询报税金额
  SalaryservicefindEmployeeSalary: url + '/salaryservice/findEmployeeSalary', //员工工资-工资表
  submmitSalaryCheck: url + '/salarySubmmitCheck/v1/submmitSalaryCheck', //工资表提交审批
  reMakeSalary: url + '/salaryservice/revokeSalary', //重新制作
  salaryserviceBaoshui: url + '/salaryservice/baoshui', //工资报税
  geshuiCheck: url + '/baoshui-geshui/check', //报税审核-工资报税
  salaryRecord: url + '/salaryservice/salaryRecord', //  工资记录
  queryBaoShuiStatus: url + '/baoshui-geshui/queryBaoShuiStatus', //  查询报税记录-个税
  removeSalaryStaff: url + '/salaryservice-employee/removeSalaryStaff', //  移除员工





  makeSalaryV2: url + '/salaryservice-v2/makeSalary-v2', //制作工资v2
  reCalculatTax: url + '/salaryservice-v2/rqueryMonthSalaryListV2e-calculat-tax', //重新算税
  querySalaryTaxCalculatRecord: url + '/salaryservice-v2/querySalaryTaxCalculatRecord', //查询算税失败原因
  revokeSalaryV2: url + '/salaryservice-v2/revokeSalary-v2', //撤销工资v2
  findEmployeeSalaryStatusV2: url + '/salaryservice-v2/findEmployeeSalaryStatus-v2', //查询工资表-查询工资表v2
  findSalaryComprehensiveV2: url + '/salaryservice-v2/findSalaryComprehensive-v2', //查询工资表-查询工资表综和v2(总表最左侧的综和)
  submmitHuanfaSalaryCheck: url + '/salaryHuanfaSubmmitCheck/v2/submmitHuanfaSalaryCheck', // 缓发工资提交审批[请求付款]-v2

  queryMonthSalaryListV2: url + '/salaryservice-v2/queryMonthSalaryList-v2', //查询工资表-查询工资列表v2

  submmitSalaryCheckV2: url + '/salarySubmmitCheck/v2/submmitSalaryCheck-v2', //工资表提交审批--v2
  submmitSalaryCheckRevoke: url + '/salarySubmmitCheck/v2/submmitSalaryCheck-revoke', //工资表审批撤销--v2

  baoshuiV2: url + '/baoshui-geshui/baoshui-v2', //工资报税v2
  baoshuiV2Correct: url + '/baoshui-geshui/baoshui-v2-correct', //工资报税v2-更正申报
  queryShenbaoStatusV2: url + '/baoshui-geshui/baoshui-v2-query-shenbao-status', //工资报税v2-查询申报状态（税局端）
  // queryShenbaoStatusV2: url + '/baoshui-geshui/geshui-v2-query-shenbao-status', //工资报税v2-查询申报状态（税局端）
  baoshuiV2Zuofei: url + '/baoshui-geshui/baoshui-v2-zuofei', //工资报税v2-作废申报
  getAgreementBankInfo: url + '/tax-pay/getAgreementBankInfo', //缴款-获取协议银行结果
  queryAgreementBankInfo: url + '/tax-pay/queryAgreementBankInfo', //缴款-查询协议银行
  taxPayment: url + '/tax-pay/taxPayment', //缴款-三方协议缴款
  getTaxPaymentResult: url + '/tax-pay/getTaxPaymentResult', //缴款-三方协议缴款结果
  arrearsQuery: url + '/tax-pay/arrearsQuery', //查询欠费-个税
  setSalaryTaxPayed: url + '/tax-pay/setSalaryTaxPayed', //设置报税完成---个税为0时，不需要交税使用这个接口
  querySalaryTaxShenbaoRecord: url + '/baoshui-geshui/querySalaryTaxShenbaoRecord', //查询申报失败原因
  shenbaoDataQuery: url + '/shenbao-data-query/shenbaoDataQuery', //查询申报数据
  baosong: url + '/salaryservice-employee/baosong', //员工报送税局
  addCagData: url + '/enterprise-report-query/addCagData', //添加往来明细
  addSubjectBalance: url + '/enterprise-report-query/addSubjectBalance', //科目余额表-增加一条科目



}