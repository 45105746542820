<template>
  <div
    class="triangle"
    :style="{ left: left + 'px' }"
    :class="{
      triangleBottom: direction === 'bottom',
      triangleTop: direction === 'top',
    }"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      key: "",
      colorValue: "",
    };
  },
  props: {
    direction: {
      type: String,
      default: "",
    },
    left: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.color) {
      this.colorValue = this.color;
    } else {
      this.colorValue = "#4c8afc";
    }
  },
  watch: {
    colorValue(val, oldVal) {
      this.$el.style.setProperty("--colorStyle", val);
    },
  },
};
</script>

<style lang="less" scoped>
/*  给矩形一个1px实心颜色rgb为#E4E4E2的边框  */
.triangle {
  position: absolute;
  left: 43px;
  color: var(--colorStyle);
  &.triangleTop {
    top: -20px;
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: -10px;
      width: 0;
      height: 0;
      border-bottom: 10px solid var(--colorStyle);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    &:after {
      content: "";
      position: absolute;
      top: 11px;
      left: -9px;
      width: 0;
      height: 0;
      border-bottom: 9px solid #fff;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
    }
  }
  &.triangleBottom {
    bottom: -20px;
    &:before {
      content: "";
      position: absolute;
      bottom: 10px;
      left: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid var(--colorStyle);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 11px;
      left: -9px;
      width: 0;
      height: 0;
      border-top: 9px solid #fff;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
    }
  }
}
</style>