import { post, get } from './fetch'
import systemApi from './url/systemUrl'
import managerApi from './url/managerUrl'
import userApi from './url/userUrl'
import voucherApi from './url/voucherUrl'
import bankbillApi from './url/bankbillUrl'
import batchApi from './url/batchUrl'
import payserverApi from './url/payserverUrl'
import attendanceApi from './url/attendanceUrl'
import inventoryApi from './url/inventoryUrl'

// !inventoryApi
//查询库存明细  
export function inventoryDetail(param) {
  return post(inventoryApi.inventoryDetail, param)
}
//暂估明细表  
export function queryEstimatedInDetail(param) {
  return post(inventoryApi.queryEstimatedInDetail, param)
}
//查询组合商品名称  
export function queryComposeName(param) {
  return post(inventoryApi.queryComposeName, param)
}
//合并库存  
export function mergeProduct(param) {
  return post(inventoryApi.mergeProduct, param)
}
//增加组合商品名称  
export function addComposeName(param) {
  return post(inventoryApi.addComposeName, param)
}
//增加测试库存明细  
export function addDetail(param) {
  return post(inventoryApi.addDetail, param)
}
//查询库存商品名称  
export function queryProductName(param) {
  return post(inventoryApi.queryProductName, param)
}
//删除测试库存明细  
export function delDetail(param) {
  return post(inventoryApi.delDetail, param)
}
//增加测试暂估库存明细  
export function addEstimatedDetail(param) {
  return post(inventoryApi.addEstimatedDetail, param)
}
//删除测试暂估库存明细  
export function delEstimatedDetail(param) {
  return post(inventoryApi.delEstimatedDetail, param)
}

//删除测试暂估库存明细  
export function queryWaiting(param) {
  return post(inventoryApi.queryWaiting, param)
}


// !queryGroup  attendance
//考勤处的转审  
export function poapprove(param) {
  return get(attendanceApi.poapprove, param)
}

//地图人员信息
export function slectOneDay(param) {
  return post(attendanceApi.slectOneDay, param)
}
// 用户查询某一天打卡情况打卡
export function imgetkey(param) {
  return post(attendanceApi.imgetkey, param)
}

// 普通用户月打卡统计
export function getOneDayByUserId(param) {
  return get(attendanceApi.getOneDayByUserId, param)
}

// 密钥
export function selectByMonth(param) {
  return get(attendanceApi.selectByMonth, param)
}
// 考勤列表
export function selectByMonthAdmin(param) {
  return get(attendanceApi.selectByMonthAdmin, param)
}
// 获取轨迹信息
export function getTajectoryall(param) {
  return post(attendanceApi.getTajectoryall, param)
}


//查询公司考勤分组
export function queryGroup(param) {
  return get(attendanceApi.queryGroup, param)
}
//保存公司考情分组
export function saveGroup(param) {
  return post(attendanceApi.saveGroup, param)
}
//删除公司考情分组
export function deleteGroup(param) {
  return getOne(attendanceApi.deleteGroup, param)
}
//查询公司考勤人员
export function queryUser(param) {
  return get(attendanceApi.queryUser, param)
}
//查询班次
export function queryShift(param) {
  return get(attendanceApi.queryShift, param)
}
//保存班次
export function saveShift(param) {
  return post(attendanceApi.saveShift, param)
}






//! systemApi
//往来明细处置方式
export function queryDealType(param) {
  return post(systemApi.queryDealType, param)
}
//查询科目列表接口
export function getSubjectList(param) {
  return post(systemApi.getSubjectList, param)
}
//查询关键词列表
export function keywordsGetKeywordsList(param) {
  return post(systemApi.keywordsGetKeywordsList, param)
}
//员工工资-根据城市代码查询城市社保
export function querySocialSecurity(param) {
  return post(systemApi.querySocialSecurity, param)
}

//查询资产类型
export function getAssetListType(param) {
  return post(systemApi.getAssetListType, param)
}
//查询固定资产折旧方法
export function getZhejiuFangfa(param) {
  return post(systemApi.getZhejiuFangfa, param)
}
// 公式列表[报表]
export function reportFormulaList(param) {
  return post(api.reportFormulaList, param)
}




//! managerApi

//查询企业信息
export function queryEnterpriseInfo(param) {
  return post(managerApi.queryEnterpriseInfo, param)
}
//查询是否建账
export function reportInitQuery(param) {
  return post(managerApi.reportInitQuery, param)
}
//年收入不足6万人员查询
export function deductPersonQuery(param) {
  return post(managerApi.deductPersonQuery, param)
}
//年收入不足6万人员确认
export function confirm(param) {
  return post(managerApi.confirm, param)
}
//资产负债--一般企业
export function assetliabilityReport(param) {
  return post(managerApi.assetliabilityReport, param)
}
//资产负债--小企业
export function assetliabilityReportSmall(param) {
  return post(managerApi.assetliabilityReportSmall, param)
}
//利润表--一般企业
export function profitReport(param) {
  return post(managerApi.profitReport, param)
}
//利润表--小企业
export function profitReportSmall(param) {
  return post(managerApi.profitReportSmall, param)
}
//现金流量--一般企业
export function cashFlowReport(param) {
  return post(managerApi.cashFlowReport, param)
}
//现金流量--小企业
export function cashFlowReportSmall(param) {
  return post(managerApi.cashFlowReportSmall, param)
}
//科目余额--一般企业
export function querySubjectBalance(param) {
  return post(managerApi.querySubjectBalance, param)
}
//科目余额--小企业
export function querySubjectBalanceSmall(param) {
  return post(managerApi.querySubjectBalanceSmall, param)
}
//添加往来明细
export function addCagData(param) {
  return post(managerApi.addCagData, param)
}
//科目余额表-增加一条科目
export function addSubjectBalance(param) {
  return post(managerApi.addSubjectBalance, param)
}



//资产负债--单条修改
export function modifyAssetliabilityReport(param) {
  return post(managerApi.modifyAssetliabilityReport, param)
}
//现金流量-单条修改
export function modifyCashFlowReport(param) {
  return post(managerApi.modifyCashFlowReport, param)
}
//利润表-单条修改
export function modifyProfitReport(param) {
  return post(managerApi.modifyProfitReport, param)
}
//科目余额表-单条修改
export function modifySubjectBalance(param) {
  return post(managerApi.modifySubjectBalance, param)
}
//明细账-科目列表
export function ledgerSubject(param) {
  return post(managerApi.ledgerSubject, param)
}
//明细账-科目明细账
export function ledgerDetail(param) {
  return post(managerApi.ledgerDetail, param)
}



//应付账款
export function accountsPayable(param) {
  return post(managerApi.accountsPayable, param)
}
//应收账款
export function accountsReceivable(param) {
  return post(managerApi.accountsReceivable, param)
}
//预收账款
export function advanceReceipts(param) {
  return post(managerApi.advanceReceipts, param)
}
//预付账款
export function prepayment(param) {
  return post(managerApi.prepayment, param)
}
//其他应付账款
export function accountsPayableOther(param) {
  return post(managerApi.accountsPayableOther, param)
}
//其他应收账款
export function accountsReceivableOther(param) {
  return post(managerApi.accountsReceivableOther, param)
}

//其他应付账款-明细
export function accountsPayableOtherDetail(param) {
  return post(managerApi.accountsPayableOtherDetail, param)
}
//应付账款-明细
export function accountsPayableDetail(param) {
  return post(managerApi.accountsPayableDetail, param)
}

//应收账款-明细
export function accountsReceivableDetail(param) {
  return post(managerApi.accountsReceivableDetail, param)
}

//其他应收账款-明细
export function accountsReceivableOtherDetail(param) {
  return post(managerApi.accountsReceivableOtherDetail, param)
}
//预收账款-明细
export function advanceReceiptsDetail(param) {
  return post(managerApi.advanceReceiptsDetail, param)
}

//预付账款-明细
export function prepaymentDetail(param) {
  return post(managerApi.prepaymentDetail, param)
}
//查询税费明细
export function queryTaxDetail(param) {
  return post(managerApi.queryTaxDetail, param)
}
//查询处置的其他公司或者个人
export function queryTitel(param) {
  return post(managerApi.queryTitel, param)
}
//查询处置的其他公司或者个人
export function deal(param) {
  return post(managerApi.deal, param)
}


//固定资产清单
export function gudingAssetList(param) {
  return post(managerApi.gudingAssetList, param)
}
//固定资产折旧清单
export function gudingAssetZheJiuList(param) {
  return post(managerApi.gudingAssetZheJiuList, param)
}
//无形资产清单
export function wuxingAssetList(param) {
  return post(managerApi.wuxingAssetList, param)
}
//无形资产摊销清单
export function wuxingAssetTanXiaoList(param) {
  return post(managerApi.wuxingAssetTanXiaoList, param)
}
//无形资产摊销清单
export function gudingAssetDeal(param) {
  return post(managerApi.gudingAssetDeal, param)
}
//查询企业凭证
export function queryEnterpriseVoucher(param) {
  return post(managerApi.queryEnterpriseVoucher, param)
}
//增加凭证
export function addEnterpriseVoucher(param) {
  return post(managerApi.addEnterpriseVoucher, param)
}
//删除凭证
export function deleteEnterpriseVoucher(param) {
  return post(managerApi.deleteEnterpriseVoucher, param)
}
//凭证从新编码
export function voucherCodeReset(param) {
  return post(managerApi.voucherCodeReset, param)
}
//凭证从新编码
export function downloadGet(param) {
  return get(managerApi.downloadGet, param)
}


//配置的库存列表
export function inventoryList(param) {
  return post(managerApi.inventoryList, param)
}
//删除配置的库存
export function deleteInventory(param) {
  return post(managerApi.deleteInventory, param)
}
//配置库存
export function addInventory(param) {
  return post(managerApi.addInventory, param)
}

//国税申报-查询报税记录
export function queryBaoShuiStatusG(param) {
  return post(managerApi.queryBaoShuiStatusG, param)
}

//税费-申报
export function taxDetailDeal(param) {
  return post(managerApi.taxDetailDeal, param)
}
//修改为已报税
export function updateBaoShuiStatus(param) {
  return post(managerApi.updateBaoShuiStatus, param)
}
//员工工资-工资表
export function findEmployeeSalary(param) {
  return post(managerApi.findEmployeeSalary, param)
}
//员工工资-工资表V2
export function findEmployeeSalaryV2(param) {
  return post(managerApi.findEmployeeSalaryV2, param)
}
//员工工资-查询工资表-查询工资表综和v2(总表最左侧的综和)
export function findSalaryComprehensiveV2(param) {
  return post(managerApi.findSalaryComprehensiveV2, param)
}
//缓发工资提交审批[请求付款]-v2
export function submmitHuanfaSalaryCheck(param) {
  return post(managerApi.submmitHuanfaSalaryCheck, param)
}
//员工列表-添加员工用的花名册
export function queryStaff(param) {
  return post(managerApi.queryStaff, param)
}
//员工列表-添加员工
export function addSalaryStaff(param) {
  return post(managerApi.addSalaryStaff, param)
}
//员工列表-移除员工
export function removeSalaryStaff(param) {
  return post(managerApi.removeSalaryStaff, param)
}
//员工工资-[普通员工]
export function queryEmployeeSalaryUser(param) {
  return post(managerApi.queryEmployeeSalaryUser, param)
}
//员工工资-查询员工工资信息
export function querySalaryStaff(param) {
  return post(managerApi.querySalaryStaff, param)
}
//员工工资-查询个人社保信息
export function queryEmployeeNoun(param) {
  return post(managerApi.queryEmployeeNoun, param)
}
//员工工资-完善员工社保公积金信息
export function updateEmployeeNoun(param) {
  return post(managerApi.updateEmployeeNoun, param)
}
//员工工资-完善员工工资信息
export function updateEmployeeStaffInfo(param) {
  return post(managerApi.updateEmployeeStaffInfo, param)
}
//员工工资-修改罚款扣款
export function updateFine(param) {
  return post(managerApi.updateFine, param)
}
//员工工资-修改是否缴纳社保
export function updateIsPaymentSS(param) {
  return post(managerApi.updateIsPaymentSS, param)
}
//员工工资-制作工资
export function makeSalary(param) {
  return post(managerApi.makeSalary, param)
}

//员工工资-工资表(查询是否有未提交审批的工资表)
export function findEmployeeSalaryNosubmmit(param) {
  return post(managerApi.findEmployeeSalaryNosubmmit, param)
}
//员工工资-工资报税-查询报税金额
export function queryBaoshuiamount(param) {
  return post(managerApi.queryBaoshuiamount, param)
}
//员工工资-工资表
export function SalaryservicefindEmployeeSalary(param) {
  return post(managerApi.SalaryservicefindEmployeeSalary, param)
}
//员工工资-工资表提交审批
export function submmitSalaryCheck(param) {
  return post(managerApi.submmitSalaryCheck, param)
}
//员工工资-重新制作
export function reMakeSalary(param) {
  return post(managerApi.reMakeSalary, param)
}
//员工工资-工资报税
export function salaryserviceBaoshui(param) {
  return post(managerApi.salaryserviceBaoshui, param)
}
//员工工资-查询报税记录-个税
export function queryBaoShuiStatus(param) {
  return post(managerApi.queryBaoShuiStatus, param)
}
//员工工资-报税审核-工资报税
export function geshuiCheck(param) {
  return post(managerApi.geshuiCheck, param)
}
//员工工资-工资记录
export function salaryRecord(param) {
  return post(managerApi.salaryRecord, param)
}

//员工工资-查询申报数据
export function shenbaoDataQuery(param) {
  return post(managerApi.shenbaoDataQuery, param)
}
//员工工资-查询申报数据
export function baosong(param) {
  return post(managerApi.baosong, param)
}


//生成下载凭证任务
export function downloadVoucher(param) {
  return post(managerApi.downloadVoucher, param)
}
//查询下载任务
export function queryDownloadTask(param) {
  return post(managerApi.queryDownloadTask, param)
}











//员工工资-制作工资V2
export function makeSalaryV2(param) {
  return post(managerApi.makeSalaryV2, param)
}
//员工工资-重新算税
export function reCalculatTax(param) {
  return post(managerApi.reCalculatTax, param)
}
//员工工资-查询算税失败原因
export function querySalaryTaxCalculatRecord(param) {
  return post(managerApi.querySalaryTaxCalculatRecord, param)
}
//员工工资-撤销工资v2
export function revokeSalaryV2(param) {
  return post(managerApi.revokeSalaryV2, param)
}
//员工工资-查询工资表v2
export function findEmployeeSalaryStatusV2(param) {
  return post(managerApi.findEmployeeSalaryStatusV2, param)
}
//员工工资-查询工资列表v2
export function queryMonthSalaryListV2(param) {
  return post(managerApi.queryMonthSalaryListV2, param)
}
//工资表提交审批--v2
export function submmitSalaryCheckV2(param) {
  return post(managerApi.submmitSalaryCheckV2, param)
}
//工资表审批撤销--v2
export function submmitSalaryCheckRevoke(param) {
  return post(managerApi.submmitSalaryCheckRevoke, param)
}
//工资报税v2-工资报税v2
export function baoshuiV2(param) {
  return post(managerApi.baoshuiV2, param)
}
//工资报税v2-更正申报
export function baoshuiV2Correct(param) {
  return post(managerApi.baoshuiV2Correct, param)
}
//工资报税v2-查询申报状态（税局端）
export function queryShenbaoStatusV2(param) {
  return post(managerApi.queryShenbaoStatusV2, param)
}
//工资报税v2-查询申报失败原因
export function querySalaryTaxShenbaoRecord(param) {
  return post(managerApi.querySalaryTaxShenbaoRecord, param)
}
//工资报税v2-作废申报
export function baoshuiV2Zuofei(param) {
  return post(managerApi.baoshuiV2Zuofei, param)
}
//缴款-获取协议银行结果
export function getAgreementBankInfo(param) {
  return post(managerApi.getAgreementBankInfo, param)
}
//缴款-查询协议银行
export function queryAgreementBankInfo(param) {
  return post(managerApi.queryAgreementBankInfo, param)
}
//缴款-三方协议缴款
export function taxPayment(param) {
  return post(managerApi.taxPayment, param)
}
//缴款-三方协议缴款结果
export function getTaxPaymentResult(param) {
  return post(managerApi.getTaxPaymentResult, param)
}
//缴款-三方协议缴款结果
export function arrearsQuery(param) {
  return post(managerApi.arrearsQuery, param)
}
//缴款-三方协议缴款结果
export function setSalaryTaxPayed(param) {
  return post(managerApi.setSalaryTaxPayed, param)
}





//! userApi
//查询企业信息接口[查询自己所在公司信息]
export function queryEnterpriseInfoForUser(param) {
  return post(userApi.queryEnterpriseInfoForUser, param)
}
//删除银行
export function deleteEnterpriseBank(param) {
  return post(userApi.deleteEnterpriseBank, param)
}
//完善企业信息pc
export function perfectEnterpriseInfo(param) {
  return post(userApi.perfectEnterpriseInfo, param)
}
//查询用户信息
export function getEmployeeInfoUserId(param) {
  return post(userApi.getEmployeeInfoUserId, param)
}
//查询员工信息
export function getEmployeeInfo(param) {
  return post(userApi.getEmployeeInfo, param)
}
//增加员工信息-管理员添加其他
export function addEmployee(param) {
  return post(userApi.addEmployee, param)
}
//设置离职日期
export function dimission(param) {
  return post(userApi.dimission, param)
}
//完善员工信息[自己给自己]
export function prefectEnterpriseEmployee(param) {
  return post(userApi.prefectEnterpriseEmployee, param)
}
//修改员工信息-管理员修改其他人
export function modifyEnterpriseEmployee(param) {
  return post(userApi.modifyEnterpriseEmployee, param)
}
//查询名下企业[管理员]
export function adminGetEnterpriseList(param) {
  return post(userApi.adminGetEnterpriseList, param)
}
//企业后台登录刷新token[选择或切换企业]PC
export function flashTokenPc(param) {
  return post(userApi.flashTokenPc, param)
}
//总后台登录跳转到企业后台刷新token
export function flashTokenPcPlatform(param) {
  return post(userApi.flashTokenPcPlatform, param)
}
//获取短验
export function getSms(param) {
  return post(userApi.getSms, param)
}
//用户登录接口-PC短信登录
export function adminLoginMsm(param) {
  return post(userApi.adminLoginMsm, param)
}
//查询花名册列表
export function getEnterpriseEmployee(param) {
  return post(userApi.getEnterpriseEmployee, param)
}
//查询花名册列表记录
export function getEnterpriseEmployeeHistory(param) {
  return post(userApi.getEnterpriseEmployeeHistory, param)
}
//变更管理员
export function exchangeEnterpriseAdmin(param) {
  return post(userApi.exchangeEnterpriseAdmin, param)
}
//员工授权
export function enterpriseGrantAuthorization(param) {
  return post(userApi.enterpriseGrantAuthorization, param)
}
//批员工[主动申请加入公司的,微信邀请的]
export function checkApplyJoinEnterprise(param) {
  return post(userApi.checkApplyJoinEnterprise, param)
}
//查询部门列表
export function queryEnterpriseDepartment(param) {
  return post(userApi.queryEnterpriseDepartment, param)
}
//删除部门
export function deleteEnterpriseDepartment(param) {
  return post(userApi.deleteEnterpriseDepartment, param)
}
//添加部门
export function addEnterpriseDepartment(param) {
  return post(userApi.addEnterpriseDepartment, param)
}
//修改部门
export function modifyEnterpriseDepartment(param) {
  return post(userApi.modifyEnterpriseDepartment, param)
}
//查询补助补贴
export function queryBuzhuButie(param, flag) {
  return post(userApi.queryBuzhuButie, param, flag)
}
//添加补助补贴
export function addSubsidy(param) {
  return post(userApi.addSubsidy, param)
}
//取消员工补助补贴
export function cancelSubsidy(param) {
  return post(userApi.cancelSubsidy, param)
}
//员工工资-获取付款人
export function getEmployeeFukuan(param) {
  return post(userApi.getEmployeeFukuan, param)
}
//员工工资-获取审批人.付款人
export function getEnterpriseEmployeeCheckers(param) {
  return post(userApi.getEnterpriseEmployeeCheckers, param)
}
//企业登记-税友
export function enterpriseRegister(param) {
  return post(userApi.enterpriseRegister, param)
}
//校验申报密码
export function checkShenBaoPassword(param) {
  return post(userApi.checkShenBaoPassword, param)
}
//查询企业登记信息
export function queryRegsterDetail(param) {
  return post(userApi.queryRegsterDetail, param)
}
export function defaultfRegsterDjhm(param) {
  return post(userApi.defaultfRegsterDjhm, param)
}
//导入员工接口
export function uploadEmployee(param) {
  return post(userApi.uploadEmployee, param)
}
//更新专项信息
export function updateSpecialInfo(param) {
  return post(userApi.updateSpecialInfo, param)
}
//查询专项信息
export function getSpecialInfo(param) {
  return post(userApi.getSpecialInfo, param)
}
//人员列表（税局）
export function queryPTSEmployeeList(param) {
  return post(userApi.queryPTSEmployeeList, param)
}




//! voucherApi
//查询公司票据
export function queryEnterpriseBill(param) {
  return post(voucherApi.queryEnterpriseBill, param)
}
//查询票据信息
export function queryBillInfo(param) {
  return post(voucherApi.queryBillInfo, param)
}
//消息列表
export function workFlowList(param) {
  return post(voucherApi.workFlowList, param)
}
//变更收款人
export function changePayee(param) {
  return post(voucherApi.changePayee, param)
}
//变更付款人
export function exchangePayer(param) {
  return post(voucherApi.exchangePayer, param)
}
//票据转审PC
export function workflowToOther(param) {
  return post(voucherApi.workflowToOther, param)
}
//工资驳回V2
export function payReject(param) {
  return post(voucherApi.payReject, param)
}

//! bankbillApi

//查询银行流水详细
export function queryBankFlow(param) {
  return post(bankbillApi.queryBankFlow, param)
}
//查询银行流水
export function queryBankFlowList(param) {
  return post(bankbillApi.queryBankFlowList, param)
}
//上传银行流水
export function uploadBankFlow(param) {
  return post(bankbillApi.uploadBankFlow, param)
}
//删除流水用途
export function deleteBankFlow(param) {
  return post(bankbillApi.deleteBankFlow, param)
}
//修改流水用途
export function modifyBankFlowYongTu(param) {
  return post(bankbillApi.modifyBankFlowYongTu, param)
}




// 导入

//花名册 人员Excel导入
export function importExcel(param) {
  return post(batchApi.importExcel, param)
}
//花名册 查询导入状态
export function getImportExcelStatus(param) {
  return post(batchApi.getImportExcelStatus, param)
}

//花名册 获取Excel导入的所有员工数据及其部门名称
export function getAllCacheEmpData(param) {
  return post(batchApi.getAllCacheEmpData, param)
}
//花名册 设置部门所属职能
export function setDeptFunction(param) {
  return post(batchApi.setDeptFunction, param)
}
//花名册 获取Excel导入信息(未完善)的员工数据
export function getPerfectEmpData(param) {
  return post(batchApi.getPerfectEmpData, param)
}
//花名册 获取Excel导入信息(未完善)的员工数据
export function getNotPerfectEmpData(param) {
  return post(batchApi.getNotPerfectEmpData, param)
}
//将员工添加到花名册、工资表
export function addToRoster(param) {
  return post(batchApi.addToRoster, param)
}
//获取添加到花名册的状态
export function getaddRosterStatus(param) {
  return post(batchApi.getaddRosterStatus, param)
}
//获取添加到花名册的状态
export function getEmpList(param) {
  return post(batchApi.getEmpList, param)
}
//修改Excel导入的员工信息
export function updateImportEmpInfo(param) {
  return post(batchApi.updateImportEmpInfo, param)
}


//获取添加到花名册的状态
export function refunds(param) {
  return post(payserverApi.refunds, param)
}
//获取添加到花名册的状态
export function queryOrders(param) {
  return post(payserverApi.queryOrders, param)
}