<template>
  <div class="loading-css">
    <i></i>
    <i></i>
    <i></i>
    <i></i>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@-webkit-keyframes loading {
  0% {
    margin-bottom: 0;
  }

  50% {
    margin-bottom: 10px;
  }

  100% {
    margin-bottom: 0px;
  }
}

.loading-css {
  height: 20px;
}

.loading-css i {
  display: inline-block;
  margin-left: 5px;
  background: #999999;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.loading-css i:nth-child(1) {
  -webkit-animation: loading 0.9s ease-in 0s infinite;
}

.loading-css i:nth-child(2) {
  -webkit-animation: loading 0.9s ease-in 0.2s infinite;
}

.loading-css i:nth-child(3) {
  -webkit-animation: loading 0.9s ease-in 0.4s infinite;
}

.loading-css i:nth-child(4) {
  -webkit-animation: loading 0.9s ease-in 0.6s infinite;
}
</style>