const employeeModel = {
  jurisdiction: {
    title: "权限",
    list: [
      {
        key: "100101",
        value: "全部权限",
        desc: "以下所有权限",
        select: false
      },
      {
        key: "100104",
        value: "花名册",
        desc: "邀请员工、管理档案信息",
        select: false
      },
      {
        key: "100105", value: "工资", desc: "查看制作工资表",
        select: false
      },
      {
        key: "100109", value: "付款", desc: "报销、工资及其他日常支付业务",
        select: false
      },
      {
        key: "100110", value: "财务", desc: "查看财务报表及纳税申报",
        select: false
      },
      {
        key: "100108",
        value: "考勤",
        desc: "员工日常打卡考勤",
        select: false
        // conceal: true,
      },
      {
        key: "100103",
        value: "开票",
        desc: "开票、查看发票。",
        conceal: true,
        select: false
      },
      {
        key: "100106",
        value: "社保管理",
        desc: "社保、公积金增减员操作",
        conceal: true,
        select: false
      },
    ]
  },
  identityData: {
    title: "员工身份",
    list: [
      {
        id: "1",
        text: "普通员工(雇员)",
      },
      // {
      //   id: "2",
      //   text: "股东",
      // },
      {
        id: "3",
        text: "应届实习生(全日制学历教育)",
      },
      // {
      //   id: "4",
      //   text: "保险营销员",
      // },
      // {
      //   id: "5",
      //   text: "证券经纪人",
      // },
      {
        id: "6",
        text: "其他",
      },
    ],
  },
  contractTypeData: {
    title: "合同类型",
    list: [
      {
        id: "3",
        text: "劳动合同",
      },
      {
        id: "2",
        text: "劳务合同",
      },
      {
        id: "1",
        text: "实习协议",
      },
    ],
  },
  idTypeData: {
    title: "证件类型",
    list: [
      {
        id: "1",
        text: "居民身份证",
      },
      // {
      //   id: "2",
      //   text: "中国护照",
      // },
      {
        id: "3",
        text: "港澳居民来往内地通行证",
      },
      {
        id: "4",
        text: "台湾居民来往内地通行证",
      },
      {
        id: "5",
        text: "外国护照",
      },
    ],
  },
  gangaoDate: {
    title: "gangao",
    list: [{
      id: "3",
      text: "港澳居民来往内地通行证",
    },
    {
      id: "4",
      text: "台湾居民来往内地通行证",
    },]
  },
  registeredData: {
    title: "户口",
    list: [
      {
        id: "2",
        text: "本市城镇",
      },
      {
        id: "1",
        text: "本市农村",
      },
      {
        id: "4",
        text: "外埠城镇",
      },
      {
        id: "3",
        text: "外埠农村",
      },
    ],
  },
  // identityData: {
  //   title: "员工身份",
  //   list: [
  //     {
  //       id: "1",
  //       text: "普通员工(雇员)",
  //     },
  //     {
  //       id: "2",
  //       text: "股东",
  //     },
  //     {
  //       id: "3",
  //       text: "应届实习生(全日制学历教育)",
  //     },
  //     {
  //       id: "4",
  //       text: "保险营销员",
  //     },
  //     {
  //       id: "5",
  //       text: "证券经纪人",
  //     },
  //     {
  //       id: "6",
  //       text: "其他",
  //     },
  //   ],
  // },
  educationData: {
    title: "学历",
    list: [
      {
        id: "5",
        text: "博士及以上",
      },
      {
        id: "4",
        text: "硕士",
      },
      {
        id: "3",
        text: "本科",
      },
      {
        id: "2",
        text: "专科",
      },
      {
        id: "1",
        text: "高中及以下",
      },
    ],
  },
  contractTermData: {
    title: "合同期限",
    list: [
      {
        id: "1",
        text: "1年及1年以內",
      },
      {
        id: "2",
        text: "2年",
      },
      {
        id: "3",
        text: "3年",
      },
      {
        id: "4",
        text: "4年",
      },
      {
        id: "5",
        text: "5年",
      },
      {
        id: "6",
        text: "6年",
      },
    ],
  },
  certifycateData: {
    title: "其他证件类型",
    list: [
      {
        id: "1",
        text: "外国人永久居留身份证",
      },
      {
        id: "2",
        text: "外国人工作许可证(A类)",
      },
      {
        id: "3",
        text: "外国人工作许可证(B类)",
      },
      {
        id: "4",
        text: "外国人工作许可证(C类)",
      },
      {
        id: "5",
        text: "外国护照",
      },
    ],
  },
  boolData: {
    title: "其他是否",
    list: [
      {
        id: "1",
        text: "是",
      },
      {
        id: "-1",
        text: "否",
      },
    ],
  },
  sssyData: {
    title: "涉税事由",
    list: [
      {
        id: "1",
        text: "任职受雇",
        select: false
      },
      {
        id: "2",
        text: "提供临时劳务",
        select: false
      },
      {
        id: "3",
        text: "转让财产",
        select: false
      },
      {
        id: "4",
        text: "从事投资和经营活动",
        select: false
      },
      {
        id: "5",
        text: "其他",
        select: false
      },
    ],
  },
  ruzhiqingxingData: {
    title: "入职情形",
    list: [
      {
        id: "1",
        text: "当年首次入职学生",
      },
      {
        id: "2",
        text: "当年首次入职其他人员",
      },
    ],
  },
  payrollScale: {
    title: "工资比例",
    list: [
      {
        id: "100",
        text: "100% 全额",
      },
      {
        id: "90",
        text: "90%",
      },
      {
        id: "80",
        text: "80%",
      },
      {
        id: "70",
        text: "70%",
      },
      {
        id: "60",
        text: "60%",
      },
      {
        id: "50",
        text: "50%",
      },
      {
        id: "40",
        text: "40%",
      },
      {
        id: "30",
        text: "30%",
      },
      {
        id: "20",
        text: "20%",
      },
      {
        id: "10",
        text: "10%",
      },
      {
        id: "0",
        text: "0%",
      },

    ]
  },
  statusList: {
    title: "员工状态",
    list: [
      {
        id: 1,
        text: "在职",
      },
      {
        id: 2,
        text: "离职",
      }]
  },
  statusList1: {
    title: "员工状态",
    list: [
      {
        id: 10,
        text: "待入职",
      },
      {
        id: 99,
        text: "审核不通过",
      }]
  },
  statusList2: {
    title: "员工状态",
    list: [
      {
        id: 1,
        text: "在职",
      },
      {
        id: 2,
        text: "离职",
      },
      {
        id: 10,
        text: "待入职",
      },
      {
        id: 99,
        text: "审核不通过",
      }]
  },
  dutiesType: {
    title: "职务",
    list: [
      {
        id: '1',
        text: "普通",
      },
      {
        id: '2',
        text: "高层",
      }]
  },
  //是否缓发 1为正常发放 2为缓发 3停发 4无薪
  salaryStatus: {
    title: "部门列表",
    list: [
      {
        id: '1',
        text: "正常发放",
        color: "#70C050",
        desc: "正常发放工资、社保、公积金",
      },
      {
        id: '2',
        text: "缓发工资",
        color: "#F58E21",
        desc: "公司缴纳社保、公积金、个税，延缓发放工资！",
      },
      // {
      //   id: '3',
      //   text: "停发工资",
      //   color: "#EA0200",
      //   desc: "公司缴纳社保、公积金，不发工资！",
      // },
      // {
      //   id: '4',
      //   text: "无工资",
      //   color: "#BBBBBB",
      //   desc: "企业只负担个人社保公积金等。",
      // },
    ]
  },

  department: {
    title: "部门列表",
    list: [
      {
        id: '10',
        text1: "市场销售",
        text2: "运营类",
      },
      {
        id: '11',
        text1: "管理后勤",
        text2: "服务类",
      },
      {
        id: '12',
        text1: "产品设计",
        text2: "研发类",
      },
    ]
  },
  cityMap: [
    { id: "110000", text: "北京市" },
    { id: "120000", text: "天津市" },
    { id: "130000", text: "河北省" },
    { id: "140000", text: "山西省" },
    { id: "150000", text: "内蒙古自治区" },
    { id: "210000", text: "辽宁省" },
    { id: "220000", text: "吉林省" },
    { id: "230000", text: "黑龙江省" },
    { id: "310000", text: "上海市" },
    { id: "320000", text: "江苏省" },
    { id: "330000", text: "浙江省" },
    { id: "340000", text: "安徽省" },
    { id: "350000", text: "福建省" },
    { id: "360000", text: "江西省" },
    { id: "370000", text: "山东省" },
    { id: "410000", text: "河南省" },
    { id: "420000", text: "湖北省" },
    { id: "430000", text: "湖南省" },
    { id: "440000", text: "广东省" },
    { id: "450000", text: "广西壮族自治区" },
    { id: "460000", text: "海南省" },
    { id: "500000", text: "重庆市" },
    { id: "510000", text: "四川省" },
    { id: "520000", text: "贵州省" },
    { id: "530000", text: "云南省" },
    { id: "540000", text: "西藏自治区" },
    { id: "610000", text: "陕西省" },
    { id: "620000", text: "甘肃省" },
    { id: "630000", text: "青海省" },
    { id: "640000", text: "宁夏回族自治区" },
    { id: "650000", text: "新疆维吾尔自治区" },
    { id: "710000", text: "台湾省" },
    { id: "810000", text: "香港特别行政区" },
    { id: "820000", text: "澳门特别行政区" },
  ],
  sex: {
    title: "性别",
    list: [
      {
        id: '1',
        text: "男",
      },
      {
        id: '2',
        text: "女",
      },
    ]
  },
  zinv: {
    list: [
      {
        id: '500',
        text: "按 500元/月 定额扣除",
      },
      {
        id: '1000',
        text: "按 1000元/月 定额扣除",
      },
      {
        id: '1500',
        text: "按 1500元/月 定额扣除",
      },
      {
        id: '2000',
        text: "按 2000元/月 定额扣除",
      },
      // {
      //   id: 5,
      //   text: "按 2500元/月 全额扣除",
      // },
      {
        id: '3000',
        text: "按 3000元/月 定额扣除",
      },
    ],
    context: [
      {
        text:
          "纳税人的子女处于学前教育阶段或者接受全日制学历教育的相关支出，按照每个子女每月1000元的标准定额扣除。",
      },
      {
        text:
          "子女年满3岁至小学前处于学前教育阶段。学历教育包括义务教育（小学、初中教育）、高中阶段教育（普通高中、中等职业、技工教育）、高等教育（大学专科、大学本科、硕士研究生、博士研究生教育）。",
      },
      {
        text:
          "父母可以选择由其中一方按扣除标准的100%扣除，也可以选择由双方分别按扣除标准的50%扣除，具体扣除方式在一个纳税年度内不能变更。",
      },
    ],
  },
  jixu: {
    list: [
      {
        id: '400',
        text: "按 400元/月 定额扣除",
        desc: "学历(学位)继续教育",
      },
      {
        id: '3600',
        text: "按 3600元/月 定额扣除",
        desc: "职业资格继续教育",
      },
    ],
    context: [
      {
        text:
          "纳税人在中国境内接受学历（学位）继续教育的支出，在学历（学位）教育期间按照每月400元定额扣除。同一学历（学位）继续教育的扣除期限不能超过48个月。纳税人接受技能人员职业资格继续教育、专业技术人员职业资格继续教育的支出，在取得相关证书的当年，按照3600元定额扣除。",
      },
      {
        text:
          "个人接受本科及以下学历（学位）继续教育，符合规定扣除条件的，可以选择由其父母扣除也可以选择由本人扣除。",
      },
    ],
  },
  daikuan: {
    list: [
      {
        id: '1000',
        text: "按 1000元/月 定额扣除",
      },
      {
        id: '500',
        text: "按 500元/月 定额扣除",
      },
    ],
    context: [
      {
        text:
          "纳税人本人或配偶单独或共同使用商业银行或住房公积金个人住房贷款为本人或其配偶购买中国境内住房，发生的首套住房贷款利息支出，在实际发生贷款利息的年度，按照每月1000元标准定额扣除，扣除期限最长不超过240个月。纳税人只能享受一次首套住房贷款的利息扣除。",
      },
    ],
  },
  zhufang: {
    list: [
      {
        id: '800',
        text: "按 800元/月 定额扣除",
      },
      {
        id: '1100',
        text: "按 1100元/月 定额扣除",
      },
      {
        id: '1500',
        text: "按 1500元/月 定额扣除",
      },
    ],
    context: [
      {
        text:
          "纳税人在主要工作城市没有自有住房而发生的住房租金支出，可以按照以下标准定额扣除：",
      },
      {
        text:
          "（一）直辖市、省会（首府）城市、计划单列市以及国务院确定的其他城市，扣除标准为每月1500元。",
      },
      {
        text:
          "（二）除第一项所列城市以外，市辖区户籍人口超过100万的城市，扣除标准为每月1100元；市辖区户籍人口不超过100万（含）的城市，扣除标准为每月800元。",
      },
    ],
  },
  shanyang: {
    list: [
      {
        id: '2000',
        text: "按 2000元/月 定额扣除",
      },
      {
        id: '-1',
        text: "按 分摊金额定额扣除",
      },
    ],
    context: [
      {
        text:
          "纳税人赡养一位及以上被赡养人的赡养支出，统一按照以下标准定额扣除：",
      },
      {
        text: "（一）纳税人为独生子女的，按照每月2000元的标准定额扣除",
      },
      {
        text:
          "（二）纳税人为非独生子女的，由其与兄弟姐妹分摊每月2000元的扣除额度，每人分摊的额度不能超过每月1000元。可以由赡养人均摊或约定分摊，也可以由被赡养人指定分摊。约定或指定分摊的须签订书面分摊协议，指定分摊优先于约定分摊。具体分摊方式和额度在一个纳税年度内不能变更。",
      },
      {
        text:
          "被赡养人是指年满60岁（含）的父母，以及子女均已去世的年满60岁的祖父母、外祖父母。",
      },
    ],
  },
  dabing: {
    list: [
      {
        id: -1,
        text: "按  元扣除",
      },
    ],
    context: [
      {
        text:
          "在一个纳税年度的，纳税人发生的与基本医保相关的医药费用，扣除医保报销后个人负担（指医保目录范围内的自付部分）累计超过15000元的部分，由纳税人在办理年度汇算清缴时，在80000元限额内据实扣除。",
      },
      {
        text:
          "纳税人发生的医药费用支出可以选择由本人或其配偶扣除；未成年子女发生的医药费用支出可以选择由其父母一方扣除。",
      },
    ],
  },
}
//国家名称
const countryData = [
  {
    id: "1",
    text: "中国",
  },
  {
    id: "2",
    text: "中国澳门",
  },
  {
    id: "3",
    text: "中国台湾",
  },
  {
    id: "4",
    text: "中国香港",
  },
  {
    id: "5",
    text: "阿尔巴尼亚",
  },
  {
    id: "6",
    text: "阿尔及利亚",
  },
  {
    id: "7",
    text: "阿富汗",
  },
  {
    id: "8",
    text: "阿根廷",
  },
  {
    id: "9",
    text: "阿联酋",
  },
  {
    id: "10",
    text: "阿鲁巴",
  },
  {
    id: "11",
    text: "阿曼",
  },
  {
    id: "12",
    text: "阿塞拜疆",
  },
  {
    id: "13",
    text: "埃及",
  },
  {
    id: "14",
    text: "埃塞俄比亚",
  },
  {
    id: "15",
    text: "爱尔兰",
  },
  {
    id: "16",
    text: "爱沙尼亚",
  },
  {
    id: "17",
    text: "安道尔",
  },
  {
    id: "18",
    text: "安哥拉",
  },
  {
    id: "19",
    text: "安圭拉",
  },
  {
    id: "20",
    text: "安提瓜和巴布达",
  },
  {
    id: "21",
    text: "奥地利",
  },
  {
    id: "22",
    text: "澳大利亚",
  },
  {
    id: "23",
    text: "巴巴多斯",
  },
  {
    id: "24",
    text: "巴布亚新几内亚",
  },
  {
    id: "25",
    text: "巴哈马",
  },
  {
    id: "26",
    text: "巴基斯坦",
  },
  {
    id: "27",
    text: "巴拉圭",
  },
  {
    id: "28",
    text: "巴勒斯坦",
  },
  {
    id: "29",
    text: "巴林",
  },
  {
    id: "30",
    text: "巴拿马",
  },
  {
    id: "31",
    text: "巴西",
  },
  {
    id: "32",
    text: "白俄罗斯",
  },
  {
    id: "33",
    text: "百慕大",
  },
  {
    id: "34",
    text: "保加利亚",
  },
  {
    id: "35",
    text: "北马里亚纳",
  },
  {
    id: "36",
    text: "贝宁",
  },
  {
    id: "37",
    text: "比利时",
  },
  {
    id: "38",
    text: "冰岛",
  },
  {
    id: "39",
    text: "波多黎各",
  },
  {
    id: "40",
    text: "波黑",
  },
  {
    id: "41",
    text: "波兰",
  },
  {
    id: "42",
    text: "玻利维亚",
  },
  {
    id: "43",
    text: "伯利兹",
  },
  {
    id: "44",
    text: "博茨瓦纳",
  },
  {
    id: "45",
    text: "不丹",
  },
  {
    id: "46",
    text: "布基纳法索",
  },
  {
    id: "47",
    text: "布隆迪",
  },
  {
    id: "48",
    text: "布维岛",
  },
  {
    id: "49",
    text: "朝鲜",
  },
  {
    id: "50",
    text: "赤道几内亚",
  },
  {
    id: "51",
    text: "丹麦",
  },
  {
    id: "52",
    text: "德国",
  },
  {
    id: "53",
    text: "东帝汶",
  },
  {
    id: "54",
    text: "多哥",
  },
  {
    id: "55",
    text: "多米尼加",
  },
  {
    id: "56",
    text: "多米尼克",
  },
  {
    id: "57",
    text: "俄罗斯联邦",
  },
  {
    id: "58",
    text: "厄瓜多尔",
  },
  {
    id: "59",
    text: "厄立特里亚",
  },
  {
    id: "60",
    text: "法国",
  },
  {
    id: "61",
    text: "法罗群岛",
  },
  {
    id: "62",
    text: "法属波利尼西亚",
  },
  {
    id: "63",
    text: "法属圭亚那",
  },
  {
    id: "64",
    text: "法属南部领地",
  },
  {
    id: "65",
    text: "梵蒂冈",
  },
  {
    id: "66",
    text: "菲律宾",
  },
  {
    id: "67",
    text: "斐济",
  },
  {
    id: "68",
    text: "芬兰",
  },
  {
    id: "69",
    text: "佛得角",
  },
  {
    id: "70",
    text: "福克兰群岛（马尔维纳斯）",
  },
  {
    id: "71",
    text: "冈比亚",
  },
  {
    id: "72",
    text: "刚果（布）",
  },
  {
    id: "73",
    text: "刚果（金）",
  },
  {
    id: "74",
    text: "哥伦比亚",
  },
  {
    id: "75",
    text: "哥斯达黎加",
  },
  {
    id: "76",
    text: "格林纳达",
  },
  {
    id: "77",
    text: "格陵兰",
  },
  {
    id: "78",
    text: "格鲁吉亚",
  },
  {
    id: "79",
    text: "根西岛",
  },
  {
    id: "80",
    text: "古巴",
  },
  {
    id: "81",
    text: "瓜德罗普",
  },
  {
    id: "82",
    text: "关岛",
  },
  {
    id: "83",
    text: "圭亚那",
  },
  {
    id: "84",
    text: "哈萨克斯坦",
  },
  {
    id: "85",
    text: "海地",
  },
  {
    id: "86",
    text: "韩国",
  },
  {
    id: "87",
    text: "荷兰",
  },
  {
    id: "88",
    text: "荷属安的列斯",
  },
  {
    id: "89",
    text: "赫德岛和麦克唐纳岛",
  },
  {
    id: "90",
    text: "黑山",
  },
  {
    id: "91",
    text: "洪都拉斯",
  },
  {
    id: "92",
    text: "基里巴斯",
  },
  {
    id: "93",
    text: "吉布提",
  },
  {
    id: "94",
    text: "吉尔吉斯斯坦",
  },
  {
    id: "95",
    text: "几内亚",
  },
  {
    id: "96",
    text: "几内亚比绍",
  },
  {
    id: "97",
    text: "加拿大",
  },
  {
    id: "98",
    text: "加纳",
  },
  {
    id: "99",
    text: "加蓬",
  },
  {
    id: "100",
    text: "柬埔寨",
  },
  {
    id: "101",
    text: "捷克",
  },
  {
    id: "102",
    text: "津巴布韦",
  },
  {
    id: "103",
    text: "喀麦隆",
  },
  {
    id: "104",
    text: "卡塔尔",
  },
  {
    id: "105",
    text: "开曼群岛",
  },
  {
    id: "106",
    text: "科科斯（基林）群岛",
  },
  {
    id: "107",
    text: "科摩罗",
  },
  {
    id: "108",
    text: "科特迪瓦",
  },
  {
    id: "109",
    text: "科威特",
  },
  {
    id: "110",
    text: "克罗地亚",
  },
  {
    id: "112",
    text: "肯尼亚",
  },
  {
    id: "113",
    text: "库克群岛",
  },
  {
    id: "114",
    text: "拉脱维亚",
  },
  {
    id: "115",
    text: "莱索托",
  },
  {
    id: "116",
    text: "老挝",
  },
  {
    id: "117",
    text: "黎巴嫩",
  },
  {
    id: "118",
    text: "立陶宛",
  },
  {
    id: "119",
    text: "利比里亚",
  },
  {
    id: "120",
    text: "利比亚",
  },
  {
    id: "121",
    text: "列支敦士登",
  },
  {
    id: "122",
    text: "留尼汪",
  },
  {
    id: "123",
    text: "卢森堡",
  },
  {
    id: "124",
    text: "卢旺达",
  },
  {
    id: "125",
    text: "罗马尼亚",
  },
  {
    id: "126",
    text: "马达加斯加",
  },
  {
    id: "127",
    text: "马尔代夫",
  },
  {
    id: "128",
    text: "马耳他",
  },
  {
    id: "129",
    text: "马拉维",
  },
  {
    id: "130",
    text: "马来西亚",
  },
  {
    id: "131",
    text: "马里",
  },
  {
    id: "132",
    text: "马绍尔群岛",
  },
  {
    id: "133",
    text: "马提尼克",
  },
  {
    id: "134",
    text: "马约特",
  },
  {
    id: "135",
    text: "毛里求斯",
  },
  {
    id: "136",
    text: "毛里塔尼亚",
  },
  {
    id: "137",
    text: "美国",
  },
  {
    id: "138",
    text: "美国本土外小岛屿",
  },
  {
    id: "139",
    text: "美属萨摩亚",
  },
  {
    id: "140",
    text: "美属维尔京群岛",
  },
  {
    id: "141",
    text: "蒙古",
  },
  {
    id: "142",
    text: "蒙特塞拉特",
  },
  {
    id: "143",
    text: "孟加拉国",
  },
  {
    id: "144",
    text: "秘鲁",
  },
  {
    id: "145",
    text: "密克罗尼西亚联邦",
  },
  {
    id: "146",
    text: "缅甸",
  },
  {
    id: "147",
    text: "摩尔多瓦",
  },
  {
    id: "148",
    text: "摩洛哥",
  },
  {
    id: "149",
    text: "摩纳哥",
  },
  {
    id: "150",
    text: "莫桑比克",
  },
  {
    id: "151",
    text: "墨西哥",
  },
  {
    id: "152",
    text: "纳米比亚",
  },
  {
    id: "153",
    text: "南非",
  },
  {
    id: "154",
    text: "南极洲",
  },
  {
    id: "155",
    text: "南乔治亚岛和南桑德韦奇岛",
  },
  {
    id: "156",
    text: "南斯拉夫",
  },
  {
    id: "157",
    text: "南苏丹",
  },
  {
    id: "158",
    text: "瑙鲁",
  },
  {
    id: "159",
    text: "尼泊尔",
  },
  {
    id: "160",
    text: "尼加拉瓜",
  },
  {
    id: "161",
    text: "尼日尔",
  },
  {
    id: "162",
    text: "尼日利亚",
  },
  {
    id: "163",
    text: "纽埃",
  },
  {
    id: "164",
    text: "挪威",
  },
  {
    id: "165",
    text: "诺福克岛",
  },
  {
    id: "166",
    text: "帕劳",
  },
  {
    id: "167",
    text: "皮特凯恩",
  },
  {
    id: "168",
    text: "葡萄牙",
  },
  {
    id: "169",
    text: "前南马其顿",
  },
  {
    id: "170",
    text: "日本",
  },
  {
    id: "171",
    text: "瑞典",
  },
  {
    id: "172",
    text: "瑞士",
  },
  {
    id: "173",
    text: "萨尔瓦多",
  },
  {
    id: "174",
    text: "萨摩亚",
  },
  {
    id: "175",
    text: "塞尔维亚",
  },
  {
    id: "176",
    text: "塞拉利昂",
  },
  {
    id: "178",
    text: "塞内加尔",
  },
  {
    id: "179",
    text: "塞浦路斯",
  },
  {
    id: "180",
    text: "塞舌尔",
  },
  {
    id: "181",
    text: "沙特阿拉伯",
  },
  {
    id: "182",
    text: "圣诞岛",
  },
  {
    id: "183",
    text: "圣多美和普林西比",
  },
  {
    id: "184",
    text: "圣赫勒拿",
  },
  {
    id: "185",
    text: "圣基茨和尼维斯",
  },
  {
    id: "186",
    text: "圣卢西亚",
  },
  {
    id: "187",
    text: "圣马力诺",
  },
  {
    id: "188",
    text: "圣皮埃尔和密克隆",
  },
  {
    id: "189",
    text: "圣文森特和格林纳丁斯",
  },
  {
    id: "190",
    text: "斯里兰卡",
  },
  {
    id: "191",
    text: "斯洛伐克",
  },
  {
    id: "192",
    text: "斯洛文尼亚",
  },
  {
    id: "193",
    text: "斯瓦尔巴岛和扬马延岛",
  },
  {
    id: "194",
    text: "斯威士兰",
  },
  {
    id: "195",
    text: "苏丹",
  },
  {
    id: "196",
    text: "苏里南",
  },
  {
    id: "197",
    text: "所罗门群岛",
  },
  {
    id: "198",
    text: "索马里",
  },
  {
    id: "199",
    text: "塔吉克斯坦",
  },
  {
    id: "200",
    text: "泰国",
  },
  {
    id: "201",
    text: "坦桑尼亚",
  },
  {
    id: "202",
    text: "汤加",
  },
  {
    id: "203",
    text: "特克斯和凯科斯群岛",
  },
  {
    id: "204",
    text: "特立尼达和多巴哥",
  },
  {
    id: "205",
    text: "突尼斯",
  },
  {
    id: "206",
    text: "图瓦卢",
  },
  {
    id: "207",
    text: "土耳其",
  },
  {
    id: "208",
    text: "土库曼斯坦",
  },
  {
    id: "209",
    text: "托克劳",
  },
  {
    id: "210",
    text: "瓦利斯和富图纳",
  },
  {
    id: "211",
    text: "瓦努阿图",
  },
  {
    id: "212",
    text: "危地马拉",
  },
  {
    id: "213",
    text: "委内瑞拉",
  },
  {
    id: "214",
    text: "文莱",
  },
  {
    id: "215",
    text: "乌干达",
  },
  {
    id: "216",
    text: "乌克兰",
  },
  {
    id: "217",
    text: "乌拉圭",
  },
  {
    id: "218",
    text: "乌兹别克斯坦",
  },
  {
    id: "219",
    text: "西班牙",
  },
  {
    id: "220",
    text: "西撒哈拉",
  },
  {
    id: "221",
    text: "希腊",
  },
  {
    id: "222",
    text: "新加坡",
  },
  {
    id: "223",
    text: "新喀里多尼亚",
  },
  {
    id: "224",
    text: "新西兰",
  },
  {
    id: "225",
    text: "匈牙利",
  },
  {
    id: "226",
    text: "叙利亚",
  },
  {
    id: "227",
    text: "牙买加",
  },
  {
    id: "228",
    text: "亚美尼亚",
  },
  {
    id: "229",
    text: "也门",
  },
  {
    id: "230",
    text: "伊拉克",
  },
  {
    id: "231",
    text: "伊朗",
  },
  {
    id: "232",
    text: "以色列",
  },
  {
    id: "233",
    text: "意大利",
  },
  {
    id: "234",
    text: "印度",
  },
  {
    id: "235",
    text: "印度尼西亚",
  },
  {
    id: "236",
    text: "英国",
  },
  {
    id: "237",
    text: "英属维尔京群岛",
  },
  {
    id: "238",
    text: "英属印度洋领地",
  },
  {
    id: "239",
    text: "约旦",
  },
  {
    id: "240",
    text: "越南",
  },
  {
    id: "241",
    text: "赞比亚",
  },
  {
    id: "242",
    text: "乍得",
  },
  {
    id: "243",
    text: "直布罗陀",
  },
  {
    id: "244",
    text: "智利",
  },
  {
    id: "245",
    text: "中非",
  },
];
const gangao = [
  {
    id: "4",
    text: "中国香港",
  },
  {
    id: "2",
    text: "中国澳门",
  },
]
const taiwan = [
  {
    id: "3",
    text: "中国台湾",
  },]
let employeeDate = {
  child1: {
    employeeList: [
      {
        // 最左边
        list: [
          {
            text: "证件类型",
            field: "idType",
            type: "select",
            value: "1",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.idTypeData.list,
            require: true
          },
          {
            text: "证件号码",
            field: "idNumber",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入真实证件号",
            require: true
          },

          {
            text: "户口性质",
            field: "registered",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.registeredData
              .list,
            require: true
          },
          {
            text: "户籍所在地",
            field: "province",
            city: "city",
            area: "area",
            type: "city",
            value1: "",
            value2: "",
            value3: "",
            desc1: "",
            desc2: "",
            desc3: "",
            desc: "",
            tips: "请选择",
            CityData: [],
            require: true
          },
          {
            text: "详细地址",
            field: "hujiDetail",
            type: "textarea",
            value: "",
            desc: "",
            tips: "请输入",
            require: true
          },

          {
            text: "学历",
            field: "education",
            value: "",
            desc: "",
            tips: "最高学历",
            type: "select",
            arrayList: employeeModel.educationData
              .list,
            require: true
          },
          {
            text: "学校",
            field: "school",
            type: "textarea",
            value: "",
            desc: "",
            tips: "请输入最高学历毕业院校",
            require: true
          },
        ],
      },
      {
        // 中间
        list: [
          {
            text: "合同类型",
            field: "contractType",
            value: "3",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTypeData
              .list,
            require: true
          },
          {
            text: "员工身份",
            field: "identity",
            type: "select",
            value: "1",
            desc: "",
            // desc: "(任职受雇从业类型)",
            tips: "请选择",
            arrayList: employeeModel.identityData.list,
            require: true
          },

          {
            text: "合同期限",
            field: "contractTerm",
            value: "",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTermData
              .list,
            require: true
          },

          {
            text: "入职日期",
            field: "jobTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "核算个税需要，请认真选择",
            require: true
          },


          {
            text: "试用期",
            field: "onTrialTermBegin",
            type: "dates",
            value: "",
            onTrialTermBegin: "",
            onTrialTermEnd: "",
            desc: "",
            tips: "请输入",
            arrayList: "",
            require: true
          },

        ],
      },

    ],
    employeeMoreList: [
      {
        // 最左边
        list: [
          {
            text: "一寸照片",
            field: "headOne",
            type: "image",
            value: "",
            image: "",
            desc: "",
            tips: "选填",
          },
          {
            text: "身份证照片",
            field: "idcardImgFront",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
            back: "",
            positive: "",
          },
          {
            text: "学位证照片",
            field: "degreeImg",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
            back: "",
            positive: "",
          },
          {
            text: "入职年度就业情形",
            field: "ruzhiqingxing",
            type: "select",
            value: "",
            desc: "",
            tips: "",
            arrayList: employeeModel.ruzhiqingxingData
              .list,
          },
          {
            text: "备注",
            field: "beizhu",
            type: "textarea",
            value: "",
            desc: "",
            tips: "",
          },

        ],
      },
      {
        // 最右边
        list: [
          {
            text: "是否残疾",
            field: "isDisability",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "残疾证号",
            field: "disabilityNum",
            type: "input",
            value: "",
            show: false,
            desc: "",
            tips: "",
          },
          {
            text: "是否烈属",
            field: "isMartyrFamily",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "烈属证号",
            field: "martyrFamilyNum",
            type: "input",
            show: false,
            value: "",
            desc: "",
            tips: "",
          },
          {
            text: "是否孤老",
            field: "isLonelyOld",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "是否扣除减除费用",
            field: "isDeductionCost",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "电子邮箱",
            field: "email",
            value: "",
            desc: "",
            tips: "",
            type: "textarea",
          },

        ],
      },
    ],
  },

  child2: {
    employeeList: [
      {
        // 最左边
        list: [
          {
            text: "证件类型",
            field: "idType",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.idTypeData.list,
            require: true
          },
          {
            text: "证件号码",
            field: "idNumber",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入真实证件号",
            require: true
          },

          {
            text: "国籍(地区)",
            field: "nationality",
            type: "select",
            value: "",
            disabled: true,
            desc: "",
            flag: true,
            tips: "请选择",
            arrayList: countryData,
            require: true
          },
          {
            text: "出生国家(地区)",
            field: "bornNation",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: countryData,
            require: true
          },

          {
            text: "性别",
            field: "gender",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.sex
              .list,
            require: true
          },
          {
            text: "出生日期",
            field: "birth",
            type: "date", // 后期改date
            value: "",
            tips: "请选择",
            require: true
          },
          {
            text: "涉税事由",
            field: "sssy",
            type: "selects", // 后期改date
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.sssyData.list,
            require: true
          },

          {
            text: "首次入境时间",
            field: "firstEntryTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "请选择",
            require: true
          },
          {
            text: "预计离境时间",
            field: "estimateLeaveTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "请选择",
            require: true
          },

        ],
      },
      {
        // 中间
        list: [
          {
            text: "合同类型",
            field: "contractType",
            value: "3",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTypeData
              .list,
            require: true
          },
          {
            text: "员工身份",
            field: "identity",
            type: "select",
            value: "1",
            desc: "",
            tips: "请输入",
            arrayList: employeeModel.identityData.list,
            require: true
          },


          {
            text: "合同期限",
            field: "contractTerm",
            value: "",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTermData
              .list,
            require: true
          },
          {
            text: "入职日期",
            field: "jobTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "核算个税需要，请认真选择",
            require: true
          },
          {
            text: "试用期",
            field: "onTrialTermBegin",
            type: "dates",
            value: "",
            onTrialTermBegin: "",
            onTrialTermEnd: "",
            desc: "",
            tips: "请输入",
            arrayList: "",
            require: true
          },

          // {
          //   text: "补贴补助",
          //   field: "butieSalary",
          //   type: "subsidy",
          //   value: "",
          //   desc: "",
          //   tips: "请选择 (选填)",
          //   subsidyList: [],
          //   arrayList: "",
          // },


          {
            text: "学历",
            field: "education",
            value: "",
            desc: "",
            tips: "最高学历",
            type: "select",
            arrayList: employeeModel.educationData
              .list,
            require: true
          },
          {
            text: "学校",
            field: "school",
            type: "textarea",
            value: "",
            desc: "",
            tips: "请输入最高学历毕业院校",
            require: true
          },

        ],
      },

    ],
    employeeMoreList: [
      {
        // 最左边
        list: [
          {
            text: "一寸照片",
            field: "headOne",
            type: "image",
            value: "",
            image: "",
            desc: "",
            tips: "选填",
          },
          {
            text: "身份证照片",
            field: "idcardImgFront",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
            back: "",
            positive: "",
          },
          {
            text: "学位证照片",
            field: "degreeImg",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
            back: "",
            positive: "",
          },
          {
            text: "中文名",
            field: "chinaName",
            value: "",
            desc: "",
            tips: "",
            type: "input",
          },
          {
            text: "入职年度就业情形",
            field: "ruzhiqingxing",
            type: "select",
            value: "",
            desc: "",
            tips: "",
            arrayList: employeeModel.ruzhiqingxingData
              .list,
          },

          {
            text: "备注",
            field: "beizhu",
            type: "textarea",
            value: "",
            desc: "",
            tips: "",
          },

        ],
      },
      {
        // 最右边
        list: [
          {
            text: "是否残疾",
            field: "isDisability",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "残疾证号",
            field: "disabilityNum",
            type: "input",
            value: "",
            show: false,
            desc: "",
            tips: "",
          },
          {
            text: "是否烈属",
            field: "isMartyrFamily",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "烈属证号",
            field: "martyrFamilyNum",
            type: "input",
            show: false,
            value: "",
            desc: "",
            tips: "",
          },
          {
            text: "是否孤老",
            field: "isLonelyOld",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "是否扣除减除费用",
            field: "isDeductionCost",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "电子邮箱",
            field: "email",
            value: "",
            desc: "",
            tips: "",
            type: "textarea",
          },

        ],
      },
    ],
  },
  child3: {
    employeeList: [
      {
        // 最左边
        list: [
          {
            text: "证件类型",
            field: "idType",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.idTypeData.list,
            require: true
          },
          {
            text: "证件号码",
            field: "idNumber",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入真实证件号",
            require: true
          },

          {
            text: "国籍(地区)",
            field: "nationality",
            type: "select",
            value: "",
            disabled: true,
            desc: "",
            flag: true,
            tips: "请选择",
            arrayList: countryData,
            require: true
          },
          {
            text: "出生国家(地区)",
            field: "bornNation",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: countryData,
            require: true
          },

          {
            text: "性别",
            field: "gender",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.sex
              .list,
            require: true
          },
          {
            text: "出生日期",
            field: "birth",
            type: "date", // 后期改date
            value: "",
            tips: "请选择",
            require: true
          },
          {
            text: "涉税事由",
            field: "sssy",
            type: "selects", // 后期改date
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.sssyData.list,
            require: true
          },

          {
            text: "首次入境时间",
            field: "firstEntryTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "请选择",
            require: true
          },
          {
            text: "预计离境时间",
            field: "estimateLeaveTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "请选择",
            require: true
          },

        ],
      },
      {
        // 中间
        list: [
          {
            text: "合同类型",
            field: "contractType",
            value: "3",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTypeData
              .list,
            require: true
          },
          {
            text: "员工身份",
            field: "identity",
            type: "select",
            value: "1",
            desc: "",
            tips: "请输入",
            arrayList: employeeModel.identityData.list,
            require: true
          },


          {
            text: "合同期限",
            field: "contractTerm",
            value: "",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTermData
              .list,
            require: true
          },
          {
            text: "入职日期",
            field: "jobTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "核算个税需要，请认真选择",
            require: true
          },
          {
            text: "试用期",
            field: "onTrialTermBegin",
            type: "dates",
            value: "",
            onTrialTermBegin: "",
            onTrialTermEnd: "",
            desc: "",
            tips: "请输入",
            arrayList: "",
            require: true
          },

          // {
          //   text: "补贴补助",
          //   field: "butieSalary",
          //   type: "subsidy",
          //   value: "",
          //   desc: "",
          //   tips: "请选择 (选填)",
          //   subsidyList: [],
          //   arrayList: "",
          // },


          {
            text: "学历",
            field: "education",
            value: "",
            desc: "",
            tips: "最高学历",
            type: "select",
            arrayList: employeeModel.educationData
              .list,
            require: true
          },
          {
            text: "学校",
            field: "school",
            type: "textarea",
            value: "",
            desc: "",
            tips: "请输入最高学历毕业院校",
            require: true
          },

        ],
      },

    ],
    employeeMoreList: [
      {
        // 最左边
        list: [
          {
            text: "一寸照片",
            field: "headOne",
            type: "image",
            value: "",
            image: "",
            desc: "",
            tips: "选填",
          },
          {
            text: "身份证照片",
            field: "idcardImgFront",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
            back: "",
            positive: "",
          },
          {
            text: "学位证照片",
            field: "degreeImg",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
            back: "",
            positive: "",
          },

          {
            text: "中文名",
            field: "chinaName",
            value: "",
            desc: "",
            tips: "",
            type: "input",
          },

          {
            text: "其他证件类型",
            field: "otherCardType",
            type: "select",
            value: "",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.certifycateData
              .list,
          },
          {
            text: "其他证件号码",
            field: "otherCardNum",
            type: "input",
            value: "",
            desc: "",
            tips: "",
          },
          {
            text: "入职年度就业情形",
            field: "ruzhiqingxing",
            type: "select",
            value: "",
            desc: "",
            tips: "",
            arrayList: employeeModel.ruzhiqingxingData
              .list,
          },
          {
            text: "备注",
            field: "beizhu",
            type: "textarea",
            value: "",
            desc: "",
            tips: "",
          },

        ],
      },
      {
        // 最右边
        list: [
          {
            text: "是否残疾",
            field: "isDisability",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "残疾证号",
            field: "disabilityNum",
            type: "input",
            value: "",
            show: false,
            desc: "",
            tips: "",
          },
          {
            text: "是否烈属",
            field: "isMartyrFamily",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "烈属证号",
            field: "martyrFamilyNum",
            type: "input",
            show: false,
            value: "",
            desc: "",
            tips: "",
          },
          {
            text: "是否孤老",
            field: "isLonelyOld",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "是否扣除减除费用",
            field: "isDeductionCost",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "电子邮箱",
            field: "email",
            value: "",
            desc: "",
            tips: "",
            type: "textarea",
          },

        ],
      },
    ],
  },
}

let employeeDateOld = {
  zhuyao: [
    {
      text: "姓名",
      field: "name",
      type: "input",
      value: "",
      desc: "",
      tips: "员工真实姓名",
      require: true
    },
    {
      text: "手机号",
      field: "mobile",
      value: "",
      desc: "",
      tips: "常用手机号",
      type: "input",
      require: true
    },
    {
      text: "岗位名称",
      field: "postName",
      type: "input",
      value: "",
      desc: "",
      tips: "请输入",
      require: true
    },
    {
      text: "所属部门",
      field: "departmentId",
      type: "select",
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: [],
      require: true
    },

    {
      text: "现居住地",
      field: "contactProvince",
      city: "contactCity",
      area: "contactArea",
      value1: "",
      value2: "",
      value3: "",
      desc1: "",
      desc2: "",
      desc3: "",
      desc: "",
      tips: "请选择",
      type: "city",
      CityData: [],
      require: true
    },
    {
      text: "详细地址",
      field: "contactDetail",
      type: "textarea",
      value: "",
      tips: "请输入",
      require: true
    },
  ],
  gangwei: [
    {
      text: "合同类型",
      field: "contractType",
      value: "3",
      desc: "",
      tips: "请选择",
      type: "select",
      arrayList: employeeModel.contractTypeData
        .list,
      require: true
    },
    {
      text: "员工身份",
      field: "identity",
      type: "select",
      value: "1",
      desc: "",
      // desc: "(任职受雇从业类型)",
      tips: "请选择",
      arrayList: employeeModel.identityData.list,
      require: true
    },


    {
      text: "合同期限",
      field: "contractTerm",
      value: "",
      desc: "",
      tips: "请选择",
      type: "select",
      arrayList: employeeModel.contractTermData
        .list,
      require: true
    },
    {
      text: "入职日期",
      field: "jobTime",
      type: "date", // 后期改date
      value: "",
      desc: "",
      tips: "核算个税需要，请认真选择",
      require: true
    },
    {
      text: "试用期",
      field: "onTrialTermBegin",
      type: "dates",
      value: "",
      onTrialTermBegin: "",
      onTrialTermEnd: "",
      desc: "",
      tips: "请输入",
      arrayList: "",
      require: true
    },

  ],
  jiben: [
    {
      text: "证件类型",
      field: "idType",
      type: "select",
      value: "1",
      desc: "",
      tips: "请选择",
      arrayList: employeeModel.idTypeData.list,
      require: true
    },
    {
      text: "证件号码",
      field: "idNumber",
      type: "input",
      value: "",
      desc: "",
      tips: "请输入真实证件号",
      require: true
    },
    {
      text: "户口性质",
      field: "registered",
      type: "select",
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: employeeModel.registeredData
        .list,
      require: true
    },
    {
      text: "户籍所在地",
      field: "province",
      city: "city",
      area: "area",
      type: "city",
      value1: "",
      value2: "",
      value3: "",
      desc1: "",
      desc2: "",
      desc3: "",
      desc: "",
      tips: "请选择",
      CityData: [],
      require: true
    },
    {
      text: "详细地址",
      field: "hujiDetail",
      type: "textarea",
      value: "",
      desc: "",
      tips: "选填",
    },
    {
      text: "学历",
      field: "education",
      value: "",
      desc: "",
      tips: "最高学历",
      type: "select",
      arrayList: employeeModel.educationData
        .list,
      require: true
    },
    {
      text: "学校",
      field: "school",
      type: "textarea",
      value: "",
      desc: "",
      tips: "请输入最高学历毕业院校",
      require: true
    },
    {
      text: "紧急联系人",
      field: "jinjiLianxiren",
      type: "textarea",
      value: "",
      desc: "",
      tips: "请输入",
    },
    {
      text: "紧急联系人电话",
      field: "jinjiLianxidianhua",
      type: "textarea",
      value: "",
      desc: "",
      tips: "请输入",
    },
  ],
  jiben2: [
    {
      text: "证件类型",
      field: "idType",
      type: "select",
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: employeeModel.idTypeData.list,
      require: true
    },
    {
      text: "证件号码",
      field: "idNumber",
      type: "input",
      value: "",
      desc: "",
      tips: "请输入真实证件号",
      require: true
    },
    {
      text: "国籍(地区)",
      field: "nationality",
      type: "select",
      value: "",
      disabled: true,
      desc: "",
      flag: true,
      tips: "请选择",
      arrayList: countryData,
      require: true
    },
    {
      text: "出生国家(地区)",
      field: "bornNation",
      type: "select",
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: countryData,
      require: true
    },
    {
      text: "出生日期",
      field: "birth",
      type: "date", // 后期改date
      value: "",
      tips: "请选择",
      require: true
    },

    {
      text: "性别",
      field: "gender",
      type: "select",
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: employeeModel.sex
        .list,
      require: true
    },
    {
      text: "学历",
      field: "education",
      value: "",
      desc: "",
      tips: "最高学历",
      type: "select",
      arrayList: employeeModel.educationData
        .list,
      require: true
    },
    {
      text: "学校",
      field: "school",
      type: "textarea",
      value: "",
      desc: "",
      tips: "请输入最高学历毕业院校",
      require: true
    },
    {
      text: "紧急联系人",
      field: "jinjiLianxiren",
      type: "input",
      value: "",
      desc: "",
      tips: "请输入",
    },
    {
      text: "紧急联系人电话",
      field: "jinjiLianxidianhua",
      type: "input",
      value: "",
      desc: "",
      tips: "请输入",
    },


  ],
  shewai: [
    {
      text: "涉税事由",
      field: "sssy",
      type: "selects", // 后期改date
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: employeeModel.sssyData.list,
      require: true
    },

    {
      text: "首次入境时间",
      field: "firstEntryTime",
      type: "date", // 后期改date
      value: "",
      desc: "",
      tips: "请选择",
      require: true
    },
    {
      text: "预计离境时间",
      field: "estimateLeaveTime",
      type: "date", // 后期改date
      value: "",
      desc: "",
      tips: "请选择",
      require: true
    },
  ],
  gongzika: [
    {
      text: "银行卡号",
      field: "bankCardNum",
      type: "input",
      value: "",
      desc: "",
      tips: "选填",
    },
    {
      text: "所属银行",
      field: "bankName",
      type: "textarea",
      value: "",
      desc: "",
      tips: "选填",
    },
    {
      text: "开户行所在地",
      field: "bankProvinceId",
      type: "select",
      value: "",
      name: "",
      desc: "",
      tips: "选填",
      arrayList: employeeModel.cityMap,
    },
  ],
  qita1: [
    {
      text: "一寸照片",
      field: "headOne",
      type: "image",
      value: "",
      image: "",
      desc: "",
      tips: "选填",
    },
    {
      text: "身份证照片",
      field: "idcardImgFront",
      type: "images",
      value: "",
      images: [],
      desc: "",
      tips: "选填",
    },
    {
      text: "学位证照片",
      field: "degreeImg",
      type: "images",
      value: "",
      images: [],
      desc: "",
      tips: "选填",
    },
    {
      text: "是否残疾",
      field: "isDisability",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "残疾证号",
      field: "disabilityNum",
      type: "input",
      value: "",
      show: false,
      desc: "",
      tips: "",
    },
    {
      text: "是否烈属",
      field: "isMartyrFamily",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "烈属证号",
      field: "martyrFamilyNum",
      type: "input",
      value: "",
      show: false,
      desc: "",
      tips: "",
    },
    {
      text: "是否孤老",
      field: "isLonelyOld",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "是否扣除减除费用",
      field: "isDeductionCost",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "入职年度就业情形",
      field: "ruzhiqingxing",
      type: "select",
      value: "",
      desc: "",
      tips: "",
      arrayList: employeeModel.ruzhiqingxingData
        .list,
    },
    {
      text: "备注",
      field: "beizhu",
      type: "textarea",
      value: "",
      desc: "",
      tips: "",
    },
  ],
  qita2: [
    {
      text: "一寸照片",
      field: "headOne",
      type: "image",
      value: "",
      image: "",
      desc: "",
      tips: "选填",
    },
    {
      text: "身份证照片",
      field: "idcardImgFront",
      type: "images",
      value: "",
      images: [],
      desc: "",
      tips: "选填",
    },
    {
      text: "学位证照片",
      field: "degreeImg",
      type: "images",
      value: "",
      images: [],
      desc: "",
      tips: "选填",
    },
    {
      text: "中文名",
      field: "chinaName",
      value: "",
      desc: "",
      tips: "",
      type: "input",
    },
    {
      text: "是否残疾",
      field: "isDisability",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "残疾证号",
      field: "disabilityNum",
      type: "input",
      value: "",
      show: false,
      desc: "",
      tips: "",
    },
    {
      text: "是否烈属",
      field: "isMartyrFamily",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "烈属证号",
      field: "martyrFamilyNum",
      type: "input",
      value: "",
      show: false,
      desc: "",
      tips: "",
    },
    {
      text: "是否孤老",
      field: "isLonelyOld",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "是否扣除减除费用",
      field: "isDeductionCost",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "入职年度就业情形",
      field: "ruzhiqingxing",
      type: "select",
      value: "",
      desc: "",
      tips: "",
      arrayList: employeeModel.ruzhiqingxingData
        .list,
    },
    {
      text: "备注",
      field: "beizhu",
      type: "textarea",
      value: "",
      desc: "",
      tips: "",
    },
  ],
  qita3: [
    {
      text: "一寸照片",
      field: "headOne",
      type: "image",
      value: "",
      image: "",
      desc: "",
      tips: "选填",
    },
    {
      text: "身份证照片",
      field: "idcardImgFront",
      type: "images",
      value: "",
      images: [],
      desc: "",
      tips: "选填",
    },
    {
      text: "学位证照片",
      field: "degreeImg",
      type: "images",
      value: "",
      images: [],
      desc: "",
      tips: "选填",
    },
    {
      text: "中文名",
      field: "chinaName",
      value: "",
      desc: "",
      tips: "",
      type: "input",
    },

    {
      text: "其他证件类型",
      field: "otherCardType",
      type: "select",
      value: "",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.certifycateData
        .list,
    },
    {
      text: "其他证件号码",
      field: "otherCardNum",
      type: "input",
      value: "",
      desc: "",
      tips: "",
    },
    {
      text: "是否残疾",
      field: "isDisability",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "残疾证号",
      field: "disabilityNum",
      type: "input",
      value: "",
      show: false,
      desc: "",
      tips: "",
    },
    {
      text: "是否烈属",
      field: "isMartyrFamily",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "烈属证号",
      field: "martyrFamilyNum",
      type: "input",
      value: "",
      show: false,
      desc: "",
      tips: "",
    },
    {
      text: "是否孤老",
      field: "isLonelyOld",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "是否扣除减除费用",
      field: "isDeductionCost",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "入职年度就业情形",
      field: "ruzhiqingxing",
      type: "select",
      value: "",
      desc: "",
      tips: "",
      arrayList: employeeModel.ruzhiqingxingData
        .list,
    },
    {
      text: "备注",
      field: "beizhu",
      type: "textarea",
      value: "",
      desc: "",
      tips: "",
    },
  ],
  child1: {
    employeeList: [
      {
        // 最左边
        list: [
          {
            text: "手机号",
            field: "mobile",
            value: "",
            desc: "",
            tips: "常用手机号",
            type: "input",
            require: true
          },
          {
            text: "证件类型",
            field: "idType",
            type: "select",
            value: "1",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.idTypeData.list,
            require: true
          },
          {
            text: "证件号码",
            field: "idNumber",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入真实证件号",
            require: true
          },
          {
            text: "姓名",
            field: "name",
            type: "input",
            value: "",
            desc: "",
            tips: "员工真实姓名",
            require: true
          },

          {
            text: "户口",
            field: "registered",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.registeredData
              .list,
            require: true
          },
          {
            text: "户籍",
            field: "province",
            city: "city",
            area: "area",
            type: "city",
            value1: "",
            value2: "",
            value3: "",
            desc1: "",
            desc2: "",
            desc3: "",
            desc: "",
            tips: "请选择",
            CityData: [],
            require: true
          },
          {
            text: "户籍(详细地址)",
            field: "hujiDetail",
            type: "textarea",
            value: "",
            desc: "",
            tips: "选填",
          },

        ],
      },
      {
        // 中间
        list: [

          {
            text: "合同类型",
            field: "contractType",
            value: "3",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTypeData
              .list,
            require: true
          },

          {
            text: "入职日期",
            field: "jobTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "核算个税需要，请认真选择",
            require: true
          },

          {
            text: "合同期限",
            field: "contractTerm",
            value: "",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTermData
              .list,
            require: true
          },
          {
            text: "试用期",
            field: "onTrialTermBegin",
            type: "dates",
            value: "",
            onTrialTermBegin: "",
            onTrialTermEnd: "",
            desc: "",
            tips: "请输入",
            arrayList: "",
            require: true
          },
          {
            text: "试用期工资",
            field: "onTrialTermSalaryScale",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入",
            arrayList: employeeModel.payrollScale.list,
            require: true
          },
          {
            text: "岗位工资(元)",
            field: "postSalary",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入",
            span: "元",
            require: true
          },
          {
            text: "岗位名称",
            field: "postName",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入",
            require: true
          },

        ],
      },
      {
        // 最右边
        list: [
          {
            text: "所属部门",
            field: "departmentId",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: [],
            require: true
          },
          {
            text: "补贴补助",
            field: "butieSalary",
            type: "subsidy",
            value: "",
            desc: "",
            tips: "请选择 (选填)",
            subsidyList: [],
            arrayList: "",
          },
          {
            text: "员工身份",
            field: "identity",
            type: "select",
            value: "1",
            desc: "",
            // desc: "(任职受雇从业类型)",
            tips: "请选择",
            arrayList: employeeModel.identityData.list,
            require: true
          },
          {
            text: "学历",
            field: "education",
            value: "",
            desc: "",
            tips: "最高学历",
            type: "select",
            arrayList: employeeModel.educationData
              .list,
            require: true
          },
          {
            text: "学校",
            field: "school",
            type: "textarea",
            value: "",
            desc: "",
            tips: "请输入最高学历毕业院校",
            require: true
          },

          {
            text: "工资卡开户行所在地",
            field: "bankProvinceId",
            type: "select",
            value: "",
            name: "",
            desc: "",
            tips: "开户行省份",
            arrayList: employeeModel.cityMap,
          },
          {
            text: "工资卡全称",
            field: "bankName",
            type: "textarea",
            value: "",
            desc: "",
            tips: "开户行全称",
          },
          {
            text: "工资银行卡号",
            field: "bankCardNum",
            type: "input",
            value: "",
            desc: "",
            tips: "银行卡号",
          },
          {
            text: "员工状态",
            field: "status",
            type: "status",
            value: "",
            dimissionTime: "",
            desc: "",
            tips: "修改员工状态",
          },
        ],
      },
    ],
    employeeMoreList: [
      {
        // 最左边
        list: [
          {
            text: "一寸照片",
            field: "headOne",
            type: "image",
            value: "",
            image: "",
            desc: "",
            tips: "选填",
          },
          {
            text: "身份证照片",
            field: "idcardImgFront",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
            back: "",
            positive: "",
          },
          {
            text: "学位证照片",
            field: "degreeImg",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
            back: "",
            positive: "",
          },


        ],
      },
      {
        // 中间
        list: [
          {
            text: "现居住地",
            field: "contactProvince",
            city: "contactCity",
            area: "contactArea",
            value1: "",
            value2: "",
            value3: "",
            desc1: "",
            desc2: "",
            desc3: "",
            desc: "",
            tips: "请选择",
            type: "city",
            CityData: [],
            require: true
          },
          {
            text: "详细地址",
            field: "contactDetail",
            type: "textarea",
            value: "",
            tips: "请输入",
            require: true
          },
          {
            text: "户口地址",
            field: "contactProvince",
            city: "contactCity",
            area: "contactArea",
            type: "city",
            value1: "",
            value2: "",
            value3: "",
            desc1: "",
            desc2: "",
            desc3: "",
            desc: "",
            tips: "",
            CityData: [],
          },
          {
            text: "户口详细地址",
            field: "contactDetail",
            type: "textarea",
            value: "",
            tips: "",
          },

        ],
      },
      {
        // 最右边
        list: [
          {
            text: "是否残疾",
            field: "isDisability",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "残疾证号",
            field: "disabilityNum",
            type: "input",
            value: "",
            show: false,
            desc: "",
            tips: "",
          },
          {
            text: "是否烈属",
            field: "isMartyrFamily",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "烈属证号",
            field: "martyrFamilyNum",
            type: "input",
            show: false,
            value: "",
            desc: "",
            tips: "",
          },
          {
            text: "是否孤老",
            field: "isLonelyOld",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "是否扣除减除费用",
            field: "isDeductionCost",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "入职年度就业情形",
            field: "ruzhiqingxing",
            type: "select",
            value: "",
            desc: "",
            tips: "",
            arrayList: employeeModel.ruzhiqingxingData
              .list,
          },
        ],
      },
    ],
  },

  child2: {
    employeeList: [
      {
        // 最左边
        list: [
          {
            text: "手机号",
            field: "mobile",
            value: "",
            desc: "",
            tips: "常用手机号",
            type: "input",
            require: true
          },
          {
            text: "证件类型",
            field: "idType",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.idTypeData.list,
            require: true
          },
          {
            text: "证件号码",
            field: "idNumber",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入真实证件号",
            require: true
          },
          {
            text: "姓名",
            field: "name",
            type: "input",
            value: "",
            desc: "",
            tips: "员工真实姓名",
            require: true
          },

          {
            text: "国籍(地区)",
            field: "nationality",
            type: "select",
            value: "",
            disabled: true,
            desc: "",
            flag: true,
            tips: "请选择",
            arrayList: countryData,
            require: true
          },
          {
            text: "出生国家(地区)",
            field: "bornNation",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: countryData,
            require: true
          },

          {
            text: "性别",
            field: "gender",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.sex
              .list,
            require: true
          },
          {
            text: "出生日期",
            field: "birth",
            type: "date", // 后期改date
            value: "",
            tips: "请选择",
            require: true
          },
          {
            text: "涉税事由",
            field: "sssy",
            type: "selects", // 后期改date
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: employeeModel.sssyData.list,
            require: true
          },

          {
            text: "首次入境时间",
            field: "firstEntryTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "请选择",
            require: true
          },
          {
            text: "预计离境时间",
            field: "estimateLeaveTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "请选择",
            require: true
          },
        ],
      },
      {
        // 中间
        list: [


          {
            text: "合同类型",
            field: "contractType",
            value: "3",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTypeData
              .list,
            require: true
          },
          {
            text: "入职日期",
            field: "jobTime",
            type: "date", // 后期改date
            value: "",
            desc: "",
            tips: "核算个税需要，请认真选择",
            require: true
          },
          {
            text: "合同期限",
            field: "contractTerm",
            value: "",
            desc: "",
            tips: "请选择",
            type: "select",
            arrayList: employeeModel.contractTermData
              .list,
            require: true
          },
          {
            text: "试用期",
            field: "onTrialTermBegin",
            type: "dates",
            value: "",
            onTrialTermBegin: "",
            onTrialTermEnd: "",
            desc: "",
            tips: "请输入",
            arrayList: "",
            require: true
          },
          {
            text: "试用期工资",
            field: "onTrialTermSalaryScale",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入",
            arrayList: employeeModel.payrollScale.list,
            require: true
          },
          {
            text: "岗位工资(元)",
            field: "postSalary",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入",
            span: "元",
            require: true
          },
          {
            text: "岗位名称",
            field: "postName",
            type: "input",
            value: "",
            desc: "",
            tips: "请输入",
            require: true
          },
        ],
      },
      {
        // 最右边
        list: [
          {
            text: "所属部门",
            field: "departmentId",
            type: "select",
            value: "",
            desc: "",
            tips: "请选择",
            arrayList: [],
            require: true
          },

          {
            text: "补贴补助",
            field: "butieSalary",
            type: "subsidy",
            value: "",
            desc: "",
            tips: "请选择 (选填)",
            subsidyList: [],
            arrayList: "",
          },
          {
            text: "员工身份",
            field: "identity",
            type: "select",
            value: "1",
            desc: "",
            tips: "请输入",
            arrayList: employeeModel.identityData.list,
            require: true
          },
          {
            text: "学历",
            field: "education",
            value: "",
            desc: "",
            tips: "最高学历",
            type: "select",
            arrayList: employeeModel.educationData
              .list,
            require: true
          },
          {
            text: "学校",
            field: "school",
            type: "textarea",
            value: "",
            desc: "",
            tips: "请输入最高学历毕业院校",
            require: true
          },

          {
            text: "工资卡开户行所在地",
            field: "bankProvinceId",
            type: "select",
            value: "",
            name: "",
            desc: "",
            tips: "开户行省份",
            arrayList: employeeModel.cityMap,
          },
          {
            text: "工资卡全称",
            field: "bankName",
            type: "textarea",
            value: "",
            desc: "",
            tips: "开户行全称",
          },
          {
            text: "工资银行卡号",
            field: "bankCardNum",
            type: "input",
            value: "",
            desc: "",
            tips: "银行卡号",
          },
          {
            text: "员工状态",
            field: "status",
            type: "status",
            value: "",
            dimissionTime: "",
            desc: "",
            tips: "修改员工状态",
          },
        ],
      },
    ],
    employeeMoreList: [
      {
        // 最左边
        list: [
          {
            text: "一寸照片",
            field: "headOne",
            type: "image",
            value: "",
            image: "",
            desc: "",
            tips: "选填",
          },
          {
            text: "身份证照片",
            field: "idcardImgFront",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
          },
          {
            text: "学位证照片",
            field: "degreeImg",
            type: "images",
            value: "",
            images: [],
            desc: "",
            tips: "选填",
          },
          {
            text: "中文名",
            field: "chinaName",
            value: "",
            desc: "",
            tips: "",
            type: "input",
          },

          {
            text: "其他证件类型",
            field: "otherCardType",
            type: "select",
            value: "",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.certifycateData
              .list,
          },
          {
            text: "其他证件号码",
            field: "otherCardNum",
            type: "input",
            value: "",
            desc: "",
            tips: "",
          },
        ],
      },
      {
        // 中间
        list: [
          {
            text: "现居住地",
            field: "contactProvince",
            city: "contactCity",
            area: "contactArea",
            value1: "",
            value2: "",
            value3: "",
            desc1: "",
            desc2: "",
            desc3: "",
            desc: "",
            tips: "请选择",
            type: "city",
            CityData: [],
            require: true
          },
          {
            text: "详细地址",
            field: "contactDetail",
            type: "textarea",
            value: "",
            tips: "请输入",
            require: true
          },
          {
            text: "户口地址",
            field: "contactProvince",
            city: "contactCity",
            area: "contactArea",
            type: "city",
            value1: "",
            value2: "",
            value3: "",
            desc1: "",
            desc2: "",
            desc3: "",
            desc: "",
            tips: "",
            CityData: [],
          },
          {
            text: "户口详细地址",
            field: "contactDetail",
            type: "textarea",
            value: "",
            tips: "",
          },
        ],
      },
      {
        // 最右边
        list: [

          {
            text: "是否残疾",
            field: "isDisability",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "残疾证号",
            field: "disabilityNum",
            type: "input",
            value: "",
            show: false,
            desc: "",
            tips: "",
          },
          {
            text: "是否烈属",
            field: "isMartyrFamily",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "烈属证号",
            field: "martyrFamilyNum",
            type: "input",
            value: "",
            show: false,
            desc: "",
            tips: "",
          },
          {
            text: "是否孤老",
            field: "isLonelyOld",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "是否扣除减除费用",
            field: "isDeductionCost",
            value: "0",
            desc: "",
            tips: "",
            type: "select",
            arrayList: employeeModel.boolData.list,
          },
          {
            text: "入职年度就业情形",
            field: "ruzhiqingxing",
            type: "select",
            value: "",
            desc: "",
            tips: "",
            arrayList: employeeModel.ruzhiqingxingData
              .list,
          },
        ],
      },
    ],
  },
}
const payment = [
  {
    id: "11",
    bankcardId: "11",
    bankName: "库存现金",
  },
  {
    id: "12",
    bankcardId: "12",
    bankName: "个人银行付款",
  },

]
let addAssets = {
  addMode: {
    name: "添加方式",
    list: [
      {
        id: "1",
        text: "新购",
      },
      {
        id: "2",
        text: "转入",
      },
    ]
  },
  assetTypeList: [
    { daLei: "1", life: "240", xiLei: "11", xiLeiName: "房屋建筑FW" },
    {
      daLei: "1",
      life: "120",
      xiLei: "12",
      xiLeiName: "机器机械JQ",
    },
    {
      daLei: "1",
      life: "60",
      xiLei: "13",
      xiLeiName: "家具设备JJ",
    },
    { daLei: "1", life: "48", xiLei: "14", xiLeiName: "运输设备YS" },
    {
      daLei: "1",
      life: "36",
      xiLei: "15",
      xiLeiName: "电子设备DZ",
    },
    {
      daLei: "2",
      xiLei: "99",
      xiLeiName: "无形资产WX",
    },
  ],
  subdivision: {
    name: "细类",
    list: [
      {
        id: "1",
        text: "专利技术",
      },
      {
        id: "2",
        text: "非专利技术",
      },
      {
        id: "3",
        text: "商标",
      },
      {
        id: "4",
        text: "著作权",
      },
      {
        id: "5",
        text: "商誉",
      },
      {
        id: "6",
        text: "自然资源使用权",
      },
      {
        id: "7",
        text: "土地使用权",
      },
      {
        id: "8",
        text: "探矿权",
      },
      {
        id: "9",
        text: "采矿权",
      },
      {
        id: "10",
        text: "其他无形资产",
      },
    ]
  },
  serviceLife: {
    name: "使用寿命",
    list: [
      {
        id: "1",
        text: "36期",
      },
      {
        id: "2",
        text: "48期",
      },
      {
        id: "3",
        text: "60期",
      },
      {
        id: "4",
        text: "120期",
      },
      {
        id: "5",
        text: "240期",
      },
    ]
  },
  depreciation: {
    name: "折旧方法",
    list: [
      {
        id: "1",
        text: "年限平均法",
      },
      {
        id: "2",
        text: "双倍余额递减法",
      },
      {
        id: "3",
        text: "年数总和法",
      },
      {
        id: "4",
        text: "工作量法",
      },
    ]
  },
  disposalMode: {
    name: "处置方式",
    list: [
      {
        id: "1",
        text: "变卖",
      },
      {
        id: "2",
        text: "报废",
      },
    ]
  },
  collectionMode: {
    name: "收款方式",
    list: [
      {
        id: "2",
        text: "企业库存现金收款",
      },
      {
        id: "3",
        text: "个人银行卡",
      },
    ]
  },
}

let gzList = {
  list1: [
    {
      text: "所属部门",
      field: "departmentId",
      type: "select",
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: [],
      require: true
    },
    {
      text: "岗位名称",
      field: "postName",
      type: "input",
      value: "",
      desc: "",
      tips: "请输入",
      require: true
    },


    {
      text: "现居住地",
      field: "contactProvince",
      city: "contactCity",
      area: "contactArea",
      value1: "",
      value2: "",
      value3: "",
      desc1: "",
      desc2: "",
      desc3: "",
      desc: "",
      tips: "请选择",
      type: "city",
      CityData: [],
      require: true
    },
    {
      text: "详细地址",
      field: "contactDetail",
      type: "textarea",
      value: "",
      tips: "请输入",
      require: true
    },
    {
      text: "证件类型",
      field: "idType",
      type: "select",
      value: "1",
      desc: "",
      tips: "请选择",
      arrayList: employeeModel.idTypeData.list,
      require: true
    },
    {
      text: "证件号码",
      field: "idNumber",
      type: "input",
      value: "",
      desc: "",
      tips: "请输入真实证件号",
      require: true
    },

  ],
  list7: [
    {
      text: "户口性质",
      field: "registered",
      type: "select",
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: employeeModel.registeredData
        .list,
      require: true
    },
    {
      text: "户籍所在地",
      field: "province",
      city: "city",
      area: "area",
      type: "city",
      value1: "",
      value2: "",
      value3: "",
      desc1: "",
      desc2: "",
      desc3: "",
      desc: "",
      tips: "请选择",
      CityData: [],
      require: true
    },
  ],
  list8: [
    {
      text: "中文名",
      field: "chinaName",
      value: "",
      desc: "",
      tips: "",
      type: "input",
    },
    {
      text: "其他证件类型",
      field: "otherCardType",
      type: "select",
      value: "",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.certifycateData
        .list,
    },
    {
      text: "其他证件号码",
      field: "otherCardNum",
      type: "input",
      value: "",
      desc: "",
      tips: "",
    },
  ],
  list5: [
    {
      text: "学历",
      field: "education",
      value: "",
      desc: "",
      tips: "最高学历",
      type: "select",
      arrayList: employeeModel.educationData
        .list,
      require: true
    },
    {
      text: "学校",
      field: "school",
      type: "textarea",
      value: "",
      desc: "",
      tips: "请输入",
      require: true
    },
    {
      text: "紧急联系人",
      field: "jinjiLianxiren",
      type: "input",
      value: "",
      desc: "",
      tips: "请输入",
    },
    {
      text: "紧急联系人电话",
      field: "jinjiLianxidianhua",
      type: "input",
      value: "",
      desc: "",
      tips: "请输入",
    },
    // {
    //   text: "电子邮箱",
    //   field: "email",
    //   type: "input",
    //   value: "",
    //   desc: "",
    //   tips: "请输入",
    // },
  ],
  list6: [
    {
      text: "国籍(地区)",
      field: "nationality",
      type: "select",
      value: "",
      disabled: true,
      desc: "",
      flag: true,
      tips: "请选择",
      arrayList: countryData,
      require: true
    },
    {
      text: "出生国家(地区)",
      field: "bornNation",
      type: "select",
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: countryData,
      require: true
    },
    {
      text: "出生日期",
      field: "birth",
      type: "date", // 后期改date
      value: "",
      tips: "请选择",
      require: true
    },

    {
      text: "性别",
      field: "gender",
      type: "select",
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: employeeModel.sex
        .list,
      require: true
    },
  ],
  list2: [

    {
      text: "员工身份",
      field: "identity",
      type: "select",
      value: "1",
      desc: "",
      // desc: "(任职受雇从业类型)",
      tips: "请选择",
      arrayList: employeeModel.identityData.list,
      require: true
    },
    {
      text: "合同类型",
      field: "contractType",
      value: "3",
      desc: "",
      tips: "请选择",
      type: "select",
      arrayList: employeeModel.contractTypeData
        .list,
      require: true
    },

    {
      text: "合同期限",
      field: "contractTerm",
      value: "",
      desc: "",
      tips: "请选择",
      type: "select",
      arrayList: employeeModel.contractTermData
        .list,
      require: true
    },
    {
      text: "入职日期",
      field: "jobTime",
      type: "date", // 后期改date
      value: "",
      desc: "",
      tips: "核算个税需要，请认真选择",
      require: true
    },
    {
      text: "试用期",
      field: "onTrialTermBegin",
      type: "dates",
      value: "",
      onTrialTermBegin: "",
      onTrialTermEnd: "",
      desc: "",
      tips: "请输入",
      arrayList: "",
      require: true
    },


  ],
  shewai: [
    {
      text: "涉税事由",
      field: "sssy",
      type: "selects", // 后期改date
      value: "",
      desc: "",
      tips: "请选择",
      arrayList: employeeModel.sssyData.list,
      require: true
    },

    {
      text: "首次入境时间",
      field: "firstEntryTime",
      type: "date", // 后期改date
      value: "",
      desc: "",
      tips: "请选择",
      require: true
    },
    {
      text: "预计离境时间",
      field: "estimateLeaveTime",
      type: "date", // 后期改date
      value: "",
      desc: "",
      tips: "请选择",
      require: true
    },
  ],
  list3: [
    {
      text: "一寸照片",
      field: "headOne",
      type: "image",
      value: "",
      image: "",
      desc: "",
      tips: "选填",
    },
    {
      text: "身份证照片",
      field: "idcardImgFront",
      type: "images",
      value: "",
      images: [],
      desc: "",
      tips: "选填",
    },
    {
      text: "学位证照片",
      field: "degreeImg",
      type: "images",
      value: "",
      images: [],
      desc: "",
      tips: "选填",
    },
  ],
  list4: [
    {
      text: "是否残疾",
      field: "isDisability",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "残疾证号",
      field: "disabilityNum",
      type: "input",
      value: "",
      show: false,
      desc: "",
      tips: "",
    },
    {
      text: "是否烈属",
      field: "isMartyrFamily",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "烈属证号",
      field: "martyrFamilyNum",
      type: "input",
      value: "",
      show: false,
      desc: "",
      tips: "",
    },
    {
      text: "是否孤老",
      field: "isLonelyOld",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "是否扣除减除费用",
      field: "isDeductionCost",
      value: "0",
      desc: "",
      tips: "",
      type: "select",
      arrayList: employeeModel.boolData.list,
    },
    {
      text: "入职年度就业情形",
      field: "ruzhiqingxing",
      type: "select",
      value: "",
      desc: "",
      tips: "",
      arrayList: employeeModel.ruzhiqingxingData
        .list,
    },
    {
      text: "备注",
      field: "beizhu",
      type: "textarea",
      value: "",
      desc: "",
      tips: "",
    },
  ],
}

export default {
  employeeDateOld, employeeModel, employeeDate,
  countryData, gangao, taiwan, payment, addAssets, gzList
}