
// 本地字典库
let addAssets = {

}
export default {
  addMode: {
    name: "添加方式",
    list: [
      {
        id: "1",
        text: "新购",
      },
      {
        id: "2",
        text: "转入",
      },
    ]
  },
  assetType: {
    name: "资产类型",
    list: [
      {
        id: "1",
        text: "无形资产WX",
      },
      {
        id: "2",
        text: "电子设备DZ",
      },
      {
        id: "3",
        text: "家具设备JJ",
      },
      {
        id: "4",
        text: "运输设备YS",
      },
      {
        id: "5",
        text: "房屋建筑FW",
      },
      {
        id: "6",
        text: "机器机械JQ",
      },
    ]
  },
  subdivision: {
    name: "细类",
    list: [
      {
        id: "1",
        text: "专利技术",
      },
      {
        id: "2",
        text: "非专利技术",
      },
      {
        id: "3",
        text: "商标",
      },
      {
        id: "4",
        text: "著作权",
      },
      {
        id: "5",
        text: "商誉",
      },
      {
        id: "6",
        text: "自然资源使用权",
      },
      {
        id: "7",
        text: "土地使用权",
      },
      {
        id: "8",
        text: "探矿权",
      },
      {
        id: "9",
        text: "采矿权",
      },
      {
        id: "10",
        text: "其他无形资产",
      },
    ]
  },
  serviceLife: {
    name: "使用寿命",
    list: [
      {
        id: "1",
        text: "36期",
      },
      {
        id: "2",
        text: "48期",
      },
      {
        id: "3",
        text: "60期",
      },
      {
        id: "4",
        text: "120期",
      },
      {
        id: "5",
        text: "240期",
      },
    ]
  },
  depreciation: {
    name: "折旧方法",
    list: [
      {
        id: "1",
        text: "年限平均法",
      },
      {
        id: "2",
        text: "双倍余额递减法",
      },
      {
        id: "3",
        text: "年数总和法",
      },
      {
        id: "4",
        text: "工作量法",
      },
    ]
  },
  disposalMode: {
    name: "处置方式",
    list: [
      {
        id: "1",
        text: "变卖",
      },
      {
        id: "2",
        text: "报废",
      },
    ]
  },
  collectionMode: {
    name: "收款方式",
    list: [
      {
        id: "1",
        text: "其他",
      },
      {
        id: "2",
        text: "库存现金",
      },
      {
        id: "3",
        text: "银行存款",
      },
    ]
  },
}