const digitsRE = /(\d{3})(?=\d)/g
/***
 * 格式化
 * @param value
 * @returns {string}
 */
export function selectText(value, item) {
  let newValue = ''
  if (value !== '') {
    item.arrayList.forEach(element => {
      if (value + '' === element.id + '') {
        newValue = element.text
        return false
      }
    });
  }
  return newValue
}


/**
 * [只能输入数字和两位小数]
 * 举例：<input type="text" onkeyup="num(this)" size="10"/>
 * @param obj
 * @returns
 * @data 20019-01-08
 */
 export function num(obj){
  obj = obj.replace(/[^\d.]/g,""); //清除"数字"和"."以外的字符
  obj = obj.replace(/^\./g,""); //验证第一个字符是数字
  obj = obj.replace(/\.{2,}/g,"."); //只保留第一个, 清除多余的
  obj = obj.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
  obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //只能输入两个小数
}