<template>
  <div class="loading-layer">
    <div class="loading-flag">
      <div class="loading-layer-mian">
        <div class="the-loading">
          <div class="loading-loader">
            <svg class="loading-circular" viewBox="25 25 50 50">
              <circle
                class="loading-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="1.5"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MyLoading",
  data() {
    return {
      // message: this.$text.MyLoading.message,
    };
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="less" scoped>
.the-loading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  // background-color: #fff;
}

.loading-loader {
  position: relative;
  margin: 0 auto;
  width: 46px;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}
.loading-circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loading-path {
  stroke-dasharray: 10, 400;
  stroke-dashoffset: 0;
  stroke: #fff;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 0, 400;
    stroke-dashoffset: 0;
  }
  25% {
    stroke-dasharray: 100, 400;
    stroke-dashoffset: -30px;
  }
  50% {
    stroke-dasharray: 200, 400;
    stroke-dashoffset: -60px;
  }
  75% {
    stroke-dasharray: 300, 400;
    stroke-dashoffset: -120px;
  }
  100% {
    stroke-dasharray: 400, 400;
    stroke-dashoffset: -200px;
  }
}

.el-icon-loading {
  // animation: rotating 2.5s linear infinite;
}
.loading-layer {
  // width: 100%;
  // height: 100%;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 999999999;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999999999;
  margin-left: -40px;
  margin-top: -40px;
  .loading-flag {
    display: table;
    width: 100%;
    height: 100%;
    .loading-layer-mian {
      display: table-cell;
      font-size: 12px;
      vertical-align: middle;
      text-align: center;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 10px;
      span {
        padding: 15px 20px;
        // background: rgba(0, 0, 0, 0.8);
        color: #fff;
        display: inline-block;
        border-radius: 5px;
        font-size: 30px;
      }
    }
  }
}
</style>