<template>
  <div class="swipper" v-if="swipperShow">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <slot v-for="(item, index) in paginationList">
          <div
            class="swiper-slide"
            @click="slideBoxClick(item, index)"
            v-if="item.index !== 99"
            :key="index"
          >
            <div class="swiper-slide-box">
              <div class="swiper-slide-left">
                <svg-icon :icon-class="item.lunbo"></svg-icon>
              </div>
              <div class="swiper-slide-right">
                <div class="swiper-title">{{ item.title }}</div>
                <div class="swiper-text">
                  <span
                    class="span-p"
                    v-for="(textClild, textIndex) in item.textList"
                    :key="textIndex"
                  >
                    <svg-icon icon-class="select-icon"></svg-icon>
                    <span class="svg-span">{{ textClild.text }}</span
                    ><br />
                  </span>
                </div>
              </div>
            </div>
            <div
              class="swiper-slide-right-link"
              v-if="index === initialSlide + 1"
            >
              进入<i class="swiper-svg">
                <svg-icon icon-class="jinru-icon"></svg-icon
              ></i>
            </div>
          </div>
        </slot>
      </div>
    </div>
    <div class="navigation-pagination">
      <span class="navigation-line"></span>
      <div class="pagination-list">
        <slot v-for="(item, index) in paginationList">
          <div
            class="pagination-box"
            :key="index"
            :class="{ active: item.index === initialSlide }"
            v-if="item.index !== 99"
            @click="slideBoxClick(item, index)"
          >
            <div class="pagination-round">
              <svg-icon
                :icon-class="
                  item.index === initialSlide ? item.icon1 : item.icon
                "
              ></svg-icon>
            </div>
            <div
              class="pagination-title-box"
              :style="{
                color: item.index === initialSlide ? '#4C8AFC' : '',
              }"
            >
              {{ item.title }}
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/swiper.css";
import Swiper from "@/assets/js/swiper.min.js";
export default {
  name: "swipper",
  data() {
    return {
      // swipperShow: false,
      initialSlide: 0,
      mySwiper: null,
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      visit: sessionStorage.getItem("visit"),
      paginationList: [
        {
          index: 99,
          left: false,
          right: false,
          title: "开票",
          icon: "xiao-kaipiao",
          icon1: "xiao-kaipiao",
          lunbo: "",
        },
        {
          index: 0,
          left: true,
          right: true,
          title: "花名册",
          icon: "moren-huamingce",
          icon1: "huamingce",
          path: "/roster",
          type: "2",
          lunbo: "huamingce-lunbo",
          textList: [
            {
              key: 1,
              text: "电子信息登记",
            },
            {
              key: 1,
              text: "电子档案管理",
            },
          ],
        },
        {
          index: 1,
          left: false,
          right: false,
          title: "财务",
          icon: "moren-caiwu",
          icon1: "caiwu",
          path: "/balance",
          type: "4",
          lunbo: "caiwu-lunbo",
          textList: [
            {
              key: 1,
              text: "可视化财务账表",
            },
            {
              key: 1,
              text: "一键智能报税",
            },
          ],
        },
        {
          index: 2,
          left: false,
          right: false,
          title: "工资",
          icon: "moren-gongzi",
          icon1: "gongzibiao",
          path: "/payrollModule",
          path1: "/payrollModule",
          type: "3",
          lunbo: "gonzi-lunbo",
          textList: [
            {
              key: 1,
              text: "工资表智能核算",
            },
            {
              key: 1,
              text: "个税自动申报",
            },
          ],
        },

        {
          index: 3,
          left: false,
          right: false,
          title: "银行对账单",
          icon: "moren-duizhangdan",
          icon1: "duizhangdan",
          path: "/bankFlow",
          lunbo: "duizhangdan-lunbo",
          type: "5",
          textList: [
            {
              key: 1,
              text: "银行对账单上传自动记账",
            },
          ],
        },
      ],
    };
  },

  props: {
    swipperShow: {
      type: Boolean,
      default: false,
    },
    jurisdiction: {
      type: String,
      default: null,
    },
  },
  mounted() {},
  watch: {
    swipperShow() {
      let that = this;
      that.swiperFun();
    },
  },

  methods: {
    swiperFun() {
      let that = this;
      setTimeout(() => {
        var mySwiper = new Swiper(
          ".swiper-container",
          {
            autoplay: false,
            speed: 500,
            autoplayDisableOnInteraction: false,
            centeredSlides: true,
            slidesPerView: 2,
            initialSlide: 1,
            paginationClickable: true,
            onTransitionEnd: function (swiper) {
              // 设置索引值
              that.initialSlide = swiper.activeIndex;
              that.$forceUpdate();
            },
          },
          200
        );
        that.mySwiper = mySwiper;
      });
    },
    slideBoxClick(item, index) {
      if (index - 1 !== this.initialSlide) {
        this.mySwiper.slideTo(index - 1);
      } else {
        const pcTkn = sessionStorage.getItem("pc-tkn"); //用户token
        //* 判断是否登录
        if (pcTkn && pcTkn !== "undefined") {
          if (item.path) {
            if (this.jurisdiction === "1") {
              this.$router.push(item.path);
            } else {
              if (item.type === "5") {
                if (
                  this.jurisdiction.indexOf(item.type) != -1 ||
                  this.jurisdiction.indexOf("4") != -1
                ) {
                  this.$router.push(item.path);
                }
              } else {
                if (this.jurisdiction.indexOf(item.type) != -1) {
                  this.$router.push(item.path);
                } else {
                  this.$store.commit("MyMessage", "暂无权限");
                }
              }
            }
          }
        } else {
          this.$store.commit("showLogin");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.swipper {
  margin: 15px 0;
}
.swiper-slide {
  background: #f9f9f9;
  opacity: 1 !important;
  border-radius: 46px;
  color: #262626;
  cursor: pointer;
  width: 416px;
}
.swiper-slide-active {
  background: #fff;
  width: 724px !important;
  height: 364px !important;
  left: -74px;
  box-shadow: 0px 0px 30px rgba(38, 38, 38, 0.16);
  margin: 70px 50px 0;
}
.swiper-slide-next {
  left: -455px;
  .swiper-slide-right {
    margin-left: 0%;
  }
}
.swiper-slide-prev {
  left: 282px;
}
.swiper-slide-box {
  width: 674px !important;
  height: 275px !important;
  margin: 44px 70px 44px 80px;
  position: relative;
}
.swiper-slide-left {
  float: left;
  width: 50%;
  // color: #fff;
  height: 100%;
  text-align: center;
  svg {
    width: 90%;
    height: 80%;
    margin: 19px auto 0;
  }
}
.swiper-slide-prev .swiper-slide-left {
  position: relative;
  left: -100px;
}
.swiper-slide-right {
  float: left;
  width: 45%;
  height: 100%;
  // color: #fff;
  margin-left: 5%;
  text-align: left;
  .swiper-title {
    font-size: 30px;
    font-weight: bold;
    color: #262626;
    line-height: 42px;
    margin-top: 47px;
    margin-bottom: 23px;
  }
  .swiper-text {
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    .span-p {
      margin-bottom: 12px;
      display: block;
    }
    .svg-span {
      margin-left: 10px;
    }
  }
}

.navigation-pagination {
  position: relative;
  padding: 20px 0 0.2rem;
  width: 100%;
  margin: 0 auto;

  .navigation-line {
    width: 100%;
    position: absolute;
    left: 0;
    top: 40px;
    background: #dbdbdb;
    height: 1px;
    z-index: -1;
  }

  .pagination-list {
    width: 60%;
    margin: 0 auto;
    display: flex;

    .pagination-box {
      flex: 1;
      text-align: center;

      .pagination-round {
        width: 40px;
        height: 40px;
        // background: #9DA5B2;
        border-radius: 50%;
        opacity: 1;
        display: inline-block;
        font-size: 40px;
        position: relative;
        // top: -10px;
        margin-top: 0px;
      }

      .pagination-icon-box {
        font-size: 37px;
        position: relative;
        top: -7px;
      }

      &.active {
        color: #4c8afc;

        .pagination-title-box {
          color: #4c8afc;
        }

        .pagination-icon-box {
          font-size: 50px;
          position: relative;
          top: -7px;
        }

        .pagination-round {
          width: 60px;
          height: 60px;
          // background: #4C8AFC;
          // top: -26px;
          font-size: 60px;
          margin-top: -13px;

          .pagination-icon {
            top: 0px;
            width: 60px;
            height: 60px;
          }
        }

        .pagination-title-box {
          color: #bbbbbb;
          position: relative;
          top: 10px;
        }
      }

      cursor: pointer;

      .pagination-title-box {
        color: #bbbbbb;
        position: relative;
        top: 17px;
      }
    }
  }
}
.swiper-slide-right-link {
  font-size: 16px;
  font-weight: 400;
  color: #bbbbbb;
  text-decoration: none;
  float: right;
  position: absolute;
  bottom: 52px;
  right: 63px;
  .swiper-svg {
    font-size: 30px;
    position: relative;
    top: 5px;
    margin-left: 10px;
  }
  .arrow-right {
    top: 9px;
  }
}
</style>