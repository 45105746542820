export default {
  authorization: function (list) {
    let data = [];
    list.forEach((e) => {
      if (e.userAuthority === "100101") {
        data[1] = '1';
        // 财务权限
      } else if (e.userAuthority === "100104") {
        data[2] = '2';
        //花名册
      } else if (e.userAuthority === "100105") {
        data[3] = '3';
        //工资
      } else if (e.userAuthority === "100110") {
        data[4] = '4';
      } else if (e.userAuthority === "100109") {
        data[5] = '5';
      } else if (e.userAuthority === "100108") {
        data[6] = '6';
        //
      }
    });
    let str = "";
    if (data[1] === '1') {
      str = '1'
    } else {
      data.forEach((element) => {
        str += element;
      });
    }
    return str
  }
}