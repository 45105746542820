import store from '../../store'
const target = store.state.target
const url = target + "/usermanager"


export default {
  queryEnterpriseInfoForUser: url + '/enterprise-service/queryEnterpriseInfoForUser', //查询企业信息接口[查询自己所在公司信息]
  deleteEnterpriseBank: url + '/enterprise-service/deleteEnterpriseBank', //删除银行
  perfectEnterpriseInfo: url + '/enterprise-service/perfectEnterpriseInfo', //完善企业信息pc
  getEmployeeInfoUserId: url + '/employee/getEmployeeInfo-userid', //查询用户信息
  getEmployeeInfo: url + '/employee/getEmployeeInfo', //查询员工信息
  adminGetEnterpriseList: url + '/admin-service/getEnterpriseList', //查询名下企业[管理员]
  flashTokenPc: url + '/login/flashTokenPc', // 企业后台登录刷新token[选择或切换企业]PC
  // flashTokenPcPlatform: url + '/login/flashTokenPc', // 总后台企业后台登录刷新token[选择或切换企业]PC
  flashTokenPcPlatform: url + '/login/flashTokenPc-platform', // 总后台企业后台登录刷新token[选择或切换企业]PC
  getSms: url + '/verificationCode/v1/getSms', // 完善员工信息[自己给自己]
  // adminLoginMsm: url + '/login/v1/admin-login-msm', //用户登录接口-PC短信登录
  adminLoginMsm: url + '/login/v1/user-userLoginMsm', //用户登录接口-PC短信登录
  getEnterpriseEmployee: url + '/employee/getEnterpriseEmployee', //查询花名册列表
  getEnterpriseEmployeeHistory: url + '/employee/getEnterpriseEmployeeHistory', //获取花名册历史记录
  exchangeEnterpriseAdmin: url + '/admin-service/exchangeEnterpriseAdmin', //  变更管理员
  enterpriseGrantAuthorization: url + '/employee/enterpriseGrantAuthorization', //员工授权
  checkApplyJoinEnterprise: url + '/employee/checkApplyJoinEnterprise', // 审批员工[主动申请加入公司的,微信邀请的]

  queryEnterpriseDepartment: url + '/enterprise-service/queryEnterpriseDepartment', //查询部门列表
  deleteEnterpriseDepartment: url + '/enterprise-service/deleteEnterpriseDepartment', //删除部门
  addEnterpriseDepartment: url + '/enterprise-service/addEnterpriseDepartment', //添加部门
  modifyEnterpriseDepartment: url + '/enterprise-service/modifyEnterpriseDepartment', //修改部门
  addEmployee: url + '/employee/addEmployee', // 增加员工信息-管理员添加其他
  dimission: url + '/employee/dimission', // 设置离职日期
  prefectEnterpriseEmployee: url + '/employee/prefectEnterpriseEmployee', // 完善员工信息[自己给自己]
  modifyEnterpriseEmployee: url + '/employee/modifyEnterpriseEmployee', //修改员工信息-管理员修改其他人
  queryBuzhuButie: url + '/employee-buzhubutie/queryBuzhuButie', //查询补助补贴
  addSubsidy: url + '/employee-buzhubutie/addSubsidy', //添加补助补贴
  cancelSubsidy: url + '/employee-buzhubutie/cancelSubsidy', //取消员工补助补贴
  getEmployeeFukuan: url + '/employee/getEmployeeFukuan', //  获取付款人
  getEnterpriseEmployeeCheckers: url + '/employee/getEnterpriseEmployeeCheckers', //员工工资-获取审批人.付款人
  uploadEmployee: url + '/employee-import-service/upload-employee', //导入员工接口
  updateSpecialInfo: url + '/special-opt/updateSpecialInfo', //更新专项信息
  getSpecialInfo: url + '/special-opt/getSpecialInfo', //查询专项信息
  queryPTSEmployeeList: url + '/employee/queryPTSEmployeeList', //人员列表（税局）



  queryRegsterDetail: url + '/enterprise-service/queryRegsterDetail', //查询企业登记信息
  enterpriseRegister: url + '/enterprise-service/enterpriseRegister', //企业登记---税友
  checkShenBaoPassword: url + '/enterprise-service/checkShenBaoPassword', //校验申报密码

  defaultfRegsterDjhm: url + '/enterprise-service/defaultfRegsterDjhm', //  设置默认的登记号码
}