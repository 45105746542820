<template>
  <div class="loading-layer">
    <div class="loading-flag">
      <div class="loading-layer-mian">
        <span id="MessageBox" :style="{ marginLeft: left, marginTop: top }">{{
          message
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyMessage",
  data() {
    return {
      message: this.$text.MyLoading.message,
      left: 0,
      top: 0,
    };
  },
  mounted() {
    let that = this;

    let dom = document.getElementById("MessageBox");
    this.left = (dom.clientWidth / 2) * -1 + "px";
    this.top = (dom.clientHeight / 2 + 15) * -1 + "px";
    setTimeout(() => {
      that.$store.commit("hideMessage");
    }, 1500);
  },
  watch: {
    loadingShow(newValue, oldValue) {
      this.$text.MyLoading.loadingShow = false;
      this.$text.MyLoading.message = "";
    },
  },
};
</script>

<style lang="less" scoped>
.loading-layer {
  z-index: 99999;
  .loading-flag {
    display: table;
    .loading-layer-mian {
      display: table-cell;
      font-size: 12px;
      vertical-align: middle;
      text-align: center;
      span {
        position: fixed;
        left: 50%;
        top: 50%;
        padding: 15px 20px;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        display: inline-block;
        border-radius: 5px;
        font-size: 18px;
        z-index: 99999;
      }
    }
  }
}
</style>