export default {
  /**
   * 判断是否有滚动条
   */
  hasScrolled(el, direction = "vertical") {
    var reportFormMain = document.getElementById(el);
    // console.log(el)
    if (!reportFormMain) return
    var overflow = reportFormMain.currentStyle
      ? reportFormMain.currentStyle.overflow
      : window.getComputedStyle(reportFormMain).getPropertyValue("overflow");

    if (overflow === "hidden") return false;

    return reportFormMain.scrollHeight > reportFormMain.clientHeight;
  },
  cloneObj: function (obj) {
    if (obj == null || obj == undefined) {
      return;
    }
    let newobj = obj.constructor === Array ? [] : {};
    if (typeof obj !== 'object') {
      return;
    }
    else {

      for (let i in obj) {
        newobj[i] = (typeof obj[i] === 'object' ? this.cloneObj(obj[i]) : obj[i]);
      }
    }
    return newobj;
  },
  getDate: function () {
    let date = new Date();
    let year = date.getFullYear() + '';
    let mounth1 =
      date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth();
    let mounth2 =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let day = date.getDate() + ''
    if (mounth1 === '00') mounth1 = '12'
    let param = {
      year: year,
      lastMounth: mounth1,
      thisMounth: mounth2,
      day: day
    }
    return param
  },
  scrollTop: function (name) {
    let scrollTop = document.getElementById(name).scrollTop;
    this.scrollTopFun(name, scrollTop, scrollTop / 10 - 1);
  },
  scrollTopFun: function (name, n, m) {
    if (n > 0) {
      setTimeout(() => {
        document.getElementById(name).scrollTop = n;
        n = n - m;
        this.scrollTopFun(name, n, m);
      }, 10);
    }
  },
  status(key) {
    key = parseFloat(key);
    const status = {
      1: "4",
      2: "4",
      3: "4",
      4: "1",
      5: "1",
      6: "1",
      7: "2",
      8: "2",
      9: "2",
      10: "3",
      11: "3",
      12: "3",
    };
    return status[key];
  },
  testid(id) {
    // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
    var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
    //号码规则校验
    if (!format.test(id)) {
      return {
        status: 0,
        msg: "身份证号码不合规",
      };
    }
    //区位码校验
    //出生年月日校验  前正则限制起始年份为1900;
    var year = id.substr(6, 4),
      //身份证年
      month = id.substr(10, 2),
      //身份证月
      date = id.substr(12, 2),
      //身份证日
      time = Date.parse(month + "-" + date + "-" + year),
      //身份证日期时间戳date
      now_time = Date.parse(new Date()),
      //当前时间戳
      dates = new Date(year, month, 0).getDate(); //身份证当月天数
    if (time > now_time || date > dates) {
      return {
        status: 0,
        msg: "出生日期不合规",
      };
    }
    //校验码判断
    var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //系数
    var b = new Array("1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"); //校验码对照表
    var id_array = id.split("");
    var sum = 0;
    for (var k = 0; k < 17; k++) {
      sum += parseInt(id_array[k]) * parseInt(c[k]);
    }
    if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
      return {
        status: 0,
        msg: "身份证校验码不合规",
      };
    }
    return {
      status: 1,
      msg: "校验通过",
    };
  },
  createPassword() {
    const min = 40
    const max = 40
    // 可以生成随机密码的相关数组
    const num = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const english = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];
    const ENGLISH = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    const hx = ["-"];
    const config = num.concat(english).concat(ENGLISH).concat(hx);
    // 随机从数组中抽出一个数值
    const getOne = (arr) => arr[Math.floor(Math.random() * arr.length)];
    // 先放入一个必须存在的
    const arr = [];
    arr.push(getOne(num));
    arr.push(getOne(english));
    arr.push(getOne(ENGLISH));
    // 获取需要生成的长度
    const len = min + Math.floor(Math.random() * (max - min + 1));
    for (let i = 4; i < len; i++) {
      // 从数组里面抽出一个
      arr.push(config[Math.floor(Math.random() * config.length)]);
    }
    // 乱序
    const newArr = [];
    for (let j = 0; j < len; j++) {
      newArr.push(arr.splice(Math.random() * arr.length, 1)[0]);
    }
    return newArr.join("") + '3';
  }
}