import Vue from "vue";
import Vuex from "vuex";
import text from '../util/text'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    LOGIN: false, //是否显示登录弹窗
    LOADING: false, //是否显示加载loading
    LoadingMessage: "",
    MESSAGE: false,
    // target: "http://192.168.3.4"     //!本地测试 勿删
    target: "https://api.jzcfo.com"        //!正式环境 勿删
    // target: "http://api.ceshihr.com"     //!下上测试 勿删 
  },
  mutations: {
    //显示Loading22e
    MyMessage(state, message) {
      state.LOADING = false
      text.MyLoading.message = message
      state.MESSAGE = true
    },
    //隐藏Loading
    hideMessage(state) {
      state.LoadingMessage = ""
      state.MESSAGE = false
    },
    //显示Loading
    showLoading(state) {
      state.LOADING = true
    },
    //隐藏Loading
    hideLoading(state) {
      state.LOADING = false
    },
    //显示Login
    showLogin(state) {
      state.LOGIN = true
    },
    //隐藏Login
    hideLogin(state) {
      state.LOGIN = false
    },
  },
  actions: {},
  modules: {},
});
