import store from '../../store'
const target = store.state.target
// const target = "http://192.168.3.28:28300"
const url = target + ""
export default {
  importExcel: url + '/batchimport/importExcel', //花名册 人员Excel导入
  getImportExcelStatus: url + '/batchimport/getImportExcelStatus', //获取Excel导入状态
  getAllCacheEmpData: url + '/batchimport/getAllCacheEmpData', //获取Excel导入的所有员工数据及其部门名称
  setDeptFunction: url + '/batchimport/setDeptFunction', //设置部门所属职能
  getPerfectEmpData: url + '/batchimport/getPerfectEmpData', //获取Excel导入信息(未完善)的员工数据
  getNotPerfectEmpData: url + '/batchimport/getNotPerfectEmpData', //获取Excel导入信息(未完善)的员工数据
  addToRoster: url + '/batchimport/addToRoster', //将员工添加到花名册、工资表
  getaddRosterStatus: url + '/batchimport/getaddRosterStatus', //获取添加到花名册的状态
  getEmpList: url + '/empManager/getEmpList', //获取花名册中的所有员工
  updateImportEmpInfo: url + '/batchimport/updateImportEmpInfo', //修改Excel导入的员工信息
}



