import store from '../../store'
const target = store.state.target
const url = target + "/platform"
export default {
  queryDealType: url + '/comeAndGo-deal/queryDealType', //往来明细处置方式
  getSubjectList: url + '/admin/subject/getSubjectList', // 查询科目列表接口
  keywordsGetKeywordsList: url + '/keywords/getKeywordsList', //查询关键词列表
  querySocialSecurity: url + '/socialSecurity/querySocialSecurity', //员工工资-根据城市代码查询城市社保

  getAssetListType: url + '/query-service/getAssetListType', //查询资产类型
  getZhejiuFangfa: url + '/query-service/getZhejiuFangfa', //查询固定资产折旧
  reportFormulaList: url + 'formula/reportFormulaList', // 公式列表[报表]
}