import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Navigation/Navigation.vue";

Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
      requireAuth: false //需要登录
    }
  },
  {
    path: '/Navigation',
    name: 'Navigation',
    component: () => import(/* webpackChunkName: "Navigation" */ '../views/Navigation/Navigation.vue'),
    meta: {
      title: '首页',
      requireAuth: false //需要登录
    }
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import(/* webpackChunkName: "Navigation" */ '../views/transfer.vue'),
    meta: {
      title: '加载中',
      requireAuth: false //需要登录
    }
  },

  {
    path: '/enterpriseList',
    name: 'enterpriseList',
    component: () => import(/* webpackChunkName: "enterprise" */ '../views/company/enterpriseList.vue'),
    meta: {
      title: '企业列表',
      workbench: "企业列表",
      requireAuth: true //需要登录
    }
  },
  {
    path: '/accountOverview',
    name: 'accountOverview',
    component: () => import(/* webpackChunkName: "accountOverview" */ '../views/Home.vue'),
    children: [
      {
        path: '/reportForm',
        name: 'reportForm',
        component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/reportForm.vue'),
        meta: {
          workbench: "工作台"
        },
        children: [
          {
            path: '/balance',
            name: 'balance',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/balance.vue'),
            meta: {
              title: '资产负债表',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
          {
            path: '/profit',
            name: 'profit',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/profit.vue'),
            meta: {
              title: '利润表',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
          {
            path: '/profitQuarter',
            name: 'profitQuarter',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/profitQuarter.vue'),
            meta: {
              title: '利润表',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
          {
            path: '/cashFlow',
            name: 'cashFlow',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/cashFlow.vue'),
            meta: {
              title: '现金流量表',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
          {
            path: '/cashFlowQuarter',
            name: 'cashFlowQuarter',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/cashFlowQuarter.vue'),
            meta: {
              title: '现金流量表',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
          {
            path: '/accountBalance',
            name: 'accountBalance',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/accountBalance.vue'),
            meta: {
              title: '科目余额表',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
          // start
          // {
          //   path: '/accountMain',
          //   name: 'accountMain',
          //   component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/accountMain/accountMain.vue'),
          //   meta: {
          //     title: '科目余额表',
          //     workbench: "财务",
          //     requireAuth: true //需要登录
          //   },

          //   children: [
          //     {
          //       path: '/accountBalance',
          //       name: 'accountBalance',
          //       component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/accountBalance.vue'),
          //       meta: {
          //         title: '科目余额表',
          //         workbench: "财务",
          //         requireAuth: true //需要登录
          //       }
          //     },
          //     {
          //       path: '/flmxz',
          //       name: 'flmxz',
          //       component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/accountMain/flmxz.vue'),
          //       meta: {
          //         title: '科目余额表',
          //         workbench: "财务",
          //         requireAuth: true //需要登录
          //       }
          //     },
          //     {
          //       path: '/flzz',
          //       name: 'flzz',
          //       component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/accountMain/flzz.vue'),
          //       meta: {
          //         title: '科目余额表',
          //         workbench: "财务",
          //         requireAuth: true //需要登录
          //       }
          //     },
          //   ]
          // },
          // end
          {
            path: '/detailed',
            name: 'detailed',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/detailed.vue'),
            meta: {
              title: '往来明细',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
          {
            path: '/assetList',
            name: 'assetList',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/assetList/assetList.vue'),
            meta: {
              title: '资产清单',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
          {
            path: '/stock',
            name: 'stock',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/stock/stock.vue'),
            meta: {
              title: '库存明细',
              workbench: "财务",
              requireAuth: true //需要登录
            },
            children: [
              {
                path: '/kcmxb',
                name: 'kcmxb',
                component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/stock/components/kcmxb.vue'),
                meta: {
                  title: '库存明细表',
                  workbench: "财务",
                  requireAuth: true //需要登录
                }
              },
              {
                path: '/zgkcmxb',
                name: 'zgkcmxb',
                component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/stock/components/zgkcmxb.vue'),
                meta: {
                  title: '暂估库存明细表',
                  workbench: "财务",
                  requireAuth: true //需要登录
                }
              },
              {
                path: '/dclkc',
                name: 'dclkc',
                component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/stock/components/dclkc.vue'),
                meta: {
                  title: '待处理库存',
                  workbench: "财务",
                  requireAuth: true //需要登录
                }
              },
              {
                path: '/kcpl',
                name: 'kcpl',
                component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/stock/components/kcpl.vue'),
                meta: {
                  title: '库存品类',
                  workbench: "财务",
                  requireAuth: true //需要登录
                }
              },

            ]
          },
          {
            path: '/spphs',
            name: 'spphs',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/stock/components/spphs.vue'),
            meta: {
              title: '商品平衡式',
              workbench: "财务",
              requireAuth: true //需要登录
            },
            children: [
              {
                path: '/slphs',
                name: 'slphs',
                component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/stock/components/kcmxb.vue'),
                meta: {
                  title: '数量平衡式',
                  workbench: "财务",
                  requireAuth: true //需要登录
                }
              },
              {
                path: '/jgphs',
                name: 'jgphs',
                component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/stock/components/jgphs.vue'),
                meta: {
                  title: '价格平衡式',
                  workbench: "财务",
                  requireAuth: true //需要登录
                }
              },
              {
                path: '/zhphs',
                name: 'zhphs',
                component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/stock/components/kcmxb.vue'),
                meta: {
                  title: '组合平衡式',
                  workbench: "财务",
                  requireAuth: true //需要登录
                }
              },
            ]

          },
          {
            path: '/voucherList',
            name: 'voucherList',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/voucherList.vue'),
            meta: {
              title: '记账凭证',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
          {
            path: '/billList',
            name: 'billList',
            component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/billList.vue'),
            meta: {
              title: '公司票据',
              workbench: "财务",
              requireAuth: true //需要登录
            }
          },
        ]
      },
      {
        path: '/taxReturns',
        name: 'taxReturns',
        component: () => import(/* webpackChunkName: "reportForm" */ '../views/reportForm/taxReturns.vue'),
        meta: {
          title: '报税表',
          workbench: "报税表",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/bankFlow',
        name: 'bankFlow',
        component: () => import(/* webpackChunkName: "bankFlow" */ '../views/company/bankFlow.vue'),
        meta: {
          title: '银行对账单',
          workbench: "银行对账单",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/enterpriseInformation',
        name: 'enterpriseInformation',
        component: () => import(/* webpackChunkName: "enterprise" */ '../views/company/enterpriseInformation.vue'),
        meta: {
          title: '企业信息',
          workbench: "企业信息",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/payserver',
        name: 'payserver',
        component: () => import(/* webpackChunkName: "enterprise" */ '../views/company/payserver.vue'),
        meta: {
          title: '企业信息',
          workbench: "企业信息",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/roster',
        name: 'roster',
        component: () => import(/* webpackChunkName: "person" */ '../views/roster/roster.vue'),
        meta: {
          title: '花名册',
          workbench: "花名册",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/exportRoster',
        name: 'exportRoster',
        component: () => import(/* webpackChunkName: "person" */ '../views/roster/exportRoster.vue'),
        meta: {
          title: '花名册',
          workbench: "花名册",
          style: "visit",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/department',
        name: 'department',
        component: () => import(/* webpackChunkName: "person" */ '../views/roster/department.vue'),
        meta: {
          title: '部门设置',
          workbench: "花名册",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/addStaff',
        name: 'addStaff',
        component: () => import(/* webpackChunkName: "person" */ '../views/roster/addStaff.vue'),
        meta: {
          title: '添加员工',
          workbench: "花名册",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/detailsStaff',
        name: 'detailsStaff',
        component: () => import(/* webpackChunkName: "person" */ '../views/roster/detailsStaff.vue'),
        meta: {
          title: '员工信息',
          workbench: "花名册",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/quitStaff',
        name: 'quitStaff',
        component: () => import(/* webpackChunkName: "person" */ '../views/roster/quitStaff.vue'),
        meta: {
          title: '员工信息',
          workbench: "花名册",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/payrollModule',
        name: 'payrollModule',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/payroll.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/personDetails',
        name: 'personDetails',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/personDetails.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/personInformation',
        name: 'personInformation',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/personInformation.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/generatePayroll',
        name: 'generatePayroll',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/generatePayroll.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          style: "visit",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/payrollRecord',
        name: 'payrollRecord',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/payrollRecord.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/generatePayrollDetails',
        name: 'generatePayrollDetails',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/generatePayrollDetails.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          style: "visit",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/generatePayrollOld',
        name: 'generatePayrollOld',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/generatePayrollOld.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          style: "visit",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/transitionPage1',
        name: 'transitionPage1',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/components/transitionPage1.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/transitionPage2',
        name: 'transitionPage2',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/components/transitionPage2.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/transitionPage3',
        name: 'transitionPage3',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/components/transitionPage3.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/transitionPage4',
        name: 'transitionPage4',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/components/transitionPage4.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          requireAuth: true //需要登录
        }
      },
      {
        path: '/transitionPage5',
        name: 'transitionPage5',
        component: () => import(/* webpackChunkName: "person" */ '../views/payrollModule/components/transitionPage5.vue'),
        meta: {
          title: '工资',
          workbench: "工资",
          requireAuth: true //需要登录
        }
      },
      {
        path: "/attendance",
        name: "attendance",
        component: () => import(/* webpackChunkName: "Navigation" */ '../views/attendance.vue'),
        meta: {
          title: '考勤设置',
          workbench: "考勤设置",
          requireName: "attendance",
          requireAuth: true //需要登录
        },
        children: [
          {
            //考勤组设置
            path: "/attendanceGroup",
            name: "attendanceGroup",
            title: "考勤组设置",
            workbench: "考勤",
            meta: {
              title: "考勤组设置",
              workbench: "考勤",
              requireAuth: true //需要登录
            },
            component: () =>
              import(/* webpackChunkName: "subjectlist" */ "../views/attendanceGroup/index.vue"),
          },
          {
            //考勤组设置
            path: "/editAttendance",
            name: "editAttendance",
            workbench: "考勤",
            title: "考勤组设置",
            meta: {
              title: "考勤组设置",
              workbench: "考勤",
              requireAuth: true //需要登录
            },
            component: () =>
              import(/* webpackChunkName: "subjectlist" */ "../views/attendanceGroup/editAttendance.vue"),
          },
          {
            //考勤组设置
            path: "/attendanceShift",
            name: "attendanceShift",
            workbench: "考勤",
            title: "考勤组设置",
            meta: {
              title: "考勤组设置",
              workbench: "考勤",
              requireAuth: true //需要登录
            },
            component: () =>
              import(/* webpackChunkName: "subjectlist" */ "../views/attendanceShift/index.vue"),
          },
          {
            //考勤组设置
            path: "/attendanceList",
            name: "attendanceList",
            title: "考勤列表",
            workbench: "考勤",
            meta: {
              title: "考勤列表",
              workbench: "考勤",
              requireAuth: true //需要登录
            },
            component: () =>
              import(/* webpackChunkName: "subjectlist" */ "../views/attendanceList/attendanceList.vue"),
          },
        ]
      },
    ]
  },

]
import { getCookie } from '../util/cookie'
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //页面title处理
  const title = to.meta && to.meta.title
  // console.log(to)
  if (title) {
    document.title = title + '-即账'
  }

  const pcTkn = sessionStorage.getItem("pc-tkn") //用户token

  if (!to.meta) {
    next({
      path: '/Navigation'
    })
  }
  //需要登录处理
  if (to.meta.requireAuth) {
    if (pcTkn) {
      next()
    } else {
      next({
        path: '/Navigation'
      })

      document.title = '首页-即账'
    }
  } else {
    next()
  }
})



export default router;
