var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-layer"},[_c('div',{staticClass:"popup-layer-bg",on:{"click":_vm.hideLogin}}),_c('div',{staticClass:"loginPopup"},[_c('div',{staticClass:"popup-layer-close",on:{"click":_vm.hideLogin}},[_c('svg-icon',{attrs:{"icon-class":"popup-close"}})],1),_c('div',{staticClass:"popup-login-main-top"},[_c('svg-icon',{staticClass:"logo-login",attrs:{"icon-class":"logo_login"}})],1),_c('div',{staticClass:"popup-login-title textAlign"},[_vm._v("即账")]),_c('div',{staticClass:"popup-login-box"},[_c('p',{staticClass:"popup-login-box-text"},[_vm._v(" 输入手机号即代表您以阅读并同意"),_c('a',{staticStyle:{"color":"#4c8afc"},attrs:{"href":_vm.hostname,"target":"_block"}},[_vm._v(" 《服务协议》 ")])]),_c('div',{staticClass:"popup-login-input"},[_c('span',{staticClass:"input-left"},[_vm._v("+86 ")]),_c('span',{staticClass:"popup-login-line"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mobile),expression:"mobile"}],ref:"mobile",staticClass:"popup-ipt",attrs:{"type":"number","id":"mobile","autocomplete":"off","oninput":"if(value.length>11)value=value.slice(0,11)"},domProps:{"value":(_vm.mobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.mobile=$event.target.value}}})])]),_c('div',{staticClass:"popup-login-box popup-warp"},[_c('p',{staticClass:"popup-login-box-text"},[_vm._v("验证手机号")]),_c('div',{staticClass:"code"},_vm._l((_vm.codeList),function(item,index){return _c('div',{key:index,staticClass:"code-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code[item.index]),expression:"code[item.index]"}],staticClass:"inputStyle",class:{
              iptBor: _vm.code[item.index] || _vm.code[item.index] === 0,
              borderB: _vm.inputFlag,
            },attrs:{"id":item.id,"autocomplete":"off","readonly":_vm.inputFlag,"type":"text"},domProps:{"value":(_vm.code[item.index])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.code, item.index, $event.target.value)}}})])}),0)]),_c('div',[_c('a',{staticClass:"popup-link",class:{
          popupLinkColor: _vm.verification !== '获取验证码',
          popupLinkColor1: _vm.verification === '重新获取验证码',
        },attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.verificationFun()}}},[_vm._v(" "+_vm._s(_vm.verification)+" ")])])]),(_vm.enterpriseShow)?_c('div',{staticClass:"loginPopup"},[_c('div',{staticClass:"popup-layer-close",on:{"click":_vm.hideLogin}},[_c('svg-icon',{attrs:{"icon-class":"popup-close"}})],1),_c('div',{staticClass:"popup-login-title popup-warp popup-margin-top"},[_vm._v(" 请选择企业 ")]),_c('div',{staticClass:"popup-search"},[_c('i',{staticClass:"el-icon-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputText),expression:"inputText"}],staticClass:"popup-ipt popup-ipt-search",attrs:{"type":"text","placeholder":_vm.placeholderText,"autocomplete":"off"},domProps:{"value":(_vm.inputText)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.inputText=$event.target.value},function($event){return _vm.fuzzyQuery(_vm.inputText)}]}})]),_c('div',{staticClass:"popup-mian-list webkit-scrollbar popup-warp",attrs:{"id":"list"}},_vm._l((_vm.enterpriseList),function(item,index){return _c('div',{key:index,staticClass:"popup-list-box border-b",on:{"click":function($event){return _vm.enterpriseClick(item)}}},[_c('div',{staticClass:"popup-list-box-left"},[_c('span',{class:{
              popupListSpan1: item.companyNature === 2,
              popupListSpan2: item.companyNature === 1,
            }},[(item.companyNature === 2)?_vm._t("default",function(){return [_vm._v("一般")]}):_vm._e(),(item.companyNature === 1)?_vm._t("default",function(){return [_vm._v("小规")]}):_vm._e()],2),_c('span',{staticClass:"companyStyle"},[_vm._v(_vm._s(item.company))])])])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }