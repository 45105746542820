import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Triangle from './components/Triangle.vue';
Vue.component('Triangle', Triangle);/*初始化公共组件*/

import loadingCss from './components/loadingCss.vue';
Vue.component('loadingCss', loadingCss);/*初始化公共组件*/
Vue.use(ElementUI);
import './assets/less/reset.less'
import './assets/less/element-style.less'
import './assets/less/popup.less'
import './assets/less/reportForm.less'
import './assets/less/accountTable.less'
import './assets/less/department.less'
import './assets/less/geshui.less'
import './assets/less/home.less'
import './assets/less/attendance.less'
import ExTableColumn from 'ex-table-column';
Vue.component(ExTableColumn.name, ExTableColumn);

import { setCookie, getCookie, delCookie } from './util/cookie'
Vue.prototype.$setCookie = setCookie
Vue.prototype.$getCookie = getCookie
Vue.prototype.$delCookie = delCookie

import jurisdiction from './util/jurisdiction'
Vue.prototype.$jurisdiction = jurisdiction
import dictionaries from './util/dictionaries'
Vue.prototype.$dictionaries = dictionaries
import config from './util/config'
Vue.prototype.$config = config
import { selectText, num } from './util/currency'
Vue.filter('selectText', selectText)
Vue.filter('num', num)

import bank from './util/bank'
Vue.prototype.$bank = bank
import cityData from './util/cityData'
import addAssets from './util/addAssets'
import text from './util/text'
Vue.prototype.$text = text
Vue.prototype.$cityData = cityData
Vue.prototype.$addAssets = addAssets

import Bold from './util/Blob.js'
import Export2Excel from './util/Export2Excel.js'

// import ExTableColumn from './components/exTableColumn.js';

// Vue.component(ExTableColumn.name, ExTableColumn);
// import ExTableColumn from 'ex-table-column';
// Vue.component(ExTableColumn.name, ExTableColumn);

import '@/icons'
Vue.directive('loadmore', {
  bind(el, binding) {
    const selectWrap = el.querySelector('.el-table__body-wrapper')
    selectWrap.addEventListener('scroll', function () {
      let sign = 0
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (scrollDistance <= sign) {
        // console.log(123)
        binding.value()
      }
    })
  }
})
Vue.config.productionTip = false;

import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
