const texts = {
  errorText: '请求异常',
  promptTitle: '提示',
  //登录相关
  loginMobileNoTxt: '登录手机号',
  loginPasswordTxt: '登录密码',
  loginErrorTxt: '用户名或密码错误',
  //手机号相关
  phoneTxt: '请输入您的手机号',
  phonePromptTxt: '请输入正确的手机号',
  //密码相关
  passwordTxt: '请输入您的密码',
  passwordTxt2: '请确认您的密码',
  passwordTxt3: '请输入您的旧密码',
  passwordPromptTxt: '密码至少包含大写字母、小写字母、数字，且不少于8位',
  passwordPromptTxt2: '两次密码输入不一致',
  setPasswordTxt: '设置密码',
  //短信验证码相关
  smsTime: 60,
  smscodeTxt: '请输入您的短信验证码',
  smscodePromptTxt: '请输入正确的短信验证码',
  smscodeButtonTxt: '获取短信验证码',
  //图片验证码相关
  imgcodeTxt: '请输入您的验证码',
  imgcodePromptTxt: '请输入正确的验证码',
  imgcodeErrorTxt: '图形验证码校验未通过',

}

let MyLoading = {
  loadingShow: false,
  message: ""
}

export default {
  texts,
  MyLoading
}
