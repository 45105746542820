<template>
  <div class="navigation">
    <div class="header">
      <div class="header-left">
        <div class="header-logo">
          <svg-icon icon-class="pc-logo" class="pc-logo-style"></svg-icon>
        </div>
        <div class="header-title">
          <span></span>不用会计的财务软件！{{ aaaa }}
        </div>
      </div>
      <div class="header-right">
        <el-button
          type="primary"
          @click="loginFun"
          v-if="!loginShow"
          class="header-login-btn"
          >登录</el-button
        >
        <companySelect v-if="loginShow"></companySelect>
      </div>
      <div class="clear"></div>
    </div>
    <div class="navigation-over">
      <div class="navigation-main">
        <swipper
          :swipperShow="swipperShow"
          :jurisdiction="jurisdiction"
        ></swipper>
      </div>
    </div>
    <TheFooter></TheFooter>
  </div>
</template>

<script>
import {
  queryEnterpriseInfo,
  queryEnterpriseInfoForUser,
  getEmployeeInfoUserId,
  flashTokenPc,
  queryEnterpriseDepartment,
} from "@/http/api";
import companySelect from "@/components/companySelect.vue";
import TheFooter from "@/components/TheFooter.vue";
import swipper from "./components/swipper.vue";
export default {
  data() {
    return {
      loginShow: false,
      swipperShow: false,
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      visit: sessionStorage.getItem("visit"),
      jurisdiction: "",
      aaaa: "",
    };
  },
  components: {
    companySelect,
    swipper,
    TheFooter,
  },

  mounted() {
    let query = this.$route.query;
    this.aaaa = process.env.VUE_APP_QNURL;
    // console.log(sessionStorage.getItem("pc-tkn"));
    if (query.pcTkn) {
      sessionStorage.setItem("pc-tkn", query.pcTkn);
      sessionStorage.setItem("enterpriseId", query.id);
      this.$setCookie("enterpriseId", query.id);
      sessionStorage.setItem("visit", query.visit);
      sessionStorage.setItem("jurisdiction", 1);
      sessionStorage.setItem("userId", query.adminId);
      sessionStorage.setItem("accountingCriterion", query.EnterpriseType);
      this.userId = query.adminId;
      this.enterpriseId = query.id;
      this.loginShow = true;
      // 刷新token
      // this.$router.push("/Navigation");
      // this.flashTokenPc(query.id);
      this.swipperShow = true;
      // this.queryEnterpriseInfo();
      this.getEmployeeInfoUserId();
    } else {
      if (sessionStorage.getItem("pc-tkn")) {
        // this.flashTokenPc(this.enterpriseId);
        if (this.userId) {
          this.loginShow = true;
          this.getEmployeeInfoUserId();
        } else {
          this.swipperShow = true;
        }
      }
    }
    this.swipperShow = true;
    if (sessionStorage.getItem("pc-tkn")) {
      this.queryEnterprieDepartment();
    }
    this.$forceUpdate();
  },
  methods: {
    // 部门列表s
    queryEnterprieDepartment() {
      let that = this;
      let param = {
        userId: that.userId,
        enterpriseId: that.enterpriseId,
      };
      queryEnterpriseDepartment(param).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          sessionStorage.setItem("departmentList", JSON.stringify(list));
          this.$forceUpdate();
        }
      });
    },
    flashTokenPc(enterpriseId) {
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: enterpriseId,
      };
      flashTokenPc(param).then((res) => {
        if (res.state == 200) {
          sessionStorage.setItem("pc-tkn", res.data.token);
          // location.reload();
        }
      });
    },
    loginFun() {
      this.$store.commit("showLogin");
    },
    queryEnterpriseInfo() {
      let param = {
        enterpriseId: sessionStorage.getItem("enterpriseId"),
        userId: this.userId,
      };
      queryEnterpriseInfo(param).then((res) => {
        // console.log("res", res);
        this.userId = res.data.enterpriseInfo.userId;
        sessionStorage.setItem("userId", res.data.enterpriseInfo.userId);
        sessionStorage.setItem(
          "accountingCriterion",
          res.data.enterpriseInfo.accountingstandard
        );

        this.$router.push("/Navigation");
        location.reload();
        this.swipperShow = true;
      });
    },
    // companySelect
    queryEnterpriseInfoForUser() {
      var that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      queryEnterpriseInfoForUser(param).then((res) => {
        // console.log("res", res);
        this.visit = sessionStorage.getItem("visit");
        // this.visit = this.$getCookie("visit");
        this.companyNature = res.data.companyNature;
        sessionStorage.setItem(
          "accountingCriterion",
          res.data.accountingstandard
        );
        sessionStorage.setItem("company", res.data.company);
        sessionStorage.setItem("companyNature", res.data.companyNature);
        sessionStorage.setItem("isStock", res.data.isStock);
        sessionStorage.setItem("creditCode", res.data.creditCode);
        this.$forceUpdate();
        if (this.visit === "visit") {
          return;
        }
        if (
          !res.data.company ||
          !res.data.enterpriseProvince ||
          !res.data.enterpriseAddress ||
          !res.data.companyNature ||
          !res.data.industrytype ||
          !res.data.corporation ||
          !res.data.accountingstandard ||
          !res.data.constructionType ||
          !res.data.declareType ||
          res.data.bankList.length === 0
        ) {
          this.popupMsg = "您需要先下载即账APP注册<br />完善企业信息后再登录";
          // this.enterprise = true;
          this.popupErrFlag = false;
        }
      });
    },
    getEmployeeInfoUserId() {
      var that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      getEmployeeInfoUserId(param).then((res) => {
        // console.log("res", res);
        if (res.code === 0) {
          that.jurisdiction = null;
          let data = [];
          if (this.visit !== "visit") {
            that.jurisdiction = this.$jurisdiction.authorization(
              res.data.userAuthorizationList
            );
          } else {
            that.jurisdiction = "1";
          }
          sessionStorage.setItem("jurisdiction", that.jurisdiction);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.navigation-main {
  position: absolute;
  width: 1328px;
  top: 50%;
  margin-top: -300px;
}
.navigation {
  width: 1328px;
  margin: 0 auto;
  padding: 0 0 0;
  height: calc(100%);
  overflow: hidden;
  .header {
    width: 100%;
    margin-top: 3.4%;
    .header-left {
      width: 40%;
      float: left;
      padding: 2px 0;
      .header-logo {
        height: 40px;
        width: 104px;
        float: left;
        .pc-logo-style {
          height: 40px !important;
          width: 104px !important;
          position: relative;
          // top: 6px;
        }
      }
      .header-title {
        height: 40px;
        line-height: 40px;
        float: left;
        font-size: 22px;
        font-weight: bold;
        color: #262626;
        span {
          width: 1px;
          height: 21px;
          display: inline-block;
          background: #262626;
          margin: 0 25px;
          position: relative;
          top: 4px;
        }
      }
    }
    .header-right {
      width: 59%;
      float: left;
      text-align: right;
      position: relative;
      // right: -36px;
      .header-login-btn {
        width: 116px;
        height: 45px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        opacity: 1;
        border-radius: 4px;
        font-size: 20px;
        font-weight: bold;
        color: #4c8afc;
      }
    }
  }
  .navigation-over {
    width: 100%;
    // min-height: calc(100% - 140px);
    min-height: calc(100% - 3.4% - 130px);
  }
}
</style>