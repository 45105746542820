import store from '../../store'
const target = store.state.target
const url = target + "/inventory-service"
export default {
  addDetail: url + '/addInventory/addDetail', //增加库存明细
  addEstimatedDetail: url + '/addInventory/addEstimatedDetail', //增加暂估库存明细

  inventoryDetail: url + '/inventoryDetail/queryInventoryDetail', //查询库存明细
  queryEstimatedInDetail: url + '/inventoryDetail/queryEstimatedInDetail', //暂估明细表
  queryProductName: url + '/inventoryDetail/queryProductName', //查询库存商品名称
  queryWaiting: url + '/inventoryDetail/queryWaiting', //查询待处理库存
  mergeProduct: url + '/inventoryDetail/mergeProduct', //合并库存

  queryComposeName: url + '/inventoryProduct/queryComposeName', //查询组合商品名称
  addComposeName: url + '/inventoryProduct/addComposeName', //增加组合商品名称

  delDetail: url + '/addtest/delDetail', //删除测试库存明细
  delEstimatedDetail: url + '/addtest/delEstimatedDetail', //删除测试暂估库存明细
  delWaitDetail: url + '/addtest/delWaitDetail', //删除待处理库存
  testTransaction: url + '/addtest/testTransaction', //测试事务
}