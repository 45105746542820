<template>
  <div class="footer">
    <slot v-if="!flag">
      Copyright 2021 jzcfo.com Inc. All Rights Reserved. /
      北京即账数字科技有限公司 版权所有 /
      <br />
      <div style="width: 300px; margin: 0 auto; padding: 20px 0">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802034519"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
        >
          <img src="../assets/img/guohui.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            <!-- 京公安网备案号  11010802034000 -->
            京公网安备 11010802034519号
          </p>
        </a>
      </div>
      <span class="icp"
        ><a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #bbb;
            font-size: 12px;
          "
          >京ICP 备 2021005831 号</a
        ></span
      >
      <!-- <span class="icp">京ICP备2021005831号</span> -->
    </slot>
    <slot v-else>
      Copyright 2021 jzdcs.com Inc. All Rights Reserved. /
      北京轻赢数据科技有限公司 版权所有 /
      <br />
      <div style="width: 300px; margin: 0 auto; padding: 20px 0">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802034519"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
        >
          <img src="../assets/img/guohui.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            京公安网备案号 11010802034000
            <!-- 京公网安备 11010802034519号 -->
          </p>
        </a>
      </div>
      <span class="icp"
        ><a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #bbb;
            font-size: 12px;
          "
          >京ICP 备18049762</a
        ></span
      >
      <!-- <span class="icp">京ICP 备18049762</span> -->
      <!-- <span class="icp">京ICP备2021005831号</span> -->
    </slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      flag: false,
    };
  },
  mounted() {
    if (location.hostname === "www.jzdcs.com") {
      this.flag = true;
    }
  },
};
</script>

<style lang="less" scoped>
.footer {
  padding: 15px 0 0;
  text-align: center;
  color: #bbbbbb;
  div {
    display: inline-block;
    padding: 10px 20px !important;
    width: auto !important;
    a {
      padding: 0 !important;
      font-size: 12px !important;
      p {
        color: #bbbbbb !important;
      }
    }
    img {
      width: 20px;
      height: 20px;
      display: inline-block;
    }
  }
  .icp {
    position: relative;
    top: -5px;
  }
}

.navigation-main {
  width: 100%;
  height: 100%;
  // overflow-y: auto;
}
</style>