import store from '../../store'
const target = store.state.target
const url = target + "/voucher"

export default {

  queryEnterpriseBill: url + '/enterpriseBill/v1/queryEnterpriseBill', //查询公司票据 
  queryBillInfo: url + '/bill/v1/queryBillInfo', //查询票据信息
  workFlowList: url + '/workbenchService/v2/workFlowList', //消息列表v2
  // changePayee: url + '/billCheque/v1/changePayee', //票据审批-变更收款人
  workflowToOther: url + '/billCheck/v1/workflowToOther', //票据转审pc
  payReject: url + '/salary-pay/v2/payReject', //工资付款驳回v2
  exchangePayer: url + '/billPay/v1/exchangePayer', //变更付款人-pc端离职时用到
  changePayee: url + '/billCheque/v1/changePayee', //变更收款人-pc端离职时用到
  // https://api.jzcfo.com/voucher/workbenchService/v2/workFlowList
  // attendance/approve/poapprove
}






