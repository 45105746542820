<template>
  <div class="companySelect">
    <div class="companySelect-main" @click.stop="companyPopupClick">
      <div class="companySelect-left">
        <span class="companySelect-logo">
          <div class="user-div" v-if="userHead">
            <img :src="userHead" alt="" />
          </div>
          <div class="user-div" v-else>
            {{ userName.substr(userName.length - 2, 2) }}
          </div>
        </span>
      </div>
      <div class="companySelect-right">
        <div class="companySelect-title">
          <span class="title-icon">{{ company }}</span>
          <!-- <svg-icon icon-class="blueA14" class="jiantou-bottom"></svg-icon> -->
          <div class="jiantou-div">
            <!-- <i class="el-icon-caret-bottom"></i> -->
            <svg-icon
              icon-class="jiantou-bottom"
              class="jiantou-bottom"
            ></svg-icon>
          </div>
        </div>
        <div class="companySelect-name">
          <span v-if="companyNature === 1" style="float: left">
            <svg-icon icon-class="xiaogui" class="yiban"></svg-icon>
          </span>
          <span v-if="companyNature === 2" style="float: left">
            <svg-icon icon-class="yiban" class="yiban"></svg-icon>
          </span>
          <span
            style="
              max-width: 65px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              float: left;
            "
            >{{ userName }}</span
          >
          <span>{{ mobileNum }}</span>
        </div>
      </div>
      <!-- @mouseleave="mouseleaveFun" -->
      <div
        class="companySelect-popup companyShow"
        @click.stop=""
        v-if="companyPopup"
      >
        <div class="companySelect-popup-header companyShow">
          <div class="companySelect-left companyShow">
            <span class="companySelect-logo companyShow">
              <div class="user-div companyShow" v-if="userHead">
                <img :src="userHead" alt="" />
              </div>
              <div class="user-div companyShow" v-else>
                {{ userName.substr(userName.length - 2, 2) }}
              </div>
            </span>
          </div>
          <div class="companySelect-right companyShow">
            <div
              class="companySelect-title companyShow"
              @click.stop="gotoFun('/enterpriseInformation')"
            >
              <span class="title-icon companyShow">{{ company }}</span>
              <div class="jiantou-div companyShow">
                <!-- <i class="el-icon-caret-bottom"></i> -->
                <svg-icon
                  icon-class="jiantou-bottom"
                  class="jiantou-top companyShow"
                ></svg-icon>
              </div>
            </div>
            <div class="companySelect-name companyShow">
              <span v-if="companyNature === 1">
                <svg-icon
                  icon-class="xiaogui"
                  class="yiban companyShow"
                ></svg-icon>
              </span>
              <span v-if="companyNature === 2">
                <svg-icon
                  icon-class="yiban"
                  class="yiban companyShow"
                ></svg-icon>
              </span>
              <span>{{ userName }}</span>
              <span>{{ mobileNum }}</span>
            </div>
          </div>
        </div>
        <slot v-if="companyAjax">
          <div
            class="companyShow"
            style="text-align: center; font-size: 26px; color: #bbb"
          >
            <i class="el-icon-loading"></i>
          </div>

          <div class="companyAjax companyShow" style="color: #bbb">
            努力加载中...
          </div>
        </slot>
        <slot v-if="!companyAjax">
          <div class="companySelect-popup-search companyShow">
            <input
              type="text"
              class="popup-ipt-search companyShow"
              :placeholder="placeholderText"
              autocomplete="off"
              v-model="inputText"
              @click.stop=""
              @input="fuzzyQuery(inputText)"
            />
            <svg-icon
              icon-class="fangdajing"
              class="fangdajing companyShow"
            ></svg-icon>
          </div>
          <div class="companySelect-list companyShow" id="list">
            <div
              class="companySelect-list-box companyShow"
              v-for="(item, index) in enterpriseList"
              :key="index"
              :class="{ cr: item.id === enterpriseId }"
              @click.stop="enterpriseClick(item)"
            >
              <div class="companySelect-list-box-l companyShow">
                <!-- <svg-icon icon-class="yiban" class="yiban"></svg-icon> -->
                <span v-if="item.companyNature === 1">
                  <svg-icon
                    icon-class="xiaogui"
                    class="yiban companyShow"
                  ></svg-icon>
                </span>
                <span v-if="item.companyNature === 2">
                  <svg-icon
                    icon-class="yiban"
                    class="yiban companyShow"
                  ></svg-icon>
                </span>
                <div class="companyTitle companyShow">
                  {{ item.company }}
                </div>
              </div>
              <div
                class="companySelect-list-box-r companyShow"
                v-if="item.id === enterpriseId"
              >
                <svg-icon
                  icon-class="duigou"
                  class="duigou companyShow"
                ></svg-icon>
              </div>
            </div>
          </div>
        </slot>
        <div class="clear"></div>
        <div class="headerQuit companyShow" @click.stop="closePopup">
          退出账号
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="huifuFlag">
      <div class="popup-layer-bg" @click="rosterClose"></div>
      <div class="huifuPopup">
        <div class="popup-layer-close" @click="rosterClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p2">您已离职，暂无权限访问。</p>
          <!-- <p class="huifuPopup-p2">如有疑问，请联系公司管理员</p> -->
          <!-- <p class="huifuPopup-p2">有信息变动请手动修改</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="rosterClose">我知道了</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryEnterpriseInfoForUser,
  adminGetEnterpriseList,
  flashTokenPc,
  flashTokenPcPlatform,
  getEmployeeInfoUserId,
} from "@/http/api";
export default {
  name: "companySelect",
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      visit: sessionStorage.getItem("visit"),
      companyNature: "",
      companyPopup: false,
      huifuFlag: false,
      placeholderText: "",
      inputText: "",
      offsetNum: 0,
      flag: true,
      company: "",
      userName: "",
      mobileNum: "",
      enterpriseList: [],
      enterpriseListBase: [],
      userHead: "",
      oldDate: {
        enterpriseId: "",
        jurisdiction: "",
      },
      companyQuery: false,
      companyAjax: false,
    };
  },
  watch: {
    companyAjax(newValue, oldValue) {
      if (!newValue) {
        this.$nextTick(() => {
          if (this.offsetNum > 7) {
            document.getElementById("list").scrollTop = this.offsetNum * 43;
          }
        });
      }
    },
  },
  mounted() {
    let that = this;
    document.addEventListener("click", function (e) {
      if (typeof e.target.className === "string") {
        if (e.target.className.indexOf("companyShow") === -1) {
          that.mouseleaveFun();
          that.$forceUpdate();
        }
      }
    });
  },
  created() {
    this.queryEnterpriseInfoForUser();
    if (this.visit !== "visit") this.getEmployeeInfoUserId(this.enterpriseId);
    if (this.visit === "visit") this.getEmployeeInfoUserId1();
  },
  methods: {
    mouseleaveFun() {
      if (!this.companyQuery) {
        this.companyPopup = false;
      }
    },

    rosterClose() {
      this.huifuFlag = false;
      this.closePopup();
    },
    companyPopupClick() {
      this.companyAjax = true;
      this.companyQuery = true;
      this.companyPopup = true;
      this.adminGetEnterpriseList();
    },
    getEmployeeInfoUserId1() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      getEmployeeInfoUserId(param).then((res) => {
        if (res.code === 0) {
          sessionStorage.setItem("infoUser", JSON.stringify(res));
          this.userHead = res.data.userHead;
          if (this.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
            this.userHead =
              "https://www.jzcfo.com" +
              this.userHead.split("http://jz.jzdcs.com")[1];
          }
        }
      });
    },
    // 切换公司
    enterpriseClick(item) {
      this.oldDate = {
        userId: sessionStorage.getItem("userId"),
        enterpriseId: sessionStorage.getItem("enterpriseId"),
        jurisdiction: sessionStorage.getItem("jurisdiction"),
        pcTkn: sessionStorage.getItem("pc-tkn"),
        accountingCriterion: sessionStorage.getItem("accountingCriterion"),
      };
      // 刷新token
      this.flashTokenPc(item.id);
      if (this.visit === "visit") {
        // 总后台--进入
        sessionStorage.setItem("enterpriseId", item.id);
        this.$setCookie("enterpriseId", item.id);
        this.routeFun();
      } else {
        // 手机号--进入
        // 1、判断是否有相应权限
        this.getEmployeeInfoUserId(item.id, item);
      }
    },
    placeholderFun() {
      let num1 = 0;
      let num2 = 0;
      let num3 = 0;
      this.enterpriseList.forEach((element) => {
        if (element.isTiyan === 0) {
          if (element.companyNature === 1) {
            num1++;
            num3++;
          }
          if (element.companyNature === 2) {
            num2++;
            num3++;
          }
        }
      });
      this.placeholderText = `共${num3}家，小规模${num1}家 一般人${num2}家`;
    },
    getEmployeeInfoUserId(enterpriseId, item) {
      var that = this;
      let param = {
        userId: this.userId,
        enterpriseId: enterpriseId,
      };
      getEmployeeInfoUserId(param).then((res) => {
        // console.log("res", res);
        if (res.code === 0) {
          if (res.data.status === 2) {
            this.huifuFlag();
            return;
          }
          sessionStorage.setItem("infoUser", JSON.stringify(res));
          let data = [];
          let jurisdiction = null;
          jurisdiction = this.$jurisdiction.authorization(
            res.data.userAuthorizationList
          );
          if (!jurisdiction) {
            this.$store.commit("MyMessage", "暂无权限");
            this.flashTokenPc(this.enterpriseId);
          } else {
            sessionStorage.setItem("jurisdiction", jurisdiction);
            sessionStorage.setItem("enterpriseId", enterpriseId);
            this.$setCookie("enterpriseId", enterpriseId);
            const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
            this.userHead = res.data.userHead;
            if (this.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
              this.userHead =
                "https://www.jzcfo.com" +
                this.userHead.split("http://jz.jzdcs.com")[1];
            }
            this.userName = res.data.name;
            if (res.data.adminName) {
              this.userName = res.data.adminName;
            }
            if (regMobile.test(this.userName)) {
              this.userName =
                "*" + this.userName.substr(this.userName.length - 2, 2);
            }

            this.mobileNum = res.data.mobile.replace(
              /(\d{3})\d{4}(\d{4})/,
              "$1 **** $2"
            );
            if (item) {
              sessionStorage.setItem(
                "accountingCriterion",
                item.accountingstandard
              );
              this.routeFun();
            }
          }
        }
      });
    },
    routeFun() {
      if (this.$route.path !== "/Navigation") {
        this.$router.push("/Navigation");
      } else {
        location.reload();
      }
    },
    flashTokenPc(enterpriseId) {
      if (this.visit === "visit") {
        this.flashTokenPcPlatform(enterpriseId);
        return false;
      }
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: enterpriseId,
      };
      flashTokenPc(param).then((res) => {
        if (res.state == 200) {
          sessionStorage.setItem("pc-tkn", res.data.token);
          console.log(res.data.token);
        }
      });
    },
    flashTokenPcPlatform(enterpriseId) {
      // if (this.visit === "visit") return;
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: enterpriseId,
      };
      flashTokenPcPlatform(param).then((res) => {
        if (res.state == 200) {
          sessionStorage.setItem("pc-tkn", res.data.token);
          console.log(res.data.token);
        }
      });
    },
    fuzzyQuery(inputText) {
      let that = this;
      that.enterpriseList = [];
      if (inputText === "") {
        that.enterpriseList = that.enterpriseListBase;
        that.placeholderFun();
        return;
      }
      that.enterpriseListBase.forEach((element) => {
        if (element.company.indexOf(inputText) !== -1) {
          that.enterpriseList.push(element);
        }
      });
      that.placeholderFun();
    },
    queryEnterpriseInfoForUser() {
      var that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      queryEnterpriseInfoForUser(param).then((res) => {
        if (res.code === 0) {
          sessionStorage.setItem(
            "accountingCriterion",
            res.data.accountingstandard
          );
          sessionStorage.setItem("company", res.data.company);
          sessionStorage.setItem("companyNature", res.data.companyNature);
          sessionStorage.setItem("isStock", res.data.isStock);
          sessionStorage.setItem("creditCode", res.data.creditCode);
          this.visit = sessionStorage.getItem("visit");
          this.company = res.data.company;
          if (this.visit === "visit") {
            const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
            this.userHead = res.data.userHead;
            if (this.userHead) {
              if (this.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
                this.userHead =
                  "https://www.jzcfo.com" +
                  this.userHead.split("http://jz.jzdcs.com")[1];
              }
            }
            this.userName = res.data.name;
            if (res.data.adminName) {
              this.userName = res.data.adminName;
            }
            if (regMobile.test(this.userName)) {
              this.userName =
                "*" + this.userName.substr(this.userName.length - 2, 2);
            }

            this.mobileNum = res.data.mobile.replace(
              /(\d{3})\d{4}(\d{4})/,
              "$1****$2"
            );
          }
          this.companyNature = res.data.companyNature;
          this.$forceUpdate();
        }
      });
    },
    adminGetEnterpriseList() {
      var that = this;
      let param = {
        userId: that.userId,
      };
      adminGetEnterpriseList(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          that.enterpriseList = [];
          let eList = [];

          let enterpriseId = that.$getCookie("enterpriseId");
          let flag = false;
          let num = 0;
          res.data.forEach((element) => {
            if (element.isTiyan === 0) {
              num++;
              if (element.id === that.enterpriseId) {
                that.offsetNum = num;
              }
              eList.push(element);
            }
          });
          that.enterpriseList = eList.reverse();
          that.enterpriseListBase = that.$config.cloneObj(that.enterpriseList);

          that.placeholderFun();
          this.companyPopup = true;
          this.companyQuery = false;
          this.companyAjax = false;
        }
      });
    },
    closePopup() {
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("pc-tkn");
      sessionStorage.removeItem("jurisdiction");
      sessionStorage.removeItem("enterpriseId");
      sessionStorage.removeItem("accountingCriterion");
      sessionStorage.removeItem("visit");
      sessionStorage.removeItem("departmentList");
      sessionStorage.removeItem("company");
      sessionStorage.removeItem("creditCode");
      sessionStorage.removeItem("infoUser");
      // sessionStorage.removeItem("mobile");
      this.routeFun();
    },
    gotoFun(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.huifuPopup {
  width: 320px;
  margin-left: -160px;
  height: 200px;
  margin-top: -200px;
}
.companyAjax {
  padding: 20px 0;
  text-align: center;
}
.user-div {
  width: 34px;
  height: 34px;
  // background: #E3E5E6;
  border-radius: 5px;
  background: #4c8afc;
  // display: inline-block;
  line-height: 34px;
  text-align: center;
  float: left;
  color: #fff;
  font-size: 12px;
  margin-top: -3px;
  img {
    border-radius: 5px;
  }
}
.companySelect-main {
  // width: 280px;
  width: 320px;
  text-align: left;
  float: right;
  // 16986481178902528
  position: relative;
  z-index: 8;
}
.companySelect-popup {
  width: 332px;
  text-align: left;
  float: right;
  position: absolute;
  padding: 9px 0 0;
  top: -10px;
  left: -14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
  .companySelect-popup-header {
    padding-bottom: 15px;
    margin: 0 13px;
    position: relative;
  }
}
.companySelect-left {
  width: 47px;
  height: 40px;
  text-align: left;
  float: left;
  cursor: pointer;
  .companySelect-logo {
    width: 34px;
    float: left;
    position: relative;
    top: 5px;
    .touxiang {
      width: 34px;
      height: 34px;
    }
  }
}
.companySelect-right {
  cursor: pointer;
  .companySelect-title {
    font-size: 14px;
    width: calc(100% - 55px);
    display: inline-block;
    position: relative;
    color: #4c8afc;
    top: 2px;
    .title-icon {
      max-width: 240px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      float: left;
    }
  }
  .companySelect-name {
    font-size: 12px;
    color: #262626;
    position: relative;
    top: -5px;
    span {
      display: inline-block;
      margin-right: 5px;
      // &:first-child {
      //   display: inline-block;
      //   margin-right: 5px;
      // }
      // &:nth-child(2) {
      //   display: inline-block;
      //   margin-right: 5px;
      // }
    }
  }
}
.companySelect-list-box-l {
  .yiban {
    left: 0px;
    top: 2px;
    // margin-right: 6px;
    float: left;
    margin-right: 5px;
  }
  .companyTitle {
    width: calc(100% - 30px);
    float: left;
  }
}
.yiban {
  width: 24px !important;
  height: 14px !important;
  position: relative;
  top: 3px;
  // margin-right: 6px;
  float: left;
}
.companySelect-popup-search {
  width: 304px;
  margin: 0 13px 10px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  opacity: 1;
  border-radius: 3px;
  position: relative;
  .popup-ipt-search {
    width: calc(100% - 30px);
    height: 100%;
    margin: 0;
    padding: 0 15px;
    float: left;
    font-size: 12px;

    &::placeholder {
      color: #bbb;
    }
  }

  .fangdajing {
    position: absolute;
    top: 9px;
    right: 12px;
    font-size: 18px;
  }
}
.companySelect-list {
  width: 310px;
  margin: 0 13px 10px;
  max-height: 484px;
  overflow-y: auto;
  .companySelect-list-box {
    width: 100%;
    padding: 14px 0px 6px;
    cursor: pointer;
    font-size: 14px;
    border-bottom: 1px dashed #dbdbdb;
    &.cr {
      color: #4c8afc;
    }
    &:last-child {
      border-bottom: none;
    }
    .companySelect-list-box-l {
      width: calc(100% - 20px);
      display: inline-block;
      text-align: left;
    }
    .companySelect-list-box-r {
      width: 20px;
      // display: inline-block;
      text-align: right;
      float: right;
    }
    &:hover {
      .companyStyle {
      }
      color: #4c8afc;
    }
  }
}
.headerQuit {
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  border-top: 1px solid rgba(219, 219, 219, 0.5);
  // border-top: 1px solid rgba(187, 187, 187, 0.5);
}
.jiantou-div {
  position: absolute;
  right: 0;
  top: 0px;
}
.jiantou-bottom {
  position: relative;
  top: 10px;
  right: 8px;
  width: 12px !important;
}
.jiantou-top {
  position: relative;
  top: 10px;
  right: -6px;
  width: 12px !important;
}
</style>