import store from '../../store'
const target = store.state.target
const url = target + "/bankbill"
export default {
  queryBankFlow: url + '/bankflow-service/queryBankFlow', //查询银行流水详细
  queryBankFlowList: url + '/bankflow-service/queryBankFlowList', //查询银行流水
  uploadBankFlow: url + '/bankflow-service/upload-bankflow', //上传银行流水
  deleteBankFlow: url + '/bankflow-service/deleteBankFlow', //删除流水用途
  modifyBankFlowYongTu: url + '/bankflow-service/modifyBankFlowYongTu', //修改流水用途
}



