<template>
  <div id="app">
    <!-- <div class="app1" @contextmenu.prevent v-if="process === 'build'">
      <MyMessage v-if="MESSAGE"> </MyMessage>
      <TheLoading v-if="LOADING"> </TheLoading>
      <LoginPopup v-if="LOGIN"> </LoginPopup>
      <router-view />
    </div> -->
    <MyMessage v-if="MESSAGE"> </MyMessage>
    <TheLoading v-if="LOADING"> </TheLoading>
    <LoginPopup v-if="LOGIN"> </LoginPopup>
    <router-view />
    <!-- <div class="app1">
    </div> -->
  </div>
</template>
<script>
import { queryEnterpriseDepartment } from "@/http/api";
import { mapState } from "vuex";
import MyMessage from "@/components/MyMessage";
import TheLoading from "@/components/TheLoading";
import LoginPopup from "@/components/loginPopup";

export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      process: "serve",
      // process: "build",
    };
  },
  computed: {
    ...mapState(["MESSAGE", "LOADING", "LOGIN"]),
  },
  name: "App",
  components: {
    MyMessage,
    TheLoading,
    LoginPopup,
  },
  mounted() {
    if (this.enterpriseId) {
      this.queryEnterpriseDepartment();
    }
    let href = window.location.href;
    // if (href.indexOf("192.168.3.8") !== -1) this.process = "build";
    if (href.indexOf("www.jzcfo.com") !== -1) this.process = "build";

    document.onkeydown = function (e) {
      // 方向键--上
      // alert(e.keyCode);
      // if (e.keyCode == "123") {
      //   // alert("按下了方向键--上");
      //   e.returnValue = false;
      //   return false;
      // }
    };
  },
  methods: {
    // 部门列表
    queryEnterpriseDepartment() {
      let that = this;
      let param = {
        userId: that.userId,
        enterpriseId: that.enterpriseId,
      };
      queryEnterpriseDepartment(param).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          let list = res.data;
          sessionStorage.setItem("departmentList", JSON.stringify(list));
          this.$forceUpdate();
        }
      });
    },
  },
  created() {
    return;
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>
<style lang="less">
.app1 {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}
</style>
