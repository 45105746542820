import axios from 'axios'
import { getCookie, delCookie } from '@/util/cookie'
import $config from '@/util/config'
import router from "../router";
import store from '@/store'
let _this = this
// axios 配置
axios.defaults.timeout = 10000 //接口超时时间10秒
axios.defaults.baseURL = '/' //调用数据接口
// axios.defaults.baseURL = 'https://api.jzcfo.com/systemset/' //调用数据接口
// http request 拦截器，通过这个，可以把Cookie传到后台
// console.log("axios", axios.defaults)
let num = 0
axios.interceptors.request.use(
  config => {
    //请求先展示加载框
    //store.commit('showLoading')
    config.data = JSON.stringify(config.data)
    config.headers = {
      Accept: 'application/json;charset=UTF-8',
      //请求先展示加载框
      // "Content-Type": "multipart/form-data"
      'Content-Type': 'application/json',
      requestId: $config.createPassword()
    }
    //接口有传参数才处理headers,避免日志get不能正常请求
    // console.log(config);
    if (config.data || config.params) {
      //token
      // const pcTkn = getCookie('pc-tkn') //获取Cookie token
      const pcTkn = sessionStorage.getItem("pc-tkn") //获取Cookie token
      if (pcTkn) {
        config.headers.token = pcTkn //后台接收的参数token
      }
    }
    if (
      config.url.indexOf('getEnterpriseList') !== -1 ||
      config.url.indexOf('queryMonthSalaryList-v2') !== -1 ||
      config.url.indexOf('queryBuzhuButie') !== -1 ||
      config.url.indexOf('queryMonthSalaryList-v2') !== -1 ||
      config.url.indexOf('getAgreementBankInfo') !== -1 ||
      config.url.indexOf('getSpecialInfo') !== -1 ||
      config.url.indexOf('getEmployeeInfo') !== -1 ||
      config.url.indexOf('queryEnterpriseDepartment') !== -1 ||
      config.url.indexOf('modifyEnterpriseEmployee') !== -1 ||
      config.url.indexOf('prefectEnterpriseEmployee') !== -1 ||
      config.url.indexOf('getaddRosterStatus') !== -1 ||
      config.url.indexOf('getEnterpriseList') !== -1
    ) {
      store.commit("hideLoading", "请求中");
    } else {
      num++
      store.commit("showLoading", "请求中");
    }
    // if (
    //   config.url !== "http://192.168.3.4/manager/salaryservice-v2/queryMonthSalaryList-v2" &&
    //   config.url !== "https://api.jzcfo.com/usermanager/employee-buzhubutie/queryBuzhuButie"
    //   && config.url !== 'http://192.168.3.4/manager/salaryservice-v2/queryMonthSalaryList-v2'
    //   && config.url !== 'http://192.168.3.4/manager/tax-pay/getAgreementBankInfo'
    //   && config.url !== 'https://api.jzcfo.com/manager/salaryservice-v2/queryMonthSalaryList-v2') {

    // }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    // 请求响应后关闭加载框
    //store.commit('hideLoading')
    // console.log(config)
    if (response.config.url !== "https://api.jzcfo.com/usermanager/employee-buzhubutie/queryBuzhuButie") {
      num--;
      if (num <= 1) {
        setTimeout(() => {
          store.commit("hideLoading", "结束");
        }, 500)
      } else {
        store.commit("showLoading", "请求中");
      }
      let TIME = 60;
      let times = setInterval(() => {
        TIME--;
        if (TIME <= 0) {
          clearInterval(times)
          store.commit("hideLoading", "结束");
          return false
        }
      }, 1000);
    }
    const responseCode = response.data.responseCode

    // console.log("response", response)
    return response
  },
  error => {
    // 请求响应后关闭加载框
    //store.commit('hideLoading')
    // 此处报错可能因素比较多
    // 1.需要授权处用户还未登录，因为路由段有验证是否登陆，此处理论上不会出现
    // 2.需要授权处用户登登录过期
    // 3.请求错误 4xx
    // 5.服务器错误 5xx
    // 关于鉴权失败，与后端约定状态码为500
    const response = error.response
    // delCookie('pc-tkn') //删除登录token
    // setTimeout(() => {
    //   router.push({
    //     path: "/login"
    //   });
    // }, 1000)
    if (response) {
      const status = response.status
      switch (status) {
        case 400:
          store.state.errorMsg = response.data.responseMsg
          //store.commit('MyMessage', '请求错误')
          break
        case 401:
          store.commit('MyMessage', '未授权，请登录')
          // delCookie('pc-tkn') //删除登录token
          sessionStorage.removeItem("pc-tkn");
          router.replace({
            path: '/user/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
          break
        case 403:
          store.commit('MyMessage', '登录信息过期，请重新登录')
          // delCookie('pc-tkn') //删除登录token
          sessionStorage.removeItem("pc-tkn");
          // delCookie('deviceCode') //删除用户设备编码
          setTimeout(() => {
            router.replace({
              path: '/user/login',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }, 1000)
          break
        case 404:
          store.commit('MyMessage', '网络请求不存在')
          break
        case 408:
          store.commit('MyMessage', '请求超时')
          break
        case 500:
          store.commit('MyMessage', '服务器内部错误')
          break
        case 501:
          store.commit('MyMessage', '服务未实现')
          break
        case 502:
          store.commit('MyMessage', '网关错误')
          break
        case 503:
          store.commit('MyMessage', '服务不可用')
          break
        case 504:
          store.commit('MyMessage', '网关超时')
          break
        case 505:
          store.commit('MyMessage', 'HTTP版本不受支持')
          break
        default:
          store.commit('MyMessage', response.data.responseMsg)
      }
      //return Promise.reject(error)
    } else {
      //store.commit('MyMessage', '请求超时，请检查网络是否连接正常')
    }
  }
)
export default axios

/**
 * post 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      response => {
        if (response) {
          const resData = response.data
          resolve(resData)
        }
      },
      err => {
        // console.log(err)
        reject(err)
      }
    )
  })
}



/**
 * get 请求方法
 * @param url
 * @returns {Promise}
 */
export function get(url, param) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: param })
      .then(response => {
        const resData = response.data
        resolve(resData)
      })
      .catch(err => {
        reject(err)
      })
  })
}



export function getOne(url, param) {
  return new Promise((resolve, reject) => {
    axios.get(url + '/' + param, {
      params: {},
      headers: {
        'token': sessionStorage.getItem("pc-tkn"),
      }
    })
      .then(response => {
        const resData = response.data
        resolve(resData)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * patch 方法封装
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      response => {
        const resData = response.data
        resolve(resData)
      },
      err => {
        reject(err)
      }
    )
  })
}

/**
 * put 方法封装
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      response => {
        const resData = response.data
        resolve(resData)
      },
      err => {
        reject(err)
      }
    )
  })
}




/**
 * delete 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function deletefn(url, param) {
  return new Promise((resolve, reject) => {
    axios.delete(url + "/" + param)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
        Message({ message: '加载失败', type: 'error' });
      })
  });
}
