import store from '../../store'
const target = store.state.target
const url = target + "/attendance"
export default {
  // queryGroup: url + '/group', //查询考勤分组列表
  // saveGroup: url + '/group', //保存公司考情分组
  // queryUser: url + '/user', //查询花名册用户
  // queryShift: url + '/shift', //查询班次
  // saveShift: url + '/shift', //保存班次
  // deleteGroup: url + '/group/deleteGroup', //保存班次
  poapprove: url + '/approve/poapprove', //考勤处的转审  
  slectOneDay: url + '/attendance/slectOneDay', // 查询地图点坐标及考勤信息
  imgetkey: url + '/imging/imgetkey', // 获取 userSig 密钥
  getOneDayByUserId: url + '/attendance/getOneDayByUserId', //用户查询某一天打卡情况打卡
  selectByMonth: url + '/attendance/selectByMonth', //普通用户月打卡统计
  getTajectoryall: url + '/attendance/getTajectoryall', //获取动态轨迹
  queryGroup: url + '/group', //查询考勤分组列表
  saveGroup: url + '/group', //保存公司考情分组
  queryUser: url + '/user', //查询花名册用户
  queryShift: url + '/shift', //查询班次
  saveShift: url + '/shift', //保存班次
  deleteGroup: url + '/group/deleteGroup', //保存班次
  selectByMonthAdmin: url + '/adminSubmitClock/selectByMonth', //保存班次
}
