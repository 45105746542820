<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="hideLogin"></div>
    <div class="loginPopup">
      <div class="popup-layer-close" @click="hideLogin">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="popup-login-main-top">
        <svg-icon icon-class="logo_login" class="logo-login"></svg-icon>
      </div>
      <div class="popup-login-title textAlign">即账</div>
      <div class="popup-login-box">
        <p class="popup-login-box-text">
          输入手机号即代表您以阅读并同意<a
            :href="hostname"
            style="color: #4c8afc"
            target="_block"
          >
            《服务协议》
          </a>
        </p>
        <div class="popup-login-input">
          <span class="input-left">+86 </span>
          <span class="popup-login-line"></span>
          <input
            class="popup-ipt"
            type="number"
            v-model="mobile"
            ref="mobile"
            id="mobile"
            autocomplete="off"
            oninput="if(value.length>11)value=value.slice(0,11)"
          />
        </div>
      </div>
      <div class="popup-login-box popup-warp">
        <p class="popup-login-box-text">验证手机号</p>
        <div class="code">
          <div class="code-box" v-for="(item, index) in codeList" :key="index">
            <input
              :id="item.id"
              class="inputStyle"
              :class="{
                iptBor: code[item.index] || code[item.index] === 0,
                borderB: inputFlag,
              }"
              autocomplete="off"
              :readonly="inputFlag"
              v-model="code[item.index]"
              type="text"
            />
          </div>
        </div>
      </div>
      <div>
        <a
          href="javascript:"
          :class="{
            popupLinkColor: verification !== '获取验证码',
            popupLinkColor1: verification === '重新获取验证码',
          }"
          class="popup-link"
          @click="verificationFun()"
        >
          {{ verification }}
        </a>
      </div>
    </div>
    <div class="loginPopup" v-if="enterpriseShow">
      <div class="popup-layer-close" @click="hideLogin">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="popup-login-title popup-warp popup-margin-top">
        请选择企业
      </div>
      <div class="popup-search">
        <i class="el-icon-search"></i>
        <input
          type="text"
          class="popup-ipt popup-ipt-search"
          :placeholder="placeholderText"
          autocomplete="off"
          v-model="inputText"
          @input="fuzzyQuery(inputText)"
        />
      </div>
      <div class="popup-mian-list webkit-scrollbar popup-warp" id="list">
        <div
          class="popup-list-box border-b"
          v-for="(item, index) in enterpriseList"
          :key="index"
          @click="enterpriseClick(item)"
        >
          <div class="popup-list-box-left">
            <span
              :class="{
                popupListSpan1: item.companyNature === 2,
                popupListSpan2: item.companyNature === 1,
              }"
            >
              <slot v-if="item.companyNature === 2">一般</slot>
              <slot v-if="item.companyNature === 1">小规</slot>
            </span>
            <span class="companyStyle">{{ item.company }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSms,
  adminLoginMsm,
  getEmployeeInfoUserId,
  flashTokenPc,
} from "@/http/api";
export default {
  name: "loginPopup",
  data() {
    return {
      enterpriseShow: false,
      mobile: "",
      verification: "获取验证码",
      placeholderText: "共0家，小规模0家 一般人0家",
      inputText: "",
      code: new Array(4),
      codeId: ["first", "second", "third", "forth"],
      inputFlag: true,
      codeList: [
        {
          id: "first",
          index: 0,
        },
        {
          id: "second",
          index: 1,
        },
        {
          id: "third",
          index: 2,
        },
        {
          id: "forth",
          index: 3,
        },
      ],
      enterpriseList: [],
      enterpriseListBase: [],
      user: {
        allrights: 0,
        createTime: "2021-01-07 13:22:54",
        defaultApprove: 0,
        defaultPayment: 0,
        defaultTransfer: 0,
        enterpriseEmployeeId: "34877431808",
        enterpriseId: "28376831521325056",
        failreason: "",
        id: "16668400797155328",
        isAdmin: 0,
        marking: "",
        nickName: "",
        openid: "",
        role: 100107,
        status: 0,
        updateTime: "2021-06-16 17:39:05",
        userHeadimg:
          "http://jz.jzdcs.com/imageprod/headportrait/16668400797155328/7518210759852032.jpg",
        userMobile: "18611912314",
        username: "张三",
      },
      token: "",
      hostname: "https://www.jzcfo.com/agreement/protocol-pc.html",
      offsetNum: 0,
    };
  },
  mounted() {
    this.mobile = sessionStorage.getItem("mobile");
    console.log(this.mobile);
    document.getElementById("mobile").focus();
    if (location.hostname === "www.jzdcs.com") {
      this.hostname = "https://www.jzdcs.com/agreement/protocol-pc.html";
    } else {
      this.hostname = "https://www.jzcfo.com/agreement/protocol-pc.html";
    }
    this.$forceUpdate();
  },

  watch: {
    code: function (newValue, oldValue) {
      let tempValue = "";
      for (let i = 0; i < newValue.length; i++) {
        if (i == 4) {
          break;
        }
        if (newValue[i]) {
          tempValue = tempValue + newValue[i];
        }
      }
      this.smsCode = tempValue;
      let m = tempValue.split("");
      let location = 0;
      for (let i = 0; i < newValue.length; i++) {
        if (m[i] && i < 4) {
          location++;
          newValue[i] = m[i];
        } else {
          newValue[i] = "";
        }
      }
      if (location < 1) {
        location = 1;
      } else if (location > 4) {
        location = 4;
      }
      if (tempValue.length == 4) {
        if (this.mobile) {
          this.loginFun(tempValue);
        } else {
          this.$store.commit("MyMessage", "手机号不能为空");
        }
      }
      document.getElementById(this.codeId[location - 1]).focus();
    },
  },
  methods: {
    hideLogin() {
      this.$store.commit("hideLogin");
    },
    enterpriseClick(item, flag) {
      let that = this;
      that.enterpriseId = item.enterpriseId;
      that.getEmployeeInfoUserId(item, flag);
    },
    getEmployeeInfoUserId(item, flag) {
      var that = this;
      console.log(item);
      let param = {
        userId: this.user.id,
        enterpriseId: item.enterpriseId,
      };
      getEmployeeInfoUserId(param).then((res) => {
        if (res.code === 0) {
          let jurisdiction = null;
          jurisdiction = this.$jurisdiction.authorization(
            res.data.userAuthorizationList
          );
          if (!jurisdiction) {
            console.log(jurisdiction);
            if (!flag) this.$store.commit("MyMessage", "暂无权限");
            this.enterpriseShow = true;
          } else {
            sessionStorage.setItem("jurisdiction", jurisdiction);
            sessionStorage.setItem("userId", this.user.id);
            sessionStorage.setItem("enterpriseId", this.enterpriseId);
            this.$setCookie("enterpriseId", this.enterpriseId);
            sessionStorage.setItem(
              "accountingCriterion",
              item.accountingstandard
            );
            // location.reload();
            // return;
            this.flashTokenPc(this.enterpriseId);
          }
        }
      });
    },
    loginFun(tempValue) {
      let that = this;
      let param = {
        mobile: that.mobile,
        msmCode: tempValue,
      };
      adminLoginMsm(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          that.enterpriseList = [];
          let eId = this.$getCookie("enterpriseId");
          let item = {};
          this.offsetNum = 0;
          let offsetFlag = false;
          res.data.enterpriseList.forEach((element) => {
            element.enterpriseId = element.id;
            if (element.isTiyan === 0 && element.company !== "游客") {
              that.enterpriseList.push(element);
              if (!offsetFlag) {
                this.offsetNum++;
              }
              if (element.id === eId) {
                item = element;
                offsetFlag = true;
              }
            }
          });
          that.enterpriseListBase = that.$config.cloneObj(that.enterpriseList);

          this.token = res.data.token;
          this.user = res.data.user;
          sessionStorage.setItem("mobile", that.mobile);
          sessionStorage.setItem("userId", this.user.id);
          sessionStorage.setItem("pc-tkn", this.token);
          // if (item.id) {
          //   this.enterpriseClick(item);
          //   this.hideLogin();
          //   return;
          // }
          // this.enterpriseClick(that.user);
          // return;
          if (that.enterpriseListBase.length === 1) {
            this.enterpriseClick(that.enterpriseListBase[0], true);
          } else {
            // this.$router.push("/enterpriseList");
            this.placeholderFun();
            this.enterpriseShow = true;
            this.$nextTick(() => {
              document.getElementById("list").scrollTop = this.offsetNum * 46;
            });
          }
          // this.enterpriseShow = true;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },

    flashTokenPc(enterpriseId) {
      let that = this;
      let param = {
        userId: this.user.id,
        enterpriseId: enterpriseId,
      };
      flashTokenPc(param).then((res) => {
        // console.log("flashTokenPc", res);
        if (res.code == 0) {
          sessionStorage.setItem("pc-tkn", res.data.token);
          location.reload();
          // if (this.$route.path === "/Navigation") {
          // } else {
          //   this.$router.push("/Navigation");
          // }
        }
      });
    },
    placeholderFun() {
      let num1 = 0;
      let num2 = 0;
      let num3 = 0;
      this.enterpriseList.forEach((element) => {
        if (element.isTiyan === 0) {
          if (element.companyNature === 1) {
            num1++;
            num3++;
          }
          if (element.companyNature === 2) {
            num2++;
            num3++;
          }
        }
      });
      this.placeholderText = `共${num3}家，小规模${num1}家 一般人${num2}家`;
    },
    fuzzyQuery(inputText) {
      console.log(inputText);
      let that = this;
      that.enterpriseList = [];
      if (inputText === "") {
        that.enterpriseList = that.enterpriseListBase;
        that.placeholderFun();
        return;
      }
      that.enterpriseListBase.forEach((element) => {
        if (element.company.indexOf(inputText) !== -1) {
          that.enterpriseList.push(element);
        }
      });
      that.placeholderFun();
    },
    adminLoginFun() {
      // 获取验证码
      let getSmsData = {
        mobileNo: this.mobile,
        busType: "2",
      };
      getSms(getSmsData).then((res) => {
        if (res.code === 0) {
          this.$store.commit("MyMessage", "发送成功");
        } else {
          // this.$store.commit("MyMessage", res.msg);
        }
        document.getElementById("first").focus();
      });
    },
    verificationFun() {
      let that = this;
      if (
        this.verification === "获取验证码" ||
        this.verification === "重新获取验证码"
      ) {
        if (that.mobile.length == 11) {
          const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
          if (regMobile.test(this.mobile)) {
            that.adminLoginFun();
          } else {
            this.$store.commit("MyMessage", "手机号不符合规则");
            return;
          }
          if (that.verification.indexOf("获取验证码") !== -1) {
            that.inputFlag = false;
            let time = 60;
            that.verification = time + "s";
            let timer = setInterval(function () {
              if (time > 0) {
                time--;
                that.verification = time + "s";
              } else {
                clearInterval(timer);
                that.verification = "重新获取验证码";
              }
              that.$forceUpdate();
            }, 1000);
          }
        } else {
          this.$store.commit("MyMessage", "请输入正确的手机号");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.popup-layer-close {
  border-radius: 0 24px 0 100% !important;
}
.el-icon-search {
  font-size: 16px;
  color: #bbb;
  margin-right: 5px;
  position: relative;
  top: 1px;
  font-weight: 600;
}
.loginPopup {
  width: 506px;
  height: 480px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -253px;
  margin-top: -240px;
  background: #fff;
  border-radius: 24px;
  .popup-login-main-top {
    font-size: 12px;
    // margin-top: 50px;
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
    .icon1 {
      top: 3px;
    }
    .logo-login {
      width: 40px;
      height: 40px;
    }
  }
  .popup-login-title {
    width: 340px;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #262626;
    text-align: center;
    margin: 0 auto;
    &.popup-margin-top {
      margin-top: 40px;
    }
    &.textAlign {
      text-align: left;
    }
  }
  .popup-login-box {
    overflow: hidden;
    margin: 10px auto 20px;
    width: 340px;
    .popup-login-box-text {
      font-size: 15px;
      font-weight: 400;
      color: #999999;
      text-align: left;
    }
    .popup-login-input {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid rgba(187, 187, 187, 0.5);
      font-size: 14px;
      text-align: left;
      margin-top: 22px;
      .input-left {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 33px;
        color: #262626;
      }
      .popup-login-line {
        position: relative;
        top: -3px;
        padding: 0 7px;
        font-size: 20px;
      }
    }
    .popup-ipt {
      // width: 190px;
      width: calc(100% - 80px);
      height: 40px;
      padding-left: 5px;
      outline: none;
      border: none;
      font-size: 24px;
      letter-spacing: 2px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &[type="number"] {
        -moz-appearance: none;
      }
    }
  }
  .code {
    // display: flex;
    font-size: 14px;
    .code-box {
      // flex: 1;
      display: inline-block;
      width: calc((100% - 90px) / 4);
      font-size: 14px;
      margin-left: 30px;
      text-align: center;
      &:first-child {
        margin-left: 0px;
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }

      .inputStyle {
        width: 60px;
        font-size: 24px;
        height: 54px;
        text-align: center;
        border: none;
        border-bottom: 1px solid #c6c6c6;
        outline: none;
        font-weight: bold;
        &.iptBor {
          border-bottom: 1px solid #4c8afc;
        }
        &.borderB {
          border-bottom: 1px solid rgba(187, 187, 187, 0.4);
        }
      }
    }
  }
  .popup-link {
    width: 340px;
    height: 46px;
    // float: right;
    line-height: 46px;
    font-size: 18px;
    // font-weight: bold;
    color: #4c8afc;
    outline: none;
    text-decoration: none;
    text-align: center;
    font-family: none;
    background: #f8f9fa;
    border-radius: 5px;
    display: block;
    margin: 40px auto 0;
    &.popupLinkColor {
      color: #bbb;
    }
    &.popupLinkColor1 {
      color: #4c8afc;
    }
  }
  .popup-search {
    width: 390px;
    margin: 0 auto;
    padding: 20px 0 10px;
    margin-left: 46px;
    border-bottom: 1px solid #dbdbdb;
    text-align: left;
    .popup-ipt {
      font-size: 16px;
      &::placeholder {
        color: #bbbbbb;
      }
      &.popup-ipt-search {
        width: 90%;
        font-size: 14px;
      }
    }
  }
  .popup-mian-list {
    width: 414px;
    height: 324px;
    overflow-y: auto;
    margin: 0 auto;
    .popup-list-box {
      width: 390px;
      display: flex;
      font-size: 14px;
      cursor: pointer;
      padding: 12px 0px 0;
      &:hover {
        .popup-list-box-left {
          color: #4c8afc;
        }
      }
      &:last-child {
        &.border-b {
          border-bottom: none;
        }
      }
      &.border-b {
        border-bottom: 1px solid rgba(187, 187, 187, 0.5);
      }
      .popup-list-box-left {
        flex: 1;
        text-align: left;
        .popupListSpan1 {
          width: 32px;
          height: 26px;
          line-height: 26px;
          background: #ff931e;
          opacity: 1;
          border-radius: 2px;
          // display: inline-block;
          font-size: 12px;
          transform: scale(0.667);
          text-align: center;
          color: #fff;
          position: relative;
          left: -4px;
          float: left;
          top: -2px;
        }

        .companyStyle {
          display: inline-block;
          width: calc(100% - 39px);
          position: relative;
          top: 2px;
          padding-bottom: 16px;
          margin-left: 5px;
        }

        .popupListSpan2 {
          width: 32px;
          height: 26px;
          line-height: 26px;
          background: #70c050;
          opacity: 1;
          border-radius: 2px;
          // display: inline-block;
          font-size: 12px;
          transform: scale(0.667);
          text-align: center;
          color: #fff;
          position: relative;
          left: -4px;
          float: left;
        }
      }
      .popup-list-box-right {
        flex: 0.2;
        text-align: center;
        &.notDeclared {
          color: #f58e21;
        }
      }
    }
  }
}
</style>